/* eslint-disable prefer-destructuring */
import mflConfig from '../../utils/config';
import { post } from '../../utils/request-axios';
import { setData, setHistoryFilterValues, updateList } from '../../store/reducer/historyLogs';
import store from '../../store/store';
import { setBackdrop } from '../../store/reducer/uploadFile';

export const fetchAllHistoryFilterValues = (appliedFilters) => async (dispatch) => {
  dispatch(setBackdrop(true));
  const response = await post(`${mflConfig.API_URL}filter`, appliedFilters);
  let label;
  let options;
  if (typeof response?.data?.subject?.data === 'object') {
    label = Object.keys(response?.data)[0]; // subject
    options = Object.values(response?.data?.subject)[0]; // []
  } else {
    label = Object.keys(response?.data)[0]; // subject
    options = Object.values(response?.data)[0]; // ["french", "german"]
  }
  dispatch(setHistoryFilterValues([label, options]));
  dispatch(setBackdrop(false));
};

export const postFilteredData =
  (data, pageNo, clearAll = false) =>
  async (dispatch) => {
    const limit = 50;
    if (clearAll) {
      data = store.getState().historyLogs.dataToSend;
      pageNo = 0;
    }
    const skip = limit * pageNo;
    store.dispatch(setBackdrop(true));
    const response = await post(`${mflConfig.API_URL}history/?$limit=${limit}&$skip=${skip}`, data);
    store.dispatch(setBackdrop(false));
    dispatch(setData(response.data.data));
    dispatch(updateList(response?.data.data));
  };
