import GameManagementImg from '../icons/Game Management.png';
import LanguageDatabaseImg from '../icons/Language.png';
import HistoryLogsImg from '../icons/History Logs.png';
import DownloadTemplateImg from '../icons/Download.png';
import UploadFileImg from '../icons/Upload.png';
import ErrorLogs from '../icons/ErrorLogs.png';
import Logo from '../../components/Logo';

export const firstPageData = [
  {
    title: 'Game Management',
    IconName: <Logo src={GameManagementImg} className="icon-style" />,
    route: 'games/list',
  },
  {
    title: 'Language Database',
    IconName: <Logo src={LanguageDatabaseImg} className="icon-style" />,
    route: 'languagedatabase',
  },
  {
    title: 'History Logs',
    IconName: <Logo src={HistoryLogsImg} className="icon-style" />,
    route: 'historylogs',
  },
];

export const secondPageData = [
  {
    title: 'Download Template',
    IconName: <Logo src={DownloadTemplateImg} className="icon-style" />,
  },
  {
    title: 'Upload File',
    IconName: <Logo src={UploadFileImg} className="icon-style" />,
  },
  {
    title: 'Error Logs',
    IconName: <Logo src={ErrorLogs} className="icon-style" />,
    route: '/errorLogs',
  },
];

export const filterData = [
  {
    id: 'id1',
    labelId: 'label1',
    label: 'Subject',
    options: ['French', 'English', 'German'],
  },
  {
    id: 'id2',
    labelId: 'label2',
    label: 'Key Stage',
    options: ['3', '2', '1'],
  },
  {
    id: 'id3',
    labelId: 'label3',
    label: 'Course',
    options: ['Dynamo 1', 'Dynamo 3', 'Dynamo 2'],
  },
  {
    id: 'id4',
    labelId: 'label4',
    label: 'Module',
    options: ['Module 2 –  En classe', 'Module 3 –  En classe'],
  },
  {
    id: 'id5',
    labelId: 'label5',
    label: 'Unit',
    options: ["Unité 2 – Qu'est-ce que tu portes?"],
  },
];
