import { Grid } from '@mui/material';
import React from 'react';
import { PropTypes } from 'prop-types';
import { NewFilterBox } from './NewFIlterBox';

export const NewFilterContainer = ({ filters }) => (
  <Grid container columnSpacing={2} rowSpacing={1}>
    {filters.map(([key, value]) => (
      <Grid key={key} item xs={8} sm={4} md={2}>
        <NewFilterBox title={key} options={value} />
      </Grid>
    ))}
  </Grid>
);

NewFilterContainer.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.arrayOf).isRequired,
};
