import { Grid } from '@mui/material';
import React from 'react';
import { PropTypes } from 'prop-types';
import { HistoryFilterBox } from './HistoryFilterBox';

export const HistoryFilterContainer = ({ filters }) => (
  <Grid container columnSpacing={2} rowSpacing={1}>
    {Object.entries(filters).map(([key, value]) => (
      <Grid key={key} item xs={7} sm={4} md={2}>
        <HistoryFilterBox title={key} options={value} />
      </Grid>
    ))}
  </Grid>
);

HistoryFilterContainer.propTypes = {
  filters: PropTypes.objectOf(PropTypes.arrayOf).isRequired,
};
