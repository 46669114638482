import * as React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from 'react-redux';
import { ReactSortable } from 'react-sortablejs';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Typography } from '@mui/material';
import { setOriginalVocabList, setSelectedVocabList } from '../../../store/reducer/gameDevelopment';
import {
  setItemInVocabList,
  setConfirmedVocabList,
  setSingleGameDetails,
} from '../../../store/reducer/gameDevelop';

import { ButtonCustom } from '../../custom/ButtonCustom';
import { DropdownWithLabel } from '../../custom/DropdownWithLabel';

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function TransferList() {
  const showVocab = useSelector((state) => state.gameDevelop.showVocab);
  const [checked, setChecked] = React.useState([]);
  const dispatch = useDispatch();
  const { originalVocabList, selectedVocabList } = useSelector((state) => state.gameDevelopment);
  const { dropdowns } = useSelector((state) => state.gameDevelop);
  const { itemInVocablist } = useSelector((state) => state.gameDevelop);
  const [list, setList] = React.useState([]);
  React.useEffect(() => {
    if (list.length > 0) {
      const idArray = list.map((obj) => obj.id);
      dispatch(setConfirmedVocabList(idArray.toString()));
    }
    dispatch(setItemInVocabList(list.length));
  }, [list]);
  const leftChecked = intersection(checked, originalVocabList);
  const rightChecked = intersection(checked, selectedVocabList);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    dispatch(setSelectedVocabList(selectedVocabList.concat(leftChecked)));
    dispatch(setOriginalVocabList(not(originalVocabList, leftChecked)));
    setChecked(not(checked, leftChecked));
  };
  const handleCheckedLeft = () => {
    dispatch(setOriginalVocabList(originalVocabList.concat(rightChecked)));
    dispatch(setSelectedVocabList(not(selectedVocabList, rightChecked)));
    setChecked(not(checked, rightChecked));
  };

  const iff = (condition, then, otherwise) => (condition ? then : otherwise);
  const sortedOriginalVocabList = [...originalVocabList].sort((p1, p2) =>
    iff(p1.vocabId > p2.vocabId, 1, iff(p1.vocabId < p2.vocabId, -1, 0))
  );
  const sortedSelectedVocabList = [...selectedVocabList].sort((p1, p2) =>
    iff(p1.vocabId > p2.vocabId, 1, iff(p1.vocabId < p2.vocabId, -1, 0))
  );
  const handleConfirmList = () => {
    setList(sortedSelectedVocabList);
    if (selectedVocabList.length >= 0) {
      let value = Math.ceil(selectedVocabList.length / dropdowns.questionPerRound);
      if (value > 30) value = 30;
      if (value === 0) value = 1;
      const shallowDropdown = JSON.parse(JSON.stringify(dropdowns));
      shallowDropdown.numberOfRound = value;
      dispatch(setSingleGameDetails(shallowDropdown));
    }
  };

  const customList = (items, title) => (
    <Card sx={{ border: 'solid gray 1px' }} elevation={10}>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            data-testid={title}
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{
              'aria-label': 'all items selected',
              // 'data-testid': { title },
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      {showVocab && items.length === 0 ? (
        <List
          dense
          component="div"
          role="list"
          sx={{
            width: '500px',
            height: 230,
            bgcolor: 'background.paper',
            overflow: 'auto',
            marginBottom: '15px',
          }}
        >
          <ListItem
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              height: '100%',
              marginTop: '-20px',
            }}
          >
            No items to display
          </ListItem>
        </List>
      ) : (
        <List
          dense
          component="div"
          role="list"
          sx={{
            width: '500px',
            height: 230,
            bgcolor: 'background.paper',
            overflow: 'auto',
            marginBottom: '15px',
          }}
        >
          {items.map((value) => {
            const labelId = `transfer-list-item-${value}-label`;
            return (
              <ListItem
                key={value?.vocabId}
                data-testid={`${title}${value.id}`}
                role="listitem"
                button
                onClick={handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${value?.item1}`} />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      )}
    </Card>
  );
  const normalList = (items, title) => (
    <Card sx={{ border: 'solid gray 1px' }} elevation={10}>
      <CardHeader sx={{ py: 1 }} title={title} />
      <List
        dense
        component="div"
        role="list"
        sx={{
          width: '650px',
          height: 230,
          bgcolor: 'background.paper',
          overflow: 'auto',
          marginBottom: '15px',
        }}
      >
        <ReactSortable
          filter=".addImageButtonContainer"
          dragClass="sortableDrag"
          list={list}
          setList={setList}
          animation="200"
          easing="ease-out"
        >
          {items.map((value) => {
            const labelId = `transfer-list-item-${value}-label`;
            return (
              <ListItem key={value?.id} role="listitem" button>
                <ListItemText id={labelId} primary={`${value?.item1}`} />
              </ListItem>
            );
          })}
        </ReactSortable>
      </List>
    </Card>
  );

  return (
    <>
      <Grid container paddingX={4} alignItems="center" rowGap={4} columnGap={6}>
        <Grid item>{customList(sortedOriginalVocabList, 'Original Vocab List')}</Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center" rowGap={1}>
            <Button
              data-testid="handleCheckedRight"
              sx={{ border: 'solid gray 2px' }}
              variant="outlined"
              size="medium"
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              <ArrowForwardIosIcon />
            </Button>
            <Button
              data-testid="handleCheckedLeft"
              sx={{ border: 'solid gray 2px' }}
              variant="outlined"
              size="medium"
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              <ArrowBackIosNewIcon sx={{ paddingLeft: '8px' }} />
            </Button>
          </Grid>
        </Grid>
        <Grid item>{customList(sortedSelectedVocabList, 'Selected Vocab List')}</Grid>
      </Grid>
      <Grid container display="flex" paddingY={2} paddingX={4}>
        <ButtonCustom
          customAttribute={{
            sx: { textTransform: 'capitalize' },
            'data-testid': 'handleConfirmList',
          }}
          valueProp="Confirm List"
          variantProp="contained"
          onClick={handleConfirmList}
        />
      </Grid>
      <Grid container paddingY={4} paddingX={4}>
        <Grid item xs={4} md={3}>
          <DropdownWithLabel labelName="Order of Vocab" dropdownValues={['Fixed', 'Random']} />
        </Grid>
        <Grid item>
          {normalList(list, 'Rearrange Vocab List (Drag & Drop)')}
          <Typography paddingY={2}>Number of Vocab items confirmed : {itemInVocablist} </Typography>
        </Grid>
      </Grid>
    </>
  );
}
