import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  errorData: [],
  currentErrorData: [],
  pageNo: 0,
};

const errorLogs = createSlice({
  name: 'errorLogs',
  initialState,
  reducers: {
    setErrorResponse: (state, action) => {
      state.errorData = [...state.errorData, ...action.payload];
    },
    setErrorResponseData: (state, action) => {
      state.currentErrorData = action.payload;
    },
    setPageNo: (state) => {
      state.pageNo += 1;
    },
    resetErrorFilters: () => initialState,
  },
});

export const { setErrorResponse, setErrorResponseData, setPageNo, resetErrorFilters } =
  errorLogs.actions;

export default errorLogs.reducer;
