import axios from 'axios';
import store from '../../store/store';
import mflConfig from '../../utils/config';
import { setFetchFile, setBackdrop, setInfoPopup } from '../../store/reducer/uploadFile';
import { setSnackState } from '../../store/reducer/snackbarReducer';

export const uploadFile = async (file, actionType) => {
  const formData = new FormData();
  formData.append('uploadFile', file);
  store.dispatch(setBackdrop(true));
  await axios
    .post(
      `${mflConfig.API_URL}language/`,
      {
        csvFile: formData.get('uploadFile'),
        action: actionType,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    .then((response) => {
      if (response?.status === 200 || response?.status === 201) {
        store.dispatch(setBackdrop(false));
        store.dispatch(setFetchFile(response));
        store.dispatch(
          setSnackState({
            openSnackbar: true,
            message: 'File uploaded successfully',
            type: 'success',
          })
        );
        store.dispatch(setInfoPopup(true));
      }
    });
};
