import { Box, Container, Snackbar } from '@mui/material';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Cards from '../Cards';
import '../../assets/styles/Dashboard.sass';
import { firstPageData } from '../../assets/constantData/cardsData';
import { Navbar } from '../Navbar';
import { SubHeader } from '../custom/SubHeader';
import { snackPopup, setSnackState } from '../../store/reducer/snackbarReducer';
import { DASHBOARD_TITLE } from '../../assets/constantData/dashboard';

function Dashboard() {
  const { openSnackbar, message } = useSelector(snackPopup);
  const dispatch = useDispatch();
  const handleSnackClose = () => {
    dispatch(
      setSnackState({
        openSnackbar: false,
        message: '',
        type: '',
      })
    );
  };

  return (
    <Box className="dashboard-wrapper">
      <Navbar />
      <Container maxWidth="large">
        <SubHeader backBtn={false} title={DASHBOARD_TITLE} />
      </Container>
      <Box className="head-card-wrapper">
        <Cards names={firstPageData} />
      </Box>
      <Snackbar
        testId="snackbar"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        message={message}
        className="snackbar"
        open={openSnackbar}
        autoHideDuration={2000}
        transitionDuration={0}
        onClose={() => handleSnackClose()}
      />
    </Box>
  );
}

export default Dashboard;
