import { post } from '../../utils/request-axios';
import mflConfig from '../../utils/config';
import { pushSelectedReducer } from '../../store/reducer/filterApply';
import {
  fetchFilteredData,
  fetchSearchData,
  setPageNoReducer,
  loadMoreFiltersReducer,
  fetchAllAfterEditReducer,
} from '../../store/reducer/languageDatabase';
import store from '../../store/store';
import { setBackdrop } from '../../store/reducer/uploadFile';

export const pushSelected = (selected, keys) => async (dispatch) => {
  dispatch(pushSelectedReducer([selected, keys]));
};

export const passingSelectedFilters = (data, pageNo) => async (dispatch) => {
  const limit = 50;
  const skip = limit * pageNo;
  store.dispatch(setBackdrop(true));
  const response = await post(`${mflConfig.API_URL}language/?$limit=${limit}&$skip=${skip}`, data);
  dispatch(fetchFilteredData(response?.data));
  store.dispatch(setBackdrop(false));
};
export const passingSelectedFiltersafterEdit = (data, pageNo) => async (dispatch) => {
  const limit = 50 * pageNo;
  const skip = 0;
  store.dispatch(setBackdrop(true));
  const response = await post(`${mflConfig.API_URL}language/?$limit=${limit}&$skip=${skip}`, data);
  dispatch(fetchAllAfterEditReducer(response?.data));
  store.dispatch(setBackdrop(false));
};

export const loadMoreFilters = (data, pageNo) => async (dispatch) => {
  const limit = 50;
  const skip = limit * pageNo;
  store.dispatch(setBackdrop(true));
  const response = await post(`${mflConfig.API_URL}language/?$limit=${limit}&$skip=${skip}`, data);
  dispatch(loadMoreFiltersReducer(response?.data));
  store.dispatch(setBackdrop(false));
};

export const passingSearchReducer = (data, pageNo) => async (dispatch) => {
  const limit = 50;
  const skip = limit * pageNo;
  store.dispatch(setBackdrop(true));
  const response = await post(`${mflConfig.API_URL}language/?$limit=${limit}&$skip=${skip}`, data);
  dispatch(fetchSearchData(response?.data));
  store.dispatch(setBackdrop(false));
};

export const setPageNo = () => (dispatch) => {
  dispatch(setPageNoReducer());
};
