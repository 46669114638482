import * as React from 'react';
import { OutlinedInput, MenuItem, FormControl, Select, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@mui/styles';
import { MenuProps, useStyles } from '../../assets/constantData/utils';
import { fetchAllGameFilterValues } from '../../services/actions/gameDevelopment';
import { setIsFilterSelected } from '../../store/reducer/gameDb';
import {
  resetGameCourseCase,
  resetGameKeyStageCase,
  resetGameSubjectCase,
  setGameFilterValues,
  setIsCourseDisabled,
  setIsKeyStageDisabled,
  setIsModuleDisabled,
  setIsSubGroupDisabled,
  setIsUnitDisabled,
  setIsVocabTypeDisabled,
  setLiveFilter,
} from '../../store/reducer/gameDevelopment';

const CustomTooltip = withStyles({
  tooltip: {
    fontSize: '14px',
  },
})(Tooltip);

export const GameDropdownSingle = ({ options, title }) => {
  const [data, setData] = React.useState([]);
  const dispatch = useDispatch();
  const isFilterApplied = useSelector((state) => state.gameDb.isFilterApplied);
  const isFilterSelected = useSelector((state) => state.gameDb.isFilterSelected);
  const liveFilters = useSelector((state) => state.gameDevelopment.liveFilter);
  const isKeyStageDisabled = useSelector((state) => state.gameDevelopment.isKeyStageDisabled);
  const isCourseDisabled = useSelector((state) => state.gameDevelopment.isCourseDisabled);
  const editFlag = useSelector((state) => state?.gameDevelop?.editFlag);

  React.useEffect(() => {
    if (!isFilterApplied && !isFilterSelected && !editFlag) setData([]);
  }, [isFilterApplied]);

  React.useEffect(() => {
    if (editFlag) {
      setData(liveFilters[title]);
      if (liveFilters.keyStage.length !== 0 || liveFilters.subject.length !== 0)
        dispatch(setIsKeyStageDisabled(false));
      if (liveFilters.course.length !== 0 || liveFilters.keyStage.length !== 0)
        dispatch(setIsCourseDisabled(false));
    }
  }, [editFlag, liveFilters]);

  const classes = useStyles();

  React.useEffect(() => {
    if (liveFilters.keyStage.length === 0 && title === 'keyStage') setData([]);
    if (liveFilters.course.length === 0 && title === 'course') setData([]);
  }, [liveFilters.keyStage, liveFilters.course]);

  React.useEffect(() => {
    if (isKeyStageDisabled && title === 'keyStage') dispatch(setGameFilterValues([title, []]));
    if (isCourseDisabled && title === 'course') dispatch(setGameFilterValues([title, []]));
  }, [isKeyStageDisabled, isCourseDisabled]);

  const handleChange = (event) => {
    dispatch(setIsFilterSelected(true));
    const {
      target: { value },
    } = event;

    switch (title) {
      case 'subject': {
        dispatch(
          fetchAllGameFilterValues({
            subject: [value],
            keyStage: [],
            course: [],
            module: [],
            unit: [],
          })
        );
        dispatch(setIsKeyStageDisabled(false));
        dispatch(setIsCourseDisabled(true));
        dispatch(setIsModuleDisabled(true));
        dispatch(setIsUnitDisabled(true));
        dispatch(setIsVocabTypeDisabled(true));
        dispatch(setIsSubGroupDisabled(true));
        dispatch(resetGameSubjectCase());
        break;
      }
      case 'keyStage': {
        dispatch(
          fetchAllGameFilterValues({
            subject: liveFilters.subject,
            keyStage: [value],
            course: [],
            module: [],
            unit: [],
          })
        );
        dispatch(setIsCourseDisabled(false));
        dispatch(setIsModuleDisabled(true));
        dispatch(setIsUnitDisabled(true));
        dispatch(setIsVocabTypeDisabled(true));
        dispatch(setIsSubGroupDisabled(true));
        dispatch(resetGameKeyStageCase());
        break;
      }
      case 'course': {
        dispatch(
          fetchAllGameFilterValues({
            subject: liveFilters.subject,
            keyStage: liveFilters.keyStage,
            course: [value],
            module: [],
            unit: [],
          })
        );
        dispatch(setIsModuleDisabled(false));
        dispatch(setIsUnitDisabled(true));
        dispatch(setIsVocabTypeDisabled(true));
        dispatch(setIsSubGroupDisabled(true));
        dispatch(resetGameCourseCase());
        break;
      }
      default:
    }
    setData([value]);
    dispatch(setLiveFilter([[value], title]));
  };
  const iff = (condition, then, otherwise) => (condition ? then : otherwise);

  return (
    <div>
      <FormControl className={classes.formControl}>
        <Select
          data-testid="select-single"
          inputProps={{ 'data-testid': 'select-input' }}
          sx={{ maxHeight: '40px', maxWidth: '200px' }}
          value={data}
          onChange={handleChange}
          input={<OutlinedInput size="small" />}
          renderValue={(value) => (value?.length ? value : 'Show All')}
          size="small"
          margin="dense"
          disabled={iff(
            title === 'subject',
            false,
            iff(title === 'keyStage', isKeyStageDisabled, isCourseDisabled)
          )}
          placeholder="Select"
          MenuProps={MenuProps}
          displayEmpty
        >
          {options?.map((name) =>
            name.length > 50 ? (
              <CustomTooltip title={name} placement="left" arrow>
                <MenuItem key={name} value={name} sx={{ whiteSpace: 'normal' }}>
                  {name}
                </MenuItem>
              </CustomTooltip>
            ) : (
              <MenuItem key={name} value={name} sx={{ whiteSpace: 'normal' }}>
                {name}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>
    </div>
  );
};

GameDropdownSingle.propTypes = {
  title: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
};

GameDropdownSingle.defaultProps = {
  title: '',
};
