import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  auth: false,
  success: false,
};

const authenticate = createSlice({
  name: 'authenticate',
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.auth = action?.payload;
    },
    setSuccess: (state, action) => {
      state.success = action?.payload;
    },
  },
});

export const { setAuth, setSuccess } = authenticate.actions;

export default authenticate.reducer;
