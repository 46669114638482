import { Typography } from '@mui/material';
import React from 'react';
import { PropTypes } from 'prop-types';
import { HistoryDropdownCheck } from './HistoryDropdownCheck';
import { HistoryDropdownSingle } from './HistoryDropdownSingle';

export const HistoryFilterBox = ({ title, options }) => {
  const createData = (titleVal, value) => ({ titleVal, value });

  const handleFilterData = (key, value) => {
    switch (key) {
      case 'keyStage':
        return createData('Key Stage', value);
      default:
        return createData(key[0].toUpperCase() + key.slice(1), value);
    }
  };
  const data = handleFilterData(title, options);
  return (
    <>
      <Typography variant="body2" fontWeight={600}>
        {data.titleVal}
      </Typography>
      {title === 'subject' || title === 'keyStage' || title === 'course' ? (
        <HistoryDropdownSingle title={title} options={data.value} />
      ) : (
        <HistoryDropdownCheck options={data.value} title={title} />
      )}
    </>
  );
};

HistoryFilterBox.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
};
