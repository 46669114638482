import * as React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import { MenuProps, useStyles } from '../../assets/constantData/utils';
import '../../assets/styles/DropDown.sass';
import { pushSelected } from '../../services/actions/filterApply';
import {
  resetCourseCase,
  resetKeyStageCase,
  resetSubjectCase,
  setFilterValues,
  setIsCourseSelected,
  setIsKeyStageSelected,
  setIsModuleSelected,
  setIsUnitSelected,
} from '../../store/reducer/filterApply';
import { fetchAllFiltersNew } from '../../services/actions/filterSelection';

const CustomTooltip = withStyles({
  tooltip: {
    fontSize: '14px',
  },
})(Tooltip);
export default function SingleDropdown({ filterOptions, keys }) {
  const [selected, setSelected] = React.useState([]);
  const isFilterApplied = useSelector((state) => state.languageDatabase.isFilterApplied);
  const selectedStore = useSelector((states) => states.filterApply.appliedFilters[keys]);
  const appliedFilters = useSelector((states) => states.filterApply.appliedFilters);
  const isKeyStageSelected = useSelector((states) => states.filterApply.isKeyStageSelected);
  const isCourseSelected = useSelector((states) => states.filterApply.isCourseSelected);

  React.useEffect(() => {
    if (!isFilterApplied) setSelected([]);
    else {
      setSelected(selectedStore?.length > 0 ? selectedStore : selected);
    }
  }, [isFilterApplied]);

  React.useEffect(() => {
    if (appliedFilters.keyStage.length === 0 && keys === 'keyStage') setSelected([]);
    if (appliedFilters.course.length === 0 && keys === 'course') setSelected([]);
  }, [appliedFilters.keyStage, appliedFilters.course]);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isKeyStageSelected && keys === 'keyStage') dispatch(setFilterValues([keys, []]));
    if (isCourseSelected && keys === 'course') dispatch(setFilterValues([keys, []]));
  }, [isKeyStageSelected, isCourseSelected]);

  const classes = useStyles();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    switch (keys) {
      case 'subject': {
        dispatch(
          fetchAllFiltersNew({
            subject: [value],
            keyStage: [],
            course: [],
            module: [],
            unit: [],
          })
        );
        dispatch(setIsKeyStageSelected(false));
        dispatch(setIsCourseSelected(true));
        dispatch(setIsModuleSelected(true));
        dispatch(setIsUnitSelected(true));
        dispatch(resetSubjectCase());

        break;
      }
      case 'keyStage': {
        dispatch(
          fetchAllFiltersNew({
            subject: appliedFilters.subject,
            keyStage: [value],
            course: [],
            module: [],
            unit: [],
          })
        );
        dispatch(setIsCourseSelected(false));
        dispatch(setIsModuleSelected(true));
        dispatch(setIsUnitSelected(true));
        dispatch(resetKeyStageCase());
        break;
      }
      case 'course': {
        dispatch(
          fetchAllFiltersNew({
            subject: appliedFilters.subject,
            keyStage: appliedFilters.keyStage,
            course: [value],
            module: [],
            unit: [],
          })
        );
        dispatch(setIsModuleSelected(false));
        dispatch(setIsUnitSelected(true));
        dispatch(resetCourseCase());
        break;
      }
      default:
    }

    setSelected(value);
    dispatch(pushSelected([value], keys));
  };

  const iff = (condition, then, otherwise) => (condition ? then : otherwise);
  return (
    <FormControl className={classes.formControl} id="mutiple-select-label">
      <Select
        data-testid="select-single"
        key={keys}
        size="small"
        margin="dense"
        id="demo-simple-select"
        value={selected}
        inputProps={{ 'data-testid': 'select-input' }}
        sx={{
          height: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '14px',
          width: '195px',
        }}
        onChange={handleChange}
        disabled={iff(
          keys === 'subject' || keys === 'assets',
          false,
          iff(keys === 'keyStage', isKeyStageSelected, isCourseSelected)
        )}
        displayEmpty
        renderValue={(value) => iff(value?.length, value, 'Show All')}
        MenuProps={MenuProps}
      >
        {filterOptions?.map((option) =>
          option.length > 50 ? (
            <CustomTooltip title={option} placement="left" arrow>
              <MenuItem key={option} value={option} sx={{ whiteSpace: 'normal' }}>
                {option}
              </MenuItem>
            </CustomTooltip>
          ) : (
            <MenuItem key={option} value={option} sx={{ whiteSpace: 'normal' }}>
              {option}
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
}

SingleDropdown.propTypes = {
  filterOptions: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  keys: PropTypes.string.isRequired,
};
