import { KeyboardBackspace } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import '../../assets/styles/BackButton.sass';

export const BackButton = () => {
  const navigate = useNavigate();
  return (
    <Button className="MainWrapper" data-testid="back-button" onClick={() => navigate(-1)}>
      <KeyboardBackspace className="BackIcon" />
      <Typography className="BackText">Back</Typography>
    </Button>
  );
};
