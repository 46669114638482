import { Typography } from '@mui/material';
import React from 'react';
import { PropTypes } from 'prop-types';
import { DropdownCheck } from '../../custom/DropdownCheck';
import { GameDropdownSingle } from '../../custom/GameDropdownSingle';
import { DropdownGameDatabase } from '../../custom/DropdownGameDatabase';

export const FilterBox = ({ title, options, isBold }) => {
  const createData = (titleVal, value) => ({ titleVal, value });

  const handleFilterData = (key, value) => {
    switch (key) {
      case 'keyStage':
        return createData('Key Stage', value);
      case 'taskAction':
        return createData('Task Action', value);
      case 'gameMechanics':
        return createData('Game Mechanism', value);
      case 'gameStatus':
        return createData('Game Status', value);
      case 'subGroup':
        return createData('Sub Group', value);
      case 'vocabType':
        return createData('Vocab Type', value);
      default:
        return createData(key[0].toUpperCase() + key.slice(1), value);
    }
  };
  const data = handleFilterData(title, options);
  const iff = (condition, then, otherwise) => (condition ? then : otherwise);
  return (
    <>
      <Typography variant="body1" fontWeight={isBold ? 600 : ''}>
        {data.titleVal}
      </Typography>
      {iff(
        title === 'subject' || title === 'keyStage' || title === 'course',
        <GameDropdownSingle title={title} options={data.value} />,
        iff(
          title === 'taskAction' ||
            title === 'gameMechanics' ||
            title === 'theme' ||
            title === 'gameStatus',
          <DropdownGameDatabase title={title} options={data.value} />,
          <DropdownCheck title={title} options={data.value} />
        )
      )}
    </>
  );
};

FilterBox.propTypes = {
  title: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  isBold: PropTypes.bool,
};

FilterBox.defaultProps = {
  title: '',
  isBold: true,
};
