import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dataObject: {},
};

const filterSelection = createSlice({
  name: 'filterSelection',
  initialState,
  reducers: {
    fetchFilterAll: (state, { payload: { data } }) => {
      state.dataObject = { ...data };
    },
  },
});

export const { fetchFilterAll } = filterSelection.actions;

export default filterSelection.reducer;
