import { IconButton, InputAdornment, OutlinedInput, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { fetchRecordsWithIDandItem1 } from '../../services/actions/languageDatabase';
import { passingSearchReducer } from '../../services/actions/filterApply';
import {
  resetPageNo,
  setDataToSend,
  setIsFilterApplied,
  setIsFilterSelected as setIsFilterSelectedHistory,
} from '../../store/reducer/historyLogs';
import {
  resetPageNoGameDb,
  setDataToSend as setDataToSendGameDb,
  setIsFilterApplied as setIsFilterAppliedGameDb,
  setIsFilterSelected as setIsFilterSelectedGameDb,
} from '../../store/reducer/gameDb';
import { postFilteredData } from '../../services/actions/historyLogs';
import { postGameFilters } from '../../services/actions/gameDb';
import {
  SEARCH_TOOLTIP_TITLE_LANGUAGE,
  SEARCH_TOOLTIP_TITLE_GAMEDB,
} from '../../assets/constantData/languageDatabase';
import { setPageNoReducer } from '../../store/reducer/languageDatabase';

export const SearchBox = ({ title }) => {
  const [error, setError] = useState(false);
  const [value, setValue] = useState('');
  const [toolTipTitle, setToolTipTitle] = useState('');
  const dispatch = useDispatch();
  const filters = useSelector((states) => states.filterApply.appliedFilters);
  const isFilterApplied = useSelector((state) => state.languageDatabase.isFilterApplied);
  const isFilterAppliedHistory = useSelector((state) => state.historyLogs.isFilterApplied);
  const isFilterAppliedGameDb = useSelector((state) => state.gameDb.isFilterApplied);
  const dataToSend = useSelector((state) => state.historyLogs.dataToSend);
  const dataToSendGameDb = useSelector((state) => state.gameDb.dataToSend);
  const clearAll = useSelector((state) => state.historyLogs.isClearAll);
  const newLiveFilters = useSelector((state) => state.gameDb.newLiveFilters);
  const handleKeyDown = (event) => {
    const query = event.target.value.length;
    if (query >= 3) setError(true);
    else setError(false);
    if (event.key === 'Enter' && error) {
      if (title === 'History Logs') {
        dispatch(resetPageNo());
        dispatch(setIsFilterSelectedHistory(true));
        dispatch(setIsFilterApplied(true));
        dispatch(postFilteredData(dataToSend, 0, false));
      } else if (title === 'Language Database') {
        dispatch(setPageNoReducer());
        dispatch(passingSearchReducer(filters, 0));
      } else {
        dispatch(resetPageNoGameDb());
        dispatch(setIsFilterSelectedGameDb(true));
        dispatch(setIsFilterAppliedGameDb(true));
        dispatch(postGameFilters({ ...dataToSendGameDb, ...newLiveFilters }, 0, false));
      }
    }
  };
  const getUserInput = (e) => {
    const query = e.target.value.length;
    if (query >= 3) setError(true);
    else setError(false);
    if (title === 'History Logs') {
      dispatch(setDataToSend({ ...dataToSend, searchStr: e.target.value }));
    } else if (title === 'Language Database') {
      dispatch(fetchRecordsWithIDandItem1(e.target.value));
    } else if (title === 'Game Database') {
      dispatch(setDataToSendGameDb({ ...dataToSendGameDb, searchStr: e.target.value }));
    }
    setValue(e.target.value);
  };

  useEffect(() => {
    setValue(filters.searchStr);
    setToolTipTitle(SEARCH_TOOLTIP_TITLE_LANGUAGE);
  }, [isFilterApplied]);

  useEffect(() => {
    if (title === 'History Logs') {
      setValue(dataToSend.searchStr);
      setToolTipTitle(SEARCH_TOOLTIP_TITLE_LANGUAGE);
    }
  }, [isFilterAppliedHistory]);

  useEffect(() => {
    if (clearAll === true) setValue('');
  }, [clearAll]);

  useEffect(() => {
    if (title === 'Game Database') {
      setValue(dataToSendGameDb.searchStr);
      setToolTipTitle(SEARCH_TOOLTIP_TITLE_GAMEDB);
    }
  }, [isFilterAppliedGameDb]);

  return (
    <Tooltip title={toolTipTitle}>
      <OutlinedInput
        inputProps={{ 'data-testid': 'searchText' }}
        placeholder="Start your search"
        margin="dense"
        size="small"
        onChange={getUserInput}
        onKeyDown={handleKeyDown}
        value={value}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              data-testid="searchBtn"
              onClick={() => {
                if (title === 'History Logs') {
                  dispatch(resetPageNo());
                  dispatch(setIsFilterSelectedHistory(true));
                  dispatch(setIsFilterApplied(true));
                  dispatch(postFilteredData(dataToSend, 0, false));
                } else if (title === 'Language Database') {
                  dispatch(setPageNoReducer());
                  dispatch(passingSearchReducer(filters, 0));
                } else {
                  dispatch(resetPageNoGameDb());
                  dispatch(setIsFilterSelectedGameDb(true));
                  dispatch(setIsFilterAppliedGameDb(true));
                  dispatch(postGameFilters({ ...dataToSendGameDb, ...newLiveFilters }, 0, false));
                }
              }}
              disabled={!error}
            >
              <SearchIcon fontSize="small" />
            </IconButton>
          </InputAdornment>
        }
      />
    </Tooltip>
  );
};

SearchBox.propTypes = {
  title: PropTypes.string.isRequired,
};
