import { createSlice } from '@reduxjs/toolkit';
import { GAME_THEME_VALUES } from '../../assets/constantData/gameConstant';

const initialState = {
  newLiveFilters: {
    subject: '',
    keyStage: '',
    course: '',
    module: '',
    unit: '',
  },
  newFilterOptions: {
    subject: [],
    keyStage: [],
    course: [],
    module: [],
    unit: [],
  },
  isNewKeyStageDisabled: true,
  isNewCourseDisabled: true,
  isNewModuleDisabled: true,
  isNewUnitDisabled: true,
  filters: {
    taskAction: [
      'Matching',
      'Missing Words',
      'Missing Letters',
      'Arrange words/letters',
      'Flashcards',
    ],
    gameMechanics: [
      'Selecting',
      'Navigating user-led',
      'Navigating item-led',
      'Type in',
      'Flashcards',
    ],
    theme: GAME_THEME_VALUES[0],
    gameStatus: ['Draft', 'Completed', 'Downloaded'],
  },
  list: [],
  menuItems: ['Edit', 'Clone', 'Delete', 'Versions'],
  columns: ['Game Name', 'User ID', 'Status', 'Modified At'],
  dataToSend: {
    taskAction: [],
    gameMechanics: [],
    theme: [],
    gameStatus: [],
    searchStr: '',
  },
  pageNo: 0,
  data: [],
  isFilterApplied: false,
  isFilterSelected: false,
  totalRecords: 0,
  isDeleted: false,
  deletePopup: false,
  versionHistory: [],
  versionPopup: false,
};

const gameDb = createSlice({
  name: 'gameDb',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setGameNewFilterOptions: (state, action) => {
      state.newFilterOptions[action?.payload[0]] = action?.payload[1];
    },
    setGameNewFilterValues: (state, { payload: [value, title] }) => {
      state.newLiveFilters[title] = value;
    },
    setIsNewKeyStageDisabled: (state, action) => {
      state.isNewKeyStageDisabled = action?.payload;
    },
    setIsNewCourseDisabled: (state, action) => {
      state.isNewCourseDisabled = action?.payload;
    },
    setIsNewModuleDisabled: (state, action) => {
      state.isNewModuleDisabled = action?.payload;
    },
    setIsNewUnitDisabled: (state, action) => {
      state.isNewUnitDisabled = action?.payload;
    },
    resetNewGameSubjectCase: (state) => {
      state.newLiveFilters.keyStage = initialState.newLiveFilters.keyStage;
      state.newLiveFilters.course = initialState.newLiveFilters.course;
      state.newLiveFilters.module = initialState.newLiveFilters.module;
      state.newLiveFilters.unit = initialState.newLiveFilters.unit;
    },
    resetNewGameKeyStageCase: (state) => {
      state.newLiveFilters.course = initialState.newLiveFilters.course;
      state.newLiveFilters.module = initialState.newLiveFilters.module;
      state.newLiveFilters.unit = initialState.newLiveFilters.unit;
    },
    resetNewGameCourseCase: (state) => {
      state.newLiveFilters.module = initialState.newLiveFilters.module;
      state.newLiveFilters.unit = initialState.newLiveFilters.unit;
    },
    resetNewGameModuleCase: (state) => {
      state.newLiveFilters.unit = initialState.newLiveFilters.unit;
    },
    updateList: (state, action) => {
      state.list = [...state.list, ...action.payload];
    },
    changeStatusToDownloaded: (state, { payload: [index, statusDownloaded] }) => {
      state.list[index].gameStatus = statusDownloaded;
    },
    resetGameDb: () => initialState,
    updatePageNo: (state) => {
      state.pageNo += 1;
    },
    setDataToSend: (state, action) => {
      state.dataToSend = action.payload;
    },
    setIsFilterApplied: (state, action) => {
      state.list = [];
      state.isFilterApplied = action?.payload;
    },
    setIsFilterSelected: (state, action) => {
      state.isFilterSelected = action.payload;
    },
    updateTotalRecords: (state, action) => {
      state.totalRecords = action.payload;
    },
    updateListWithoutAppend: (state, action) => {
      state.list = action.payload;
    },
    setIsDeleted: (state, action) => {
      state.isDeleted = action.payload;
    },
    setDeletePopup: (state, action) => {
      state.deletePopup = action.payload;
    },
    setVersionHistory: (state, action) => {
      state.versionHistory = action.payload;
    },
    setVersionPopup: (state, action) => {
      state.versionPopup = action.payload;
    },
    resetVersionHistory: (state) => {
      state.versionHistory = [];
    },
    resetWithoutDataToSend: (state, action) => {
      state.list = initialState.list;
      state.data = initialState.data;
      state.dataToSend = action.payload;
    },
    setMenuItems: (state, action) => {
      state.menuItems = [...initialState.menuItems, ...action.payload];
    },
    resetMenuItems: (state) => {
      state.menuItems = initialState.menuItems;
    },
    resetPageNoGameDb: (state) => {
      state.pageNo = initialState.pageNo;
    },
    resetWithoutGameFiltersState: (state) => {
      state.filters = initialState.filters;
      state.list = initialState.list;
      state.menuItems = initialState.menuItems;
      state.columns = initialState.columns;
      state.dataToSend = initialState.dataToSend;
      state.pageNo = initialState.pageNo;
      state.data = initialState.data;
      state.isFilterApplied = initialState.isFilterApplied;
      state.isFilterSelected = initialState.isFilterSelected;
      state.totalRecords = initialState.totalRecords;
      state.isDeleted = initialState.isDeleted;
      state.deletePopup = initialState.deletePopup;
      state.versionHistory = initialState.versionHistory;
      state.versionPopup = initialState.versionPopup;
    },
  },
});

export const {
  fetchFilters,
  setGameNewFilterOptions,
  setGameNewFilterValues,
  setIsNewKeyStageDisabled,
  setIsNewCourseDisabled,
  setIsNewModuleDisabled,
  setIsNewUnitDisabled,
  resetNewGameSubjectCase,
  resetNewGameKeyStageCase,
  resetNewGameCourseCase,
  resetNewGameModuleCase,
  setMenu,
  setData,
  updateList,
  resetGameDb,
  updatePageNo,
  setDataToSend,
  setIsFilterApplied,
  updateTotalRecords,
  setIsFilterSelected,
  updateListWithoutAppend,
  changeStatusToDownloaded,
  setIsDeleted,
  setDeletePopup,
  setVersionHistory,
  setVersionPopup,
  resetVersionHistory,
  resetWithoutDataToSend,
  setMenuItems,
  resetMenuItems,
  resetPageNoGameDb,
  resetWithoutGameFiltersState,
} = gameDb.actions;
export default gameDb.reducer;
