import * as React from 'react';
import { OutlinedInput, MenuItem, FormControl, Select, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@mui/styles';
import { MenuProps } from '../../assets/constantData/utils';
import '../../assets/styles/DropDown.sass';
import {
  resetHistoryCourseCase,
  resetHistoryKeyStageCase,
  resetHistorySubjectCase,
  setHistoryFilterValues,
  setHistoryLiveFilter,
  setIsCourseDisabledHistory,
  setIsKeyStageDisabledHistory,
  setIsModuleDisabledHistory,
  setIsUnitDisabledHistory,
} from '../../store/reducer/historyLogs';
import { fetchAllHistoryFilterValues } from '../../services/actions/historyLogs';

const CustomTooltip = withStyles({
  tooltip: {
    fontSize: '14px',
  },
})(Tooltip);

export const HistoryDropdownSingle = ({ options, title }) => {
  const [data, setData] = React.useState([]);
  const dispatch = useDispatch();
  const dataToSend = useSelector((state) => state.historyLogs.dataToSend);
  const isFilterApplied = useSelector((state) => state.historyLogs.isFilterApplied);
  const isKeyStageDisabledHistory = useSelector(
    (state) => state.historyLogs.isKeyStageDisabledHistory
  );
  const isCourseDisabledHistory = useSelector((state) => state.historyLogs.isCourseDisabledHistory);
  React.useEffect(() => {
    if (!isFilterApplied) setData([]);
  }, [isFilterApplied]);

  React.useEffect(() => {
    if (dataToSend.keyStage.length === 0 && title === 'keyStage') setData([]);
    if (dataToSend.course.length === 0 && title === 'course') setData([]);
  }, [dataToSend.keyStage, dataToSend.course]);

  React.useEffect(() => {
    if (isKeyStageDisabledHistory && title === 'keyStage')
      dispatch(setHistoryFilterValues([title, []]));
    if (isCourseDisabledHistory && title === 'course')
      dispatch(setHistoryFilterValues([title, []]));
  }, [isKeyStageDisabledHistory, isCourseDisabledHistory]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    switch (title) {
      case 'subject': {
        dispatch(
          fetchAllHistoryFilterValues({
            subject: [value],
            keyStage: [],
            course: [],
            module: [],
            unit: [],
          })
        );
        dispatch(setIsKeyStageDisabledHistory(false));
        dispatch(setIsCourseDisabledHistory(true));
        dispatch(setIsModuleDisabledHistory(true));
        dispatch(setIsUnitDisabledHistory(true));
        dispatch(resetHistorySubjectCase());
        break;
      }
      case 'keyStage': {
        dispatch(
          fetchAllHistoryFilterValues({
            subject: dataToSend.subject,
            keyStage: [value],
            course: [],
            module: [],
            unit: [],
          })
        );
        dispatch(setIsCourseDisabledHistory(false));
        dispatch(setIsModuleDisabledHistory(true));
        dispatch(setIsUnitDisabledHistory(true));
        dispatch(resetHistoryKeyStageCase());
        break;
      }
      case 'course': {
        dispatch(
          fetchAllHistoryFilterValues({
            subject: dataToSend.subject,
            keyStage: dataToSend.keyStage,
            course: [value],
            module: [],
            unit: [],
          })
        );
        dispatch(setIsModuleDisabledHistory(false));
        dispatch(setIsUnitDisabledHistory(true));
        dispatch(resetHistoryCourseCase());
        break;
      }
      default:
    }
    setData([value]);
    dispatch(setHistoryLiveFilter([title, [value]]));
  };
  const iff = (condition, then, otherwise) => (condition ? then : otherwise);
  return (
    <div>
      <FormControl sx={{ width: '100%' }} size="small" id="multiple-select-label">
        <Select
          data-testid="select-history"
          sx={{ maxHeight: '40px', maxWidth: '200px' }}
          value={data}
          onChange={handleChange}
          input={<OutlinedInput size="small" />}
          renderValue={(value) => (value?.length ? value : 'Show All')}
          inputProps={{ 'data-testid': 'selecthandleChange' }}
          size="small"
          margin="dense"
          placeholder="Select"
          MenuProps={MenuProps}
          disabled={iff(
            title === 'subject',
            false,
            iff(title === 'keyStage', isKeyStageDisabledHistory, isCourseDisabledHistory)
          )}
          displayEmpty
        >
          {options.map((name) =>
            name.length > 50 ? (
              <CustomTooltip title={name} placement="left" arrow>
                <MenuItem key={name} value={name} sx={{ whiteSpace: 'normal' }}>
                  {name}
                </MenuItem>
              </CustomTooltip>
            ) : (
              <MenuItem key={name} value={name} sx={{ whiteSpace: 'normal' }}>
                {name}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>
    </div>
  );
};

HistoryDropdownSingle.propTypes = {
  options: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  title: PropTypes.string.isRequired,
};
