import {
  resetLevelField2,
  resetLevelField3,
  setCheckCloneName,
  setEditGameDetails,
  setLevelDropdown,
  setLevelName,
  setStatusGame,
  setValue,
} from '../../store/reducer/gameDevelop';
import { setLiveFilterEdit, setSelectedVocabList } from '../../store/reducer/gameDevelopment';
import { setSnackState } from '../../store/reducer/snackbarReducer';
import { setBackdrop } from '../../store/reducer/uploadFile';
import store from '../../store/store';
import mflConfig from '../../utils/config';
import { fetch, post, update } from '../../utils/request-axios';

export const setLevelFieldName = (textFieldLabel, updatedValue) => {
  switch (textFieldLabel) {
    case 'Level 1 Name':
      store.dispatch(setLevelName(['levelName1', updatedValue]));
      break;
    case 'Level 2 Name':
      store.dispatch(setLevelName(['levelName2', updatedValue]));
      break;
    case 'Level 3 Name':
      store.dispatch(setLevelName(['levelName3', updatedValue]));
      break;
    default:
      break;
  }
};

export const setValueAll = ([labelChanged, value]) => {
  switch (labelChanged) {
    case 'orderOfVocab':
      store.dispatch(setValue(['vocabOrder', value]));
      break;
    case 'mechanics':
      store.dispatch(setValue(['gameMechanics', value]));
      break;
    case 'numberOfRounds':
      store.dispatch(setValue(['numberOfRound', value]));
      break;
    case 'questionsPerRound':
      store.dispatch(setValue(['questionPerRound', value]));
      break;
    case 'attemptsPerQuestion':
      store.dispatch(setValue(['attemptPerQuestion', value]));
      break;
    case 'numberOfDifficultyLevels':
      store.dispatch(setValue(['dificultyLevel', value]));
      break;
    case 'questionField':
      store.dispatch(setValue(['questionLangulage', value]));
      break;
    case 'answerField':
      store.dispatch(setValue(['answerLangulage', value]));
      break;
    default:
      store.dispatch(setValue([labelChanged, value]));
      break;
  }
};

export const setValueLevelAll = ([labelChanged, value, levelNumber]) => {
  switch (levelNumber) {
    case 'level1':
      switch (labelChanged) {
        case 'numberOfDistractors':
          store.dispatch(setLevelDropdown(['numberOfDistractor', value]));
          break;
        case 'sourceOfDistractors':
          store.dispatch(setLevelDropdown(['sourceDistractor', value]));
          break;
        case 'numberOfGaps':
          store.dispatch(setLevelDropdown(['numberOfMissingWord', value]));
          break;
        default:
          store.dispatch(setLevelDropdown([labelChanged, value]));
          break;
      }
      break;
    case 'level2':
      switch (labelChanged) {
        case 'numberOfDistractors':
          store.dispatch(setLevelDropdown(['numberOfDistractor2', value]));
          break;
        case 'sourceOfDistractors':
          store.dispatch(setLevelDropdown(['sourceDistractor2', value]));
          break;
        case 'numberOfGaps':
          store.dispatch(setLevelDropdown(['numberOfMissingWord2', value]));
          break;
        default:
          store.dispatch(setLevelDropdown([labelChanged.concat('2'), value]));
          break;
      }
      break;
    case 'level3':
      switch (labelChanged) {
        case 'numberOfDistractors':
          store.dispatch(setLevelDropdown(['numberOfDistractor3', value]));
          break;
        case 'sourceOfDistractors':
          store.dispatch(setLevelDropdown(['sourceDistractor3', value]));
          break;
        case 'numberOfGaps':
          store.dispatch(setLevelDropdown(['numberOfMissingWord3', value]));
          break;
        default:
          store.dispatch(setLevelDropdown([labelChanged.concat('3'), value]));
          break;
      }
      break;
    default:
      break;
  }
};

export const saveCreateNewGame = (dropdowns, status, filterObj) => async () => {
  store.dispatch(setBackdrop(true));
  const newDropdown = { ...dropdowns, ...filterObj };
  newDropdown.name = dropdowns?.name?.trim();
  if (newDropdown?.numberOfDistractor === '' || newDropdown?.numberOfDistractor === null)
    newDropdown.numberOfDistractor = null;
  if (newDropdown?.numberOfDistractor2 === '' || newDropdown?.numberOfDistractor2 === null)
    newDropdown.numberOfDistractor2 = null;
  if (newDropdown?.numberOfDistractor3 === '' || newDropdown?.numberOfDistractor3 === null)
    newDropdown.numberOfDistractor3 = null;
  const response = await post(`${mflConfig.API_URL}game/`, {
    ...newDropdown,
    gameStatus: status,
  });
  if (response?.status === 200 || response?.status === 201)
    store.dispatch(
      setSnackState({
        openSnackbar: true,
        message:
          status === 'Draft'
            ? 'All the changes have been saved, and the status of the game is Draft'
            : 'All the changes have been saved, and status of the game is Completed',
        type: status === 'Draft' ? 'info' : 'success',
      })
    );
  store.dispatch(setBackdrop(false));
};

export const fetchSingleGameDetails = (id) => async (dispatch) => {
  dispatch(setBackdrop(true));
  const response = await fetch(`${mflConfig.API_URL}game/${id}`);
  const filtersObjResp = {};
  filtersObjResp.subject =
    response.data.selectedSubject === '' ? [] : [response.data.selectedSubject];
  filtersObjResp.keyStage =
    response.data.selectedKeyStage === '' ? [] : [response.data.selectedKeyStage];
  filtersObjResp.course = response.data.selectedCourse === '' ? [] : [response.data.selectedCourse];
  filtersObjResp.module =
    response.data.selectedModule === '' ? [] : response.data.selectedModule.split('$@$@');
  filtersObjResp.unit =
    response.data.selectedUnit === '' ? [] : response.data.selectedUnit.split('$@$@');
  filtersObjResp.vocabType =
    response.data.selectedVocabType === '' ? [] : response.data.selectedVocabType.split('$@$@');
  filtersObjResp.subGroup =
    response.data.selectedSubGroup === '' ? [] : response.data.selectedSubGroup.split('$@$@');
  delete response?.data?.selectedSubject;
  delete response?.data?.selectedKeyStage;
  delete response?.data?.selectedCourse;
  delete response?.data?.selectedModule;
  delete response?.data?.selectedUnit;
  delete response?.data?.selectedVocabType;
  delete response?.data?.selectedSubGroup;
  dispatch(setLiveFilterEdit(filtersObjResp));
  delete response?.data?.createdAt;
  delete response?.data?.id;
  delete response?.data?.updatedAt;
  delete response?.data?.userId;
  delete response?.data?.deleteStatus;
  dispatch(setStatusGame(response?.data?.gameStatus));
  delete response?.data?.gameStatus;
  if (response?.data?.caseSensitivity) response.data.caseSensitivity = '';
  if (response?.data?.selectedVocabs[0] !== null)
    dispatch(setSelectedVocabList(response?.data?.selectedVocabs));
  delete response?.data?.selectedVocabs;
  dispatch(setEditGameDetails(response?.data));
  dispatch(setCheckCloneName(response?.data?.name));
  dispatch(setBackdrop(false));
};

export const resetLevelFields = (noOfDifficultyLevels) => async (dispatch) => {
  if (noOfDifficultyLevels === '1') {
    dispatch(resetLevelField2());
    dispatch(resetLevelField3());
  } else if (noOfDifficultyLevels === '2') {
    dispatch(resetLevelField3());
  }
};

export const saveEditGame = (dropdowns, status, id, filterObj) => async () => {
  store.dispatch(setBackdrop(true));
  const newDropdown = { ...JSON.parse(JSON.stringify(dropdowns)), ...filterObj };
  newDropdown.name = dropdowns?.name?.trim();
  if (status === 'Completed') {
    newDropdown.attemptPerQuestion = newDropdown?.attemptPerQuestion?.toString();
    newDropdown.numberOfRound = newDropdown?.numberOfRound?.toString();
    newDropdown.questionLangulage = newDropdown?.questionLangulage?.toString();
    newDropdown.questionPerRound = newDropdown?.questionPerRound?.toString();
    newDropdown.numberOfDistractor = newDropdown?.numberOfDistractor?.toString();
    if (newDropdown?.dificultyLevel === '2')
      newDropdown.numberOfDistractor2 = newDropdown?.numberOfDistractor2?.toString();
    else if (newDropdown?.dificultyLevel === '3') {
      newDropdown.numberOfDistractor3 = newDropdown?.numberOfDistractor3?.toString();
      newDropdown.numberOfDistractor2 = newDropdown?.numberOfDistractor2?.toString();
    }
    if (newDropdown?.numberOfDistractor === '' || newDropdown?.numberOfDistractor === null)
      newDropdown.numberOfDistractor = null;
    if (newDropdown?.numberOfDistractor2 === '' || newDropdown?.numberOfDistractor2 === null)
      newDropdown.numberOfDistractor2 = null;
    if (newDropdown?.numberOfDistractor3 === '' || newDropdown?.numberOfDistractor3 === null)
      newDropdown.numberOfDistractor3 = null;
    if (newDropdown.dificultyLevel === '1') {
      delete newDropdown.levelName2;
      delete newDropdown.numberOfDistractor2;
      delete newDropdown.sourceDistractor2;
      delete newDropdown.numberOfMissingWord2;
      delete newDropdown.imagePrompt2;
      delete newDropdown.audioPrompt2;
      delete newDropdown.textPrompt2;
      delete newDropdown.levelName3;
      delete newDropdown.numberOfDistractor3;
      delete newDropdown.sourceDistractor3;
      delete newDropdown.numberOfMissingWord3;
      delete newDropdown.imagePrompt3;
      delete newDropdown.audioPrompt3;
      delete newDropdown.textPrompt3;
    } else if (newDropdown.dificultyLevel === '2') {
      delete newDropdown.levelName3;
      delete newDropdown.numberOfDistractor3;
      delete newDropdown.sourceDistractor3;
      delete newDropdown.numberOfMissingWord3;
      delete newDropdown.imagePrompt3;
      delete newDropdown.audioPrompt3;
      delete newDropdown.textPrompt3;
    }
  }
  const response = await update(`${mflConfig.API_URL}game/${id}`, {
    ...newDropdown,
    gameStatus: status,
  });
  if (response?.status === 200 || response?.status === 201)
    await store.dispatch(
      setSnackState({
        openSnackbar: true,
        message:
          status === 'Draft'
            ? 'All the changes have been saved, and the status of the game is Draft'
            : 'All the changes have been saved, and status of the game is Completed',
        type: status === 'Draft' ? 'info' : 'success',
      })
    );
  store.dispatch(setBackdrop(false));
};
