import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import viewRecordReducer from './reducer/viewRecord';
import languageDatabaseReducer from './reducer/languageDatabase';
import uploadFileReducer from './reducer/uploadFile';
import snackbarReducer from './reducer/snackbarReducer';
import filterSelectionReducer from './reducer/filterSelection';
import filterApplyReducer from './reducer/filterApply';
import gameDbReducer from './reducer/gameDb';
import gameDevelopment from './reducer/gameDevelopment';
import historyLogsReducer from './reducer/historyLogs';
import gameDevelopReducer from './reducer/gameDevelop';
import authenticateReducer from './reducer/authenticate';
import errorLogsReducer from './reducer/errorLogs';

export default configureStore({
  reducer: {
    editRecord: viewRecordReducer,
    languageDatabase: languageDatabaseReducer,
    uploadFile: uploadFileReducer,
    snackLaunched: snackbarReducer,
    filterSelection: filterSelectionReducer,
    filterApply: filterApplyReducer,
    gameDb: gameDbReducer,
    gameDevelopment,
    historyLogs: historyLogsReducer,
    gameDevelop: gameDevelopReducer,
    authenticate: authenticateReducer,
    errorLogs: errorLogsReducer,
  },
  middleware: window.location.origin.includes('localhost')
    ? (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(logger)
    : (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});
