import { setErrorResponse, setErrorResponseData } from '../../store/reducer/errorLogs';
import { setBackdrop } from '../../store/reducer/uploadFile';
import mflConfig from '../../utils/config';
import { fetch } from '../../utils/request-axios';

export const fetchErrorLogs = (pageNo) => async (dispatch) => {
  dispatch(setBackdrop(true));
  const limit = 50;
  const skip = limit * pageNo;
  const response = await fetch(`${mflConfig.API_URL}error-logs/?$limit=${limit}&$skip=${skip}`);
  dispatch(setErrorResponse(response.data.data));
  dispatch(setErrorResponseData(response.data.data));
  dispatch(setBackdrop(false));
};

export const fetchFileDownloadUrl = (fileName) => async (dispatch) => {
  dispatch(setBackdrop(true));
  const response = await fetch(`${mflConfig.API_URL}error-logs/?fileName=${fileName}`);
  dispatch(setBackdrop(false));
  return response.data;
};
