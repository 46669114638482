import React, { useEffect, useState } from 'react';
import { Alert, Backdrop, CircularProgress, Grid, Snackbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { ButtonCustom } from '../../custom/ButtonCustom';
import { setSnackState, snackPopup } from '../../../store/reducer/snackbarReducer';
import { SubHeader } from '../../custom/SubHeader';
import { Navbar } from '../../Navbar';
import { ActionContainer } from './ActionContainer';
import { FilterContainer } from './FilterContainer';
import { TableWrapper } from './TableWrapper';
import { CustomMenu } from '../../custom/CustomMenu';
import {
  changeStatusToDownloadAction,
  deleteGame,
  fetchVersionHistory,
  postGameFilters,
} from '../../../services/actions/gameDb';
import {
  resetVersionHistory,
  setDeletePopup,
  setIsDeleted,
  setVersionPopup,
  resetMenuItems,
  resetWithoutDataToSend,
  setMenuItems,
  updatePageNo,
  changeStatusToDownloaded,
  resetWithoutGameFiltersState,
} from '../../../store/reducer/gameDb';
import { resetAll, setCloneFlag, setEditFlag } from '../../../store/reducer/gameDevelop';
import AlertDialog from '../../custom/AlertDialog';
import AlertVersions from './AlertVersions';
import { LOAD_MORE, NO_RESULT_FOUND } from '../../../assets/constantData/gameConstant';
import { resetGameFilters } from '../../../store/reducer/gameDevelopment';
import { setBackdrop } from '../../../store/reducer/uploadFile';
import { NewFilterContainer } from './NewFilterContainer';
import { fetchAllNewGameFilterValues } from '../../../services/actions/gameDevelopment';

export const GameDashboard = () => {
  const filters = useSelector((state) => state.gameDb.filters);
  const newFilters = useSelector((state) => state.gameDb.newFilterOptions);
  const newFilterEntries = Object.entries(newFilters);
  const gameData = useSelector((states) => states.gameDb);
  const dispatch = useDispatch();
  const { openSnackbar, message, type } = useSelector(snackPopup);
  const backdrop = useSelector((states) => states.uploadFile.backdrop);
  const newLiveFilters = useSelector((state) => state.gameDb.newLiveFilters);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [gameId, setgameId] = React.useState();
  const [currGame, setCurrGame] = React.useState('');
  const [previewUrl, setPreviewUrl] = React.useState('');
  const [exportUrl, setExportUrl] = React.useState('');
  const [nameOfGame, setNameOfGame] = useState('');
  const navigate = useNavigate();
  const handleIconClick = (e, id, gameName, previewURL, exportURL) => {
    setNameOfGame(gameName);
    setOpen(true);
    setAnchorEl(e.currentTarget);
    setgameId(id);
    setCurrGame(gameName);
    setPreviewUrl(previewURL);
    setExportUrl(exportURL);
    const gameStatusObj = gameData.list.filter((obj) => obj.id === id)[0];
    if (gameStatusObj.gameStatus === 'Completed' || gameStatusObj.gameStatus === 'Downloaded') {
      dispatch(setMenuItems(['Preview', 'Export']));
    } else dispatch(resetMenuItems());
  };
  useEffect(() => {
    dispatch(fetchAllNewGameFilterValues({ subject: [] }));
  }, []);

  const useStyle = makeStyles(() => ({
    okayAlertButtonClass: {
      '&.MuiButtonBase-root': {
        backgroundColor: '#003057',
      },
      textTransform: 'capitalize',
    },
    cancelAlertButtonClass: {
      '&.MuiButtonBase-root': {
        color: '#333333',
        borderColor: '#333333',
      },
      textTransform: 'capitalize',
    },
  }));

  const classes = useStyle();

  const handleIconClose = async (item) => {
    setOpen(false);
    setAnchorEl(null);
    if (item === 'Edit') {
      navigate(`/gameDevelopment/${gameId}`);
      dispatch(setEditFlag(true));
    } else if (item === 'Delete') {
      dispatch(setDeletePopup(true));
    } else if (item === 'Clone') {
      const clone = 'clone';
      navigate(`/gameDevelopment/${gameId}/${clone}`);
      dispatch(setCloneFlag(true));
    } else if (item === 'Versions') {
      dispatch(setVersionPopup(true));
      dispatch(fetchVersionHistory(gameId));
    } else if (item === 'Preview') {
      if (!previewUrl) {
        dispatch(
          setSnackState({
            openSnackbar: true,
            message: 'Failed to Preview due to Preview URL not found',
            type: 'error',
          })
        );
      } else window.open(previewUrl, '_blank', 'noreferrer');
    } else if (item === 'Export') {
      try {
        const indexOfClicked = gameData.list.findIndex((exportItem) => exportItem.id === gameId);
        if (!exportUrl) {
          const errorForNoURL = { name: 'No URL error', message: 'Export URL not found' };
          errorForNoURL.prototype = Error.prototype;
          throw errorForNoURL.message;
        }
        await changeStatusToDownloadAction(gameId);
        const filename = exportUrl;
        const anchorLink = document.createElement('a');
        anchorLink.href = filename;
        anchorLink.download = 'Download Template';
        document.body.appendChild(anchorLink);
        anchorLink.click();
        anchorLink.parentNode.removeChild(anchorLink);
        dispatch(changeStatusToDownloaded([indexOfClicked, 'Downloaded']));
      } catch (error) {
        dispatch(setBackdrop(false));
        dispatch(
          setSnackState({
            openSnackbar: true,
            message: `Failed to export due to ${error}`,
            type: 'error',
          })
        );
      }
    }
  };

  useEffect(() => {
    dispatch(resetAll());
    dispatch(postGameFilters({ ...gameData.dataToSend, ...newLiveFilters }, 0));
    dispatch(resetGameFilters());
  }, []);

  useEffect(() => {
    if (gameData.isDeleted === true && gameData.isFilterApplied) {
      dispatch(resetWithoutDataToSend(gameData.dataToSend));
      dispatch(postGameFilters({ ...gameData.dataToSend, ...newLiveFilters }, 0));
    } else if (gameData.isDeleted === true) {
      dispatch(resetWithoutGameFiltersState());
      dispatch(fetchAllNewGameFilterValues({ subject: [] }));
      dispatch(postGameFilters({ ...gameData.dataToSend, ...newLiveFilters }, 0));
    }
  }, [gameData.isDeleted, gameData.isFilterApplied]);

  const handleLoadMore = () => {
    dispatch(updatePageNo());
    dispatch(postGameFilters({ ...gameData.dataToSend, ...newLiveFilters }, gameData.pageNo + 1));
  };

  const handleSnackCloseGame = () => {
    dispatch(
      setSnackState({
        openSnackbar: false,
        message: '',
        type: '',
      })
    );
  };

  const deleteButtonClick = () => {
    dispatch(setDeletePopup(false));
    dispatch(deleteGame([gameId]));
    dispatch(setIsDeleted(false));
  };

  const compare = () => {
    if (gameData.data.length === 0 && gameData.list.length === 0) {
      return <h1>{NO_RESULT_FOUND}</h1>;
    }
    if (gameData.data.length === 50)
      return (
        <ButtonCustom
          valueProp={LOAD_MORE}
          variantProp="outlined"
          classProp="loadMore"
          typoClassProp="typoLoadMore"
          onClick={() => handleLoadMore()}
          customAttribute={{ style: { textTransform: 'none' }, 'data-testid': 'loadMore' }}
        />
      );
    return null;
  };
  return (
    <>
      <Navbar />
      <Grid paddingX={4}>
        <SubHeader sidemenu title="Game Database" searchBox />
        <Snackbar
          open={openSnackbar}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={() => handleSnackCloseGame()}
        >
          <Alert severity={type === '' ? 'info' : type} sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>
        <NewFilterContainer filters={newFilterEntries} />
        <FilterContainer filters={filters} />
        <ActionContainer />
        <TableWrapper
          isHistoryLogs={false}
          rows={gameData.list}
          columns={gameData.columns}
          isIcon={<MoreHorizIcon />}
          handleIconClick={(e, id, gameName, previewURL, exportURL) =>
            handleIconClick(e, id, gameName, previewURL, exportURL)
          }
        />
        <Grid display="flex" justifyContent="center" paddingY={2}>
          {compare()}
        </Grid>
      </Grid>
      {open && (
        <CustomMenu
          open={open}
          anchorEl={anchorEl}
          handleClose={handleIconClose}
          menuItems={gameData.menuItems}
        />
      )}
      <AlertDialog
        okayTestId="alertOk"
        cancelTestId="alertCancel"
        open={gameData.deletePopup}
        okBtn="Confirm"
        cancelBtn="Cancel"
        okClick={deleteButtonClick}
        cancelClick={() => dispatch(setDeletePopup(false))}
        cancelButtonClass={classes.cancelAlertButtonClass}
        okayButtonClass={classes.okayAlertButtonClass}
        cancelVariant="outlined"
        okayVariant="contained"
        contentText="Are you sure you want to delete this game?"
        contentText2={`${nameOfGame}`}
      />
      <AlertVersions
        open={gameData.versionPopup}
        title={`Game Name - \n${currGame}`}
        closeBtn
        closeButtonClick={() => {
          dispatch(setVersionPopup(false));
          dispatch(resetVersionHistory());
        }}
        gameId={gameId}
      />
      <Backdrop sx={{ color: '#fff', zIndex: 10000 }} open={backdrop}>
        <CircularProgress />
      </Backdrop>
    </>
  );
};
