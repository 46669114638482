import * as React from 'react';
import {
  ListItemIcon,
  OutlinedInput,
  MenuItem,
  FormControl,
  Select,
  Checkbox,
  Tooltip,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@mui/styles';
import { MenuProps, useStyles } from '../../assets/constantData/utils';
import { setDataToSend } from '../../store/reducer/gameDb';

const CustomTooltip = withStyles({
  tooltip: {
    fontSize: '14px',
  },
})(Tooltip);

export const DropdownGameDatabase = ({ options, title }) => {
  const [data, setData] = React.useState([]);
  const dispatch = useDispatch();
  const dataToSend = useSelector((state) => state.gameDb.dataToSend);
  const isFilterApplied = useSelector((state) => state.gameDb.isFilterApplied);

  React.useEffect(() => {
    if (!isFilterApplied) setData([]);
  }, [isFilterApplied]);
  const isAllSelected = options.length > 0 && data?.length === options.length;
  const classes = useStyles();

  const handleDashboardChange = (event) => {
    const {
      target: { value },
    } = event;

    if (value[value.length - 1] === 'all') {
      setData(data.length === options.length ? [] : options);
      dispatch(
        setDataToSend(
          data.length === options.length
            ? { ...dataToSend, [title]: [] }
            : { ...dataToSend, [title]: options }
        )
      );
      return;
    }
    setData(value);
    dispatch(setDataToSend({ ...dataToSend, [title]: event.target.value }));
  };
  const iff = (condition, then, otherwise) => (condition ? then : otherwise);

  return (
    <div>
      <FormControl size="small" sx={{ width: '100%' }}>
        <Select
          data-testid="check-select"
          sx={{ maxHeight: '40px', maxWidth: '200px' }}
          multiple
          value={data}
          inputProps={{ 'data-testid': 'select-input' }}
          onChange={handleDashboardChange}
          input={<OutlinedInput size="small" />}
          renderValue={(value) =>
            iff(value?.length, iff(Array.isArray(value), value.join(', '), value), 'Show All')
          }
          size="small"
          margin="dense"
          placeholder="Select"
          MenuProps={MenuProps}
          displayEmpty
        >
          <MenuItem
            value="all"
            classes={{
              root: isAllSelected ? classes.selectedAll : '',
            }}
          >
            <ListItemIcon>
              <Checkbox
                sx={{ padding: 0 }}
                classes={{ indeterminate: classes.indeterminateColor }}
                checked={isAllSelected}
                indeterminate={data?.length > 0 && data.length < options.length}
              />
            </ListItemIcon>
            Select All
          </MenuItem>
          {options?.map((name) =>
            name.length > 50 ? (
              <CustomTooltip title={name} placement="left" arrow>
                <MenuItem key={name} value={name} sx={{ whiteSpace: 'normal' }}>
                  <ListItemIcon>
                    <Checkbox sx={{ padding: 0 }} checked={data.indexOf(name) > -1} />
                  </ListItemIcon>
                  {name}
                </MenuItem>
              </CustomTooltip>
            ) : (
              <MenuItem key={name} value={name} sx={{ whiteSpace: 'normal' }}>
                <ListItemIcon>
                  <Checkbox sx={{ padding: 0 }} checked={data.indexOf(name) > -1} />
                </ListItemIcon>
                {name}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>
    </div>
  );
};

DropdownGameDatabase.propTypes = {
  title: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
};

DropdownGameDatabase.defaultProps = {
  title: '',
};
