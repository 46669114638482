import { PropTypes } from 'prop-types';
import { Grid, TextField, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { setLevelFieldName } from '../../services/actions/gameDevelop';

export const TextfieldWithLabel = ({ labelValue }) => {
  const handlechange = (value) => setLevelFieldName(labelValue, value);
  const editFlag = useSelector((state) => state.gameDevelop.editFlag);
  const levelName1 = useSelector((state) => state?.gameDevelop?.dropdowns?.levelName1);
  const levelName2 = useSelector((state) => state?.gameDevelop?.dropdowns?.levelName2);
  const levelName3 = useSelector((state) => state?.gameDevelop?.dropdowns?.levelName3);

  const defaultValueFunction = (labelValueTextField) => {
    switch (labelValueTextField) {
      case 'Level 1 Name':
        return levelName1;
      case 'Level 2 Name':
        return levelName2;
      case 'Level 3 Name':
        return levelName3;
      default:
        break;
    }
    return '';
  };

  return (
    <Grid paddingTop={2} xs={8.1}>
      <Typography>{labelValue}</Typography>
      <TextField
        size="small"
        required
        fullWidth
        inputProps={{ 'data-testid': 'textfield-custom' }}
        defaultValue={editFlag ? defaultValueFunction(labelValue) : ''}
        variant="outlined"
        onChange={(e) => handlechange(e.target.value)}
      />
    </Grid>
  );
};

TextfieldWithLabel.propTypes = {
  labelValue: PropTypes.string,
};

TextfieldWithLabel.defaultProps = {
  labelValue: '',
};
