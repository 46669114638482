import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  appliedFilters: {
    subject: [],
    keyStage: [],
    course: [],
    module: [],
    unit: [],
    assets: [],
    searchStr: '',
  },
  isKeyStageSelected: true,
  isCourseSelected: true,
  isModuleSelected: true,
  isUnitSelected: true,
  filterValues: {
    subject: [],
    keyStage: [],
    course: [],
    module: [],
    unit: [],
    assets: ['NY'],
  },
};

const filterApply = createSlice({
  name: 'filterApply',
  initialState,
  reducers: {
    pushSelectedReducer: (state, { payload: [selected, keys] }) => {
      state.appliedFilters[keys] = selected;
    },
    setFilterValues: (state, { payload: [label, options] }) => {
      state.filterValues[label] = options;
    },
    setSearchString: (state, action) => {
      state.appliedFilters.searchStr = action.payload;
    },
    clearSearchString: (state) => {
      state.appliedFilters.searchStr = '';
    },
    resetFilters: (state) => {
      state.appliedFilters = initialState.appliedFilters;
      state.isKeyStageSelected = initialState.isKeyStageSelected;
      state.isCourseSelected = initialState.isCourseSelected;
      state.isModuleSelected = initialState.isModuleSelected;
      state.isUnitSelected = initialState.isUnitSelected;
      state.filterValues.keyStage = initialState.filterValues.keyStage;
      state.filterValues.course = initialState.filterValues.course;
      state.filterValues.module = initialState.filterValues.module;
      state.filterValues.unit = initialState.filterValues.unit;
    },
    setIsKeyStageSelected: (state, action) => {
      state.isKeyStageSelected = action?.payload;
    },
    setIsCourseSelected: (state, action) => {
      state.isCourseSelected = action?.payload;
    },
    setIsModuleSelected: (state, action) => {
      state.isModuleSelected = action?.payload;
    },
    setIsUnitSelected: (state, action) => {
      state.isUnitSelected = action?.payload;
    },
    resetSubjectCase: (state) => {
      state.appliedFilters.keyStage = initialState.appliedFilters.keyStage;
      state.appliedFilters.course = initialState.appliedFilters.course;
      state.appliedFilters.module = initialState.appliedFilters.module;
      state.appliedFilters.unit = initialState.appliedFilters.unit;
    },
    resetKeyStageCase: (state) => {
      state.appliedFilters.course = initialState.appliedFilters.course;
      state.appliedFilters.module = initialState.appliedFilters.module;
      state.appliedFilters.unit = initialState.appliedFilters.unit;
    },
    resetCourseCase: (state) => {
      state.appliedFilters.module = initialState.appliedFilters.module;
      state.appliedFilters.unit = initialState.appliedFilters.unit;
    },
    resetModuleCase: (state) => {
      state.appliedFilters.unit = initialState.appliedFilters.unit;
    },
  },
});

export const {
  pushSelectedReducer,
  setSearchString,
  clearSearchString,
  resetFilters,
  setIsKeyStageSelected,
  setIsCourseSelected,
  setIsModuleSelected,
  setIsUnitSelected,
  setFilterValuesSubject,
  setFilterValuesKeyStage,
  setFilterValuesCourse,
  setFilterValuesModule,
  setFilterValuesUnit,
  setFilterValues,
  resetSubjectCase,
  resetKeyStageCase,
  resetCourseCase,
  resetModuleCase,
} = filterApply.actions;

export default filterApply.reducer;
