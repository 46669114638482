/* eslint-disable prefer-destructuring */
import { fetch, post } from '../../utils/request-axios';
import mflConfig from '../../utils/config';
import { fetchFilterAll } from '../../store/reducer/filterSelection';
import { setBackdrop } from '../../store/reducer/uploadFile';
import store from '../../store/store';
import { setFilterValues } from '../../store/reducer/filterApply';

export const fetchAllFilters = () => async (dispatch) => {
  store.dispatch(setBackdrop(true));
  const response = await fetch(`${mflConfig.API_URL}language-filter/?filterFor=language`);
  dispatch(fetchFilterAll(response?.data));
  store.dispatch(setBackdrop(false));
};

export const fetchAllFiltersNew = (appliedFilters) => async (dispatch) => {
  store.dispatch(setBackdrop(true));
  const response = await post(`${mflConfig.API_URL}filter`, appliedFilters);
  let label;
  let options;
  if (typeof response?.data?.subject?.data === 'object') {
    label = Object.keys(response?.data)[0]; // subject
    options = Object.values(response?.data?.subject)[0]; // []
  } else {
    label = Object.keys(response?.data)[0]; // subject
    options = Object.values(response?.data)[0]; // ["french", "german"]
  }
  dispatch(setFilterValues([label, options]));
  store.dispatch(setBackdrop(false));
};
