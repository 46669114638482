export const NUMBER_OF_ROUND = 'Number of Rounds';
export const QUESTION_PER_ROUND = 'Questions per Round';
export const ATTEMPT_PER_QUESTION = 'Attempts per Question';
export const NUMERICAL_ARRAY = ['1', '2', '3'];
export const MAX_VALUE = 'Maximum value you can type is 20';
export const LEVEL_DROPDOWN_VALUES = [
  ['0', '1', '2', '3', '4', '5', '6'],
  ['Same Sub Group', 'Different Sub Group', 'Any item in vocab set'],
  ['25%', '50%', '75%', '100%'],
];
export const LEVEL_PROMPT_VALUES = ['Always', 'Never', '10 seconds', '20 seconds'];
export const TASK_ACTION_KEYS = ['Task Action', 'Question Field', 'Answer Field'];
export const TASK_ACTION_VALUES = [
  ['Matching', 'Missing Words', 'Missing Letters', 'Arrange words/letters', 'Flashcards'],
  ['Item 1', 'Item 2'],
  ['Item 1', 'Item 2'],
];
export const GAME_MECHANICS_KEYS = ['Mechanics'];
export const GAME_MECHANICS_VALUES = [
  ['Selecting', 'Navigating user-led', 'Navigating item-led', 'Type in', 'Flashcards'],
];
export const GAME_THEME_KEYS = ['Theme', 'Sub Theme'];
export const GAME_THEME_VALUES = [['Jungle', 'Medieval'], ['Sub theme 1']];
export const GAME_FUNCTIONALITY_KEYS = ['Case Sensitivity', 'Type of Keyboard'];
export const GAME_FUNCTIONALITY_VALUES = [
  ['on', 'off'],
  [
    'Special characters from answer phrase',
    'All variations from answer phrase',
    'All special characters from language',
  ],
];
export const DIFFICULTY_LEVELS_KEYS = ['Number of Difficulty Levels'];
export const DIFFICULTY_LEVELS_VALUES = [['1', '2', '3']];
export const APPLY_FILTERS = 'Apply Filters';
export const CLEAR_ALL = 'Clear All';
export const LOAD_MORE = 'Load More';
export const NO_RESULT_FOUND = 'No result found';
export const ALERT_CONTENT_VOCAB_CONFIRM = 'Please add at least 1 item in Confirmed Vocab List';
export const ALERT_CONTENT_CLONE = 'Please change the name of the game in case of Clone';
export const GAME_DEVELOPMENT = 'Game Development';
export const GAME_NAME = 'Game Name';
export const SELECTING_COURSE = 'Selecting Course';
export const SELECTING_VOCABLIST = 'Selecting Vocab List';
