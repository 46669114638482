import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Button,
  TextField,
  Snackbar,
  Alert,
  Container,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import Pencil from '../assets/icons/pencil.png';
import { Navbar } from './Navbar';
import {
  setEditRecordDetail,
  editRecordSelector,
  getViewRecordDetail,
  setDataToBeUpdated,
} from '../store/reducer/viewRecord';
import { SubHeader } from './custom/SubHeader';
import { fetchRecordWithId, UpdateRecordWithId } from '../services/actions/viewRecord';
import { snackPopup, setSnackState } from '../store/reducer/snackbarReducer';
import { SelectCustom } from './custom/SelectCustom';
import { fetchAllRecordsAfterEdit } from '../services/actions/languageDatabase';
import { passingSelectedFiltersafterEdit } from '../services/actions/filterApply';

const useStyle = makeStyles(() => ({
  viewEdit: {
    height: '1.17em',
    marginLeft: '20px',
    cursor: 'pointer',
  },
  boxtable: {
    display: 'flex',
  },
  saveButton: {
    '&.MuiButtonBase-root': {
      height: '30px',
      margin: '3px 20px 15px',
    },
    textTransform: 'capitalize',
  },
  cancelButton: {
    '&.MuiButtonBase-root': {
      color: '#333333',
      backgroundColor: '#FFFFFF',
      border: '2px solid #333333',
      height: '30px',
      flexGrow: 0,
      marginTop: '3px',
    },
    textTransform: 'capitalize',
  },
  editInput: {
    '&.MuiTextField-root': {
      width: '350px',
      height: '40px',
    },
  },
  backBox: {
    height: '40px',
    marginLeft: '10px',
  },
  footerBox: {
    backgroundColor: '#FFFFFF',
    position: 'sticky',
    bottom: '0',
    height: '40px',
    display: 'flex',
    justifyContent: 'end',
  },
}));

const ViewRecord = () => {
  const dispatch = useDispatch();
  const backdrop = useSelector((states) => states.uploadFile.backdrop);
  const [disableBtn, setDisableBtn] = useState(false);
  const { editRecordDetail, updatedData, viewRecordData } = useSelector(editRecordSelector);
  const { openSnackbar, message, type } = useSelector(snackPopup);
  const { id } = useParams();

  const createData = (title, value) => ({ title, value });

  const handleFilterData = (key, value) => {
    switch (key) {
      case 'keyStage':
        return createData('Key Stage', value);
      case 'subGroup':
        return createData('Sub Group', value);
      case 'vocabType':
        return createData('Vocab Type', value);
      case 'alternateItem1':
        return createData('Alternate Item 1', value);
      case 'alternateItem2':
        return createData('Alternate Item 2', value);
      case 'caseSensitiveItem1':
        return createData('Case Sensitive Item 1', value);
      case 'caseSensitiveItem2':
        return createData('Case Sensitive Item 2', value);
      case 'imageItem1':
        return createData('Image Item 1', value);
      case 'imageItem2':
        return createData('Image Item 2', value);
      case 'audioItem1':
        return createData('Audio Item 1', value);
      case 'audioItem2':
        return createData('Audio Item 2', value);
      default:
        return createData(key[0].toUpperCase() + key.slice(1), value);
    }
  };

  useEffect(() => {
    dispatch(fetchRecordWithId(id));
  }, []);

  const handleClick = () => {
    dispatch(setEditRecordDetail(true));
  };
  const handleCancel = () => {
    dispatch(setEditRecordDetail(false));
    dispatch(fetchRecordWithId(id));
  };
  const handleSnackClose = () => {
    dispatch(
      setSnackState({
        openSnackbar: false,
        message: '',
        type: '',
      })
    );
  };
  const classes = useStyle();
  const rowValues = Object.entries(viewRecordData).map((o) => o[1].value);
  const recordsData = Object.entries(viewRecordData);
  recordsData.shift();
  recordsData.splice(recordsData.length - 2, 2);
  const vocabId = rowValues[rowValues.length - 2];
  const idsArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];
  const languageDbData = useSelector((states) => states.languageDatabase);
  const appliedFilters = useSelector((state) => state.filterApply.appliedFilters);

  const iff = (condition, then, otherwise) => (condition ? then : otherwise);
  useEffect(() => {
    let result = false;
    Object.values(viewRecordData).forEach((obj) => {
      if (obj.value.length === 0) {
        result = true;
      }
    });
    if (result) setDisableBtn(true);
    else setDisableBtn(false);
  }, [viewRecordData]);

  const updateData = (e, rowKey, isDropdown = false) => {
    if (isDropdown) {
      dispatch(
        getViewRecordDetail({
          ...viewRecordData,
          [rowKey]: { ...viewRecordData[rowKey], value: e },
        })
      );
      dispatch(setDataToBeUpdated({ ...updatedData, [rowKey]: e }));
    } else {
      dispatch(
        getViewRecordDetail({
          ...viewRecordData,
          [rowKey]: { ...viewRecordData[rowKey], value: e.target.value },
        })
      );
      dispatch(setDataToBeUpdated({ ...updatedData, [rowKey]: e.target.value }));
    }
  };
  const fetchUpdatedValue = (value, rowKeysJsonValue) => {
    updateData(value, rowKeysJsonValue, true);
  };
  const sendUpdatedData = async () => {
    await dispatch(UpdateRecordWithId(updatedData, id));
    setTimeout(() => {
      dispatch(fetchRecordWithId(id));
    }, 150);
    dispatch(setDataToBeUpdated({}));
    if (!languageDbData.isFilterApplied) dispatch(fetchAllRecordsAfterEdit(languageDbData?.pageNo));
    else dispatch(passingSelectedFiltersafterEdit(appliedFilters, languageDbData?.pageNo));

    handleCancel();
  };
  return (
    <Box>
      <Navbar noNavigate={editRecordDetail} />
      <Container maxWidth="large">
        <SubHeader
          backBtn={!editRecordDetail}
          sidemenu={!editRecordDetail}
          title={editRecordDetail ? 'Edit Record' : 'View Record'}
        />
      </Container>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={1500}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => handleSnackClose()}
      >
        <Alert severity={type} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '80%',
          margin: '0 auto',
        }}
      >
        <h3>Vocab ID: {vocabId} </h3>
        {!editRecordDetail && (
          <Tooltip title="Click this to Edit the data">
            <img
              data-testid="pencil"
              onClick={handleClick}
              className={classes.viewEdit}
              src={Pencil}
              alt="edit"
              aria-hidden
            />
          </Tooltip>
        )}
      </Box>
      <Box className={classes.boxtable}>
        <Table
          sx={{
            width: '80%',
            margin: '0 auto',
            marginBottom: '20px',
          }}
        >
          <TableBody>
            {recordsData.map(([key, value], idx) => {
              const data = handleFilterData(key, value);
              return (
                <TableRow
                  sx={editRecordDetail ? { border: 'none' } : { borderBottom: '.1px solid grey' }}
                  key={idsArr[idx]}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ width: '200px', borderBottom: 'none', padding: '5px' }}
                  >
                    {data.title}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ borderBottom: 'none', padding: '5px', overflowWrap: 'anywhere' }}
                  >
                    {editRecordDetail
                      ? iff(
                          idsArr[idx] === 10 || idsArr[idx] === 15,
                          <SelectCustom
                            className="selectBox"
                            fetchUpdatedValue={fetchUpdatedValue}
                            selectConstantData={[
                              { label: 'off', value: 'off' },
                              { label: 'on', value: 'on' },
                            ]}
                            incomingResponse={data.value.value}
                            rowKeysJson={
                              data.title === 'Case Sensitive Item 1'
                                ? 'caseSensitiveItem1'
                                : 'caseSensitiveItem2'
                            }
                          />,
                          <TextField
                            data-testid="standard-required"
                            size="small"
                            id="standard-required"
                            value={data.value.value}
                            variant="outlined"
                            className={classes.editInput}
                            disabled={idx < 5 || idx === 6}
                            onChange={(e) => updateData(e, recordsData[idx][0])}
                          />
                        )
                      : data.value.value}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
      {editRecordDetail && (
        <Box className={classes.footerBox}>
          <Button data-testid="cancel-btn" onClick={handleCancel} className={classes.cancelButton}>
            Cancel
          </Button>
          <Button
            sx={{ background: '#003057', '&:hover': { background: '#003057' } }}
            variant="contained"
            data-testid="save-changes-btn"
            className={classes.saveButton}
            disabled={disableBtn}
            onClick={sendUpdatedData}
          >
            Save Changes
          </Button>
        </Box>
      )}
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backdrop}>
        <CircularProgress />
      </Backdrop>
    </Box>
  );
};

export default ViewRecord;
