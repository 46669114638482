import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import '../../../assets/styles/AlertDialog.sass';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import '../../../assets/styles/LangTable.sass';

const AlertVersions = ({ divider, open, title, closeButtonClick, closeBtn }) => {
  const tableData = useSelector((state) => state.gameDb.versionHistory);
  const popUpTitle = () => (
    <Grid container direction="row" sx={{ fontWeight: '600' }}>
      <Grid fontSize="1.1rem" textAlign="left" item xs={10}>
        {title}
      </Grid>
      <Grid item xs={2}>
        {closeBtn === true && (
          <CloseIcon data-testid="closePopup" className="iconClose" onClick={closeButtonClick} />
        )}
      </Grid>
    </Grid>
  );

  const renderTable = () => (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead sx={{ backgroundColor: '#E8ECF1' }}>
          <TableRow>
            <TableCell align="left" className="headcell">
              Action Taken
            </TableCell>
            <TableCell align="left" className="headcell">
              User ID
            </TableCell>
            <TableCell align="left" className="headcell">
              Date Modified
            </TableCell>
            <TableCell align="left" className="headcell">
              Time
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((row) => (
            <TableRow
              hover
              tabIndex={-1}
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">{row.actionTaken}</TableCell>
              <TableCell align="left">{row.userId}</TableCell>
              <TableCell align="left">
                {row.createdAt.substring(0, 10).split('-').reverse().join('-')}
              </TableCell>
              <TableCell align="left">{row.createdAt.substring(11, 16)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <div>
      <Dialog open={open} classes={{ paper: 'wrapper' }}>
        <DialogTitle id="alert-dialog-title">{popUpTitle()}</DialogTitle>
        {divider === true && <Divider light />}
        <DialogContent>
          <DialogContentText className="contentStyle">{renderTable()}</DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

AlertVersions.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  closeButtonClick: PropTypes.func.isRequired,
  divider: PropTypes.bool,
  closeBtn: PropTypes.bool,
};

AlertVersions.defaultProps = {
  title: '',
  divider: false,
  closeBtn: false,
};

export default AlertVersions;
