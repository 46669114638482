import { fetch, update } from '../../utils/request-axios';
import mflConfig from '../../utils/config';
import { getViewRecordDetail } from '../../store/reducer/viewRecord';
import { setSnackState } from '../../store/reducer/snackbarReducer';
import { setBackdrop } from '../../store/reducer/uploadFile';
import store from '../../store/store';

export const fetchRecordWithId = (id) => async (dispatch) => {
  store.dispatch(setBackdrop(true));
  const response = await fetch(`${mflConfig.API_URL}language/${id}`);
  dispatch(getViewRecordDetail(response?.data));
  store.dispatch(setBackdrop(false));
};

export const UpdateRecordWithId = (data, id) => async (dispatch) => {
  const response = await update(`${mflConfig.API_URL}language/${id}`, data);
  dispatch(
    setSnackState({
      openSnackbar: true,
      message: response.status === 200 ? 'Edited Successfully' : 'Sorry someting went wrong',
      type: response.status === 200 ? 'success' : 'error',
    })
  );
};
