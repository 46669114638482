import React, { useEffect } from 'react';
import { FormControl, MenuItem, OutlinedInput, Select, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';
import '../../assets/styles/SelectCustom.sass';
import { useDispatch, useSelector } from 'react-redux';
import { setValueAll, setValueLevelAll } from '../../services/actions/gameDevelop';
import { setAttemptResponseFlag, setResponseFlag } from '../../store/reducer/gameDevelop';

export const DropdownSingle = ({ classProp, labelName, dropdownValues, levelNumber }) => {
  const [action, setAction] = React.useState('');
  const dispatch = useDispatch();
  const editFlag = useSelector((state) => state?.gameDevelop?.editFlag);
  const responseFlag = useSelector((state) => state?.gameDevelop?.responseFlag);
  const responseFlagAttempt = useSelector((state) => state?.gameDevelop?.responseFlagAttempt);
  const dropdowns = useSelector((state) => state?.gameDevelop?.dropdowns);
  const toCamelCase = (str) =>
    str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (ltr, idx) =>
        idx === 0 ? ltr.toLowerCase() : ltr.toUpperCase()
      )
      .replace(/\s+/g, '');
  const switchFunc = (label) => {
    switch (label) {
      case 'Order of Vocab':
        setAction(dropdowns?.vocabOrder);
        break;
      case 'Task Action':
        setAction(dropdowns?.taskAction);
        break;
      case 'Question Field':
        setAction(dropdowns?.questionLangulage);
        break;
      case 'Answer Field':
        setAction(dropdowns?.answerLangulage);
        break;
      case 'Mechanics':
        setAction(dropdowns?.gameMechanics);
        break;
      case 'Theme':
        setAction(dropdowns?.theme);
        break;
      case 'Sub Theme':
        setAction(dropdowns?.subTheme);
        break;
      case 'Number of Rounds':
        setAction(dropdowns?.numberOfRound);
        break;
      case 'Questions per Round':
        setAction(dropdowns?.questionPerRound);
        break;
      case 'Attempts per Question':
        setAction(dropdowns?.attemptPerQuestion);
        break;
      // case 'Case Sensitivity':
      //   setAction(dropdowns?.caseSensitivity);
      //   break;
      case 'Type of Keyboard':
        setAction(dropdowns?.typeOfKeyboard);
        break;
      case 'Number of Difficulty Levels':
        setAction(dropdowns?.dificultyLevel);
        break;
      case 'Number of Distractors':
        switch (levelNumber) {
          case 'level1':
            setAction(dropdowns?.numberOfDistractor);
            break;
          case 'level2':
            setAction(dropdowns?.numberOfDistractor2);
            break;
          case 'level3':
            setAction(dropdowns?.numberOfDistractor3);
            break;
          default:
        }
        break;
      case 'Source of Distractors':
        switch (levelNumber) {
          case 'level1':
            setAction(dropdowns?.sourceDistractor);
            break;
          case 'level2':
            setAction(dropdowns?.sourceDistractor2);
            break;
          case 'level3':
            setAction(dropdowns?.sourceDistractor3);
            break;
          default:
        }
        break;
      case 'Number of Gaps':
        switch (levelNumber) {
          case 'level1':
            setAction(dropdowns?.numberOfMissingWord);
            break;
          case 'level2':
            setAction(dropdowns?.numberOfMissingWord2);
            break;
          case 'level3':
            setAction(dropdowns?.numberOfMissingWord3);
            break;
          default:
        }
        break;
      case 'Image Prompt':
        switch (levelNumber) {
          case 'level1':
            setAction(dropdowns?.imagePrompt);
            break;
          case 'level2':
            setAction(dropdowns?.imagePrompt2);
            break;
          case 'level3':
            setAction(dropdowns?.imagePrompt3);
            break;
          default:
        }
        break;
      case 'Audio Prompt':
        switch (levelNumber) {
          case 'level1':
            setAction(dropdowns?.audioPrompt);
            break;
          case 'level2':
            setAction(dropdowns?.audioPrompt2);
            break;
          case 'level3':
            setAction(dropdowns?.audioPrompt3);
            break;
          default:
        }
        break;
      case 'Text Prompt':
        switch (levelNumber) {
          case 'level1':
            setAction(dropdowns?.textPrompt);
            break;
          case 'level2':
            setAction(dropdowns?.textPrompt2);
            break;
          case 'level3':
            setAction(dropdowns?.textPrompt3);
            break;
          default:
        }
        break;
      default:
    }
    return null;
  };
  const labelChanged = toCamelCase(labelName);
  useEffect(() => {
    if (editFlag) {
      switchFunc(labelName);
    }
  }, [dropdowns]);
  const gameMechanics = useSelector((state) => state?.gameDevelop?.dropdowns?.gameMechanics);
  const taskAction = useSelector((state) => state?.gameDevelop?.dropdowns?.taskAction);
  const numberOfDistractor = useSelector(
    (state) => state?.gameDevelop?.dropdowns?.numberOfDistractor
  );
  const numberOfDistractor2 = useSelector(
    (state) => state?.gameDevelop?.dropdowns?.numberOfDistractor2
  );
  const numberOfDistractor3 = useSelector(
    (state) => state?.gameDevelop?.dropdowns?.numberOfDistractor3
  );
  const checkCondition = () => {
    if (labelChanged === 'caseSensitivity') return true;
    if (labelChanged === 'typeOfKeyboard') {
      if (gameMechanics === 'Type in') return false;
      return true;
    }
    if (labelChanged === 'numberOfGaps') {
      if (taskAction === 'Missing Words' || taskAction === 'Missing Letters') return false;
      return true;
    }
    if (labelChanged === 'numberOfDistractors' || labelChanged === 'sourceOfDistractors') {
      if (gameMechanics === 'Type in') return true;
    }
    if (labelChanged === 'sourceOfDistractors' && levelNumber === 'level1') {
      if (numberOfDistractor === '0' || numberOfDistractor === 0) return true;
      return false;
    }
    if (labelChanged === 'sourceOfDistractors' && levelNumber === 'level2') {
      if (numberOfDistractor2 === '0' || numberOfDistractor2 === 0) return true;
      return false;
    }
    if (labelChanged === 'sourceOfDistractors' && levelNumber === 'level3') {
      if (numberOfDistractor3 === '0' || numberOfDistractor3 === 0) return true;
      return false;
    }
    return false;
  };
  useEffect(() => {
    if (checkCondition() === true) {
      setAction('');
      if (levelNumber) {
        setValueLevelAll([labelChanged, '', levelNumber]);
      } else setValueAll([labelChanged, '']);
    }
  }, [checkCondition()]);
  useEffect(() => {
    if (labelChanged === 'attemptsPerQuestion')
      if (
        (gameMechanics === 'Selecting' ||
          gameMechanics === 'Navigating user-led' ||
          gameMechanics === 'Navigating item-led' ||
          gameMechanics === 'Flashcards') &&
        responseFlagAttempt
      ) {
        setAction('1');
        setValueAll([labelChanged, '1']);
      } else if (gameMechanics === 'Type in' && responseFlagAttempt) {
        setAction('3');
        setValueAll([labelChanged, '3']);
      }
  }, [gameMechanics]);

  useEffect(() => {
    if (labelChanged === 'mechanics' && taskAction === 'Flashcards' && responseFlag) {
      setAction('Flashcards');
      setValueAll([labelChanged, 'Flashcards']);
    }
    if (
      labelChanged === 'mechanics' &&
      (taskAction === 'Matching' ||
        taskAction === 'Missing Words' ||
        taskAction === 'Missing Letters' ||
        taskAction === 'Arrange words/letters') &&
      responseFlag
    ) {
      setAction('');
      setValueAll([labelChanged, '']);
    }
  }, [taskAction]);

  useEffect(() => {
    if (labelChanged === 'taskAction' && gameMechanics === 'Flashcards') {
      setAction('Flashcards');
      setValueAll([labelChanged, 'Flashcards']);
    }
  }, [gameMechanics]);

  const handleChange = (event) => {
    if (labelChanged === 'taskAction') {
      dispatch(setResponseFlag(true));
    }
    if (labelChanged === 'attemptsPerQuestion' || labelChanged === 'mechanics')
      dispatch(setAttemptResponseFlag(true));
    setAction(event.target.value);
    if (levelNumber) setValueLevelAll([labelChanged, event.target.value, levelNumber]);
    else setValueAll([labelChanged, event.target.value]);
    if (labelChanged === 'taskAction') {
      if (event.target.value === 'Flashcards') {
        dispatch(setAttemptResponseFlag(true));
      }
    }
  };
  return (
    <FormControl style={{ minWidth: '150px' }}>
      <Select
        data-testid="select-custom"
        input={<OutlinedInput size="small" />}
        margin="dense"
        inputProps={{ 'data-testid': 'item' }}
        required
        className={classProp}
        value={action}
        onChange={handleChange}
        placeholder="Select"
        disabled={checkCondition()}
        displayEmpty
        renderValue={
          action === null || action === ''
            ? () => <Typography className="placeholderSelect">Select</Typography>
            : undefined
        }
      >
        {dropdownValues.map((value) => (
          <MenuItem key={value} value={value}>
            <Typography className="typoMenuItemOptions">{value}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

DropdownSingle.propTypes = {
  classProp: PropTypes.string,
  labelName: PropTypes.string.isRequired,
  dropdownValues: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  levelNumber: PropTypes.string,
};

DropdownSingle.defaultProps = {
  classProp: '',
  levelNumber: '',
};
