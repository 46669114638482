import React from 'react';
import { useSelector } from 'react-redux';
import { Typography, TextField } from '@mui/material';
import { PropTypes } from 'prop-types';
import { QUESTION_PER_ROUND } from '../../assets/constantData/gameConstant';

export const CustomTextField = ({ labelName, typeOfField = false, onChange }) => {
  const { dropdowns } = useSelector((state) => state?.gameDevelop);
  const taskActionVal = useSelector((state) => state?.gameDevelop?.dropdowns?.taskAction);
  return (
    <>
      <Typography variant="body1">
        {taskActionVal === 'Flashcards' && labelName === QUESTION_PER_ROUND
          ? 'Number of cards per page'
          : labelName}
      </Typography>

      <TextField
        inputProps={{ min: 1, max: typeOfField ? 20 : 30, 'data-testid': { labelName } }}
        size="small"
        type="number"
        required
        value={typeOfField ? dropdowns?.questionPerRound : dropdowns?.numberOfRound}
        name={labelName}
        onChange={onChange}
        helperText={
          typeOfField ? `Maximum value you can type is 20` : `Maximum value you can type is 30`
        }
      />
    </>
  );
};
CustomTextField.propTypes = {
  labelName: PropTypes.string.isRequired,
  typeOfField: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
};
CustomTextField.defaultProps = {
  onChange: () => {},
};
