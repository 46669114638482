import {
  setData,
  setIsDeleted,
  setVersionHistory,
  updateList,
  updateTotalRecords,
} from '../../store/reducer/gameDb';
import { setBackdrop } from '../../store/reducer/uploadFile';
import store from '../../store/store';
import mflConfig from '../../utils/config';
import { remove, post, fetch, update } from '../../utils/request-axios';

export const fetchVersionHistory = (gameId) => async (dispatch) => {
  dispatch(setBackdrop(true));
  const response = await fetch(`${mflConfig.API_URL}history/?actionModule=game&recordId=${gameId}`);
  dispatch(setVersionHistory(response.data.data));
  dispatch(setBackdrop(false));
};

export const postGameFilters =
  (data, pageNo, clearAll = false) =>
  async (dispatch) => {
    const limit = 50;
    if (clearAll) {
      data = { ...store.getState().gameDb.dataToSend, ...store.getState().gameDb.newLiveFilters };
      pageNo = 0;
    }
    const skip = limit * pageNo;
    store.dispatch(setBackdrop(true));
    const response = await post(`${mflConfig.API_URL}game/?$limit=${limit}&$skip=${skip}`, data);
    store.dispatch(setBackdrop(false));
    dispatch(setData(response.data.data));
    dispatch(updateList(response?.data.data));
    dispatch(updateTotalRecords(response.data.total));
  };

export const deleteGame = (gameIdsArr) => async (dispatch) => {
  store.dispatch(setBackdrop(true));
  await remove(`${mflConfig.API_URL}game/`, {
    gmaeId: gameIdsArr,
  });
  dispatch(setIsDeleted(true));
  dispatch(setBackdrop(false));
};

export const changeStatusToDownloadAction = async (gameId) => {
  store.dispatch(setBackdrop(true));
  const res = await update(`${mflConfig.API_URL}game/${gameId}`, {
    gameStatus: 'Downloaded',
  });
  if (res.status !== 200) throw res.message;
  store.dispatch(setBackdrop(false));
};
