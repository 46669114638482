import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  openSnackbar: false,
  message: '',
  type: '',
};

export const snackOpener = createSlice({
  name: 'snackLaunched',
  initialState,
  reducers: {
    setSnackState: (state, action) => {
      state.openSnackbar = action.payload.openSnackbar;
      state.message = action.payload.message;
      state.type = action.payload.type;
    },
  },
});
export const { setSnackState } = snackOpener.actions;

export const snackPopup = (state) => state.snackLaunched;

export default snackOpener.reducer;
