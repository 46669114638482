import { Backdrop, CircularProgress, Grid } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ERROR_LOGS } from '../../assets/constantData/dashboard';
import { LOAD_MORE, NO_RESULT_FOUND } from '../../assets/constantData/gameConstant';
import { fetchErrorLogs } from '../../services/actions/errorLogs';
import { setPageNo } from '../../store/reducer/errorLogs';
import { ButtonCustom } from '../custom/ButtonCustom';
import { SubHeader } from '../custom/SubHeader';
import { Navbar } from '../Navbar';
import ErrorTable from './ErrorTable';

export const ErrorLogs = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchErrorLogs(0));
  }, []);
  const backdrop = useSelector((states) => states.uploadFile.backdrop);
  const errorData = useSelector((states) => states.errorLogs.currentErrorData);
  const errorDataPageNo = useSelector((states) => states.errorLogs.pageNo);
  const handleLoadMore = () => {
    dispatch(setPageNo());
    dispatch(fetchErrorLogs(errorDataPageNo + 1));
  };
  const compare = () => {
    if (errorData.length === 0) {
      return <h1>{NO_RESULT_FOUND}</h1>;
    }
    if (errorData.length === 50)
      return (
        <ButtonCustom
          valueProp={LOAD_MORE}
          variantProp="outlined"
          classProp="loadMore"
          typoClassProp="typoLoadMore"
          onClick={() => handleLoadMore()}
          customAttribute={{ style: { textTransform: 'none' }, 'data-testid': 'errorloadmore' }}
        />
      );
    return null;
  };

  return (
    <>
      <Navbar />
      <Grid paddingX={4}>
        <SubHeader backBtn={false} title={ERROR_LOGS} sidemenu />
        <ErrorTable />
      </Grid>
      <Grid display="flex" justifyContent="center" paddingY={2}>
        {compare()}
      </Grid>
      <Backdrop sx={{ color: '#fff', zIndex: 10000 }} open={backdrop}>
        <CircularProgress />
      </Backdrop>
    </>
  );
};
