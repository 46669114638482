import { Container, Grid } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import CardItem from './CardItem';
import '../assets/styles/Cards.sass';

const Cards = ({ names }) => {
  const navigate = useNavigate();
  const handleClick = (title) => {
    if (title === 'Download Template') {
      const filename = 'download_template.csv';
      const anchorLink = document.createElement('a');
      anchorLink.href = filename;
      anchorLink.download = 'Download Template';
      document.body.appendChild(anchorLink);
      anchorLink.click();
      anchorLink.parentNode.removeChild(anchorLink);
    } else if (title === 'Game Management') {
      navigate('games/list');
    } else if (title === 'Language Database') {
      navigate('languagedatabase');
    } else if (title === 'History Logs') {
      navigate('historylogs');
    } else if (title === 'Error Logs') {
      navigate('/errorLogs');
    }
  };
  return (
    <Container maxWidth="md" className="cards-container">
      <Grid container className="grid-container">
        {names.map((obj) => (
          <Grid item md={4} key={obj.title}>
            {obj.title === 'Upload File' ? (
              <label htmlFor="upload-file">
                {/* eslint-disable-next-line */}
                <CardItem
                  data-testid="cardItem"
                  className="card-item"
                  title={obj.title}
                  Icon={obj.IconName}
                  onClick={() => handleClick(obj.title)}
                />
              </label>
            ) : (
              <CardItem
                className="card-item"
                title={obj.title}
                Icon={obj.IconName}
                onClick={() => handleClick(obj.title)}
              />
            )}
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

Cards.propTypes = {
  names: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
};

export default Cards;
