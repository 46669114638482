import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allData: [],
  pageNo: 0,
  isFilterApplied: false,
  filteredDataCount: 0,
  totalRecords: 0,
  recordsAfterDelete: [],
  selected: [],
  openAlert: false,
};

const languageDatabase = createSlice({
  name: 'languageDatabase',
  initialState,
  reducers: {
    fetchAll: (state, { payload: { data, total } }) => {
      state.allData = [...state.allData, ...data];
      state.pageNo += 1;
      state.totalRecords = total;
    },
    fetchFilteredData: (state, { payload: { data, total } }) => {
      state.isFilterApplied = true;
      state.filteredDataCount += 1;
      state.totalRecords = total;
      if (state.filteredDataCount === 1) state.allData = [];
      if (state.pageNo === 0) state.allData = [...data];
      state.pageNo += 1;
    },
    loadMoreFiltersReducer: (state, { payload: { data, total } }) => {
      state.isFilterApplied = true;
      state.filteredDataCount += 1;
      state.totalRecords = total;
      state.allData = [...state.allData, ...data];
      state.pageNo += 1;
    },
    fetchSearchData: (state, { payload: { data, total } }) => {
      state.isFilterApplied = true;
      state.filteredDataCount += 1;
      state.totalRecords = total;
      if (state.filteredDataCount === 1) state.allData = [];
      state.allData = [...data];
      state.pageNo += 1;
    },
    setPageNoReducer: (state) => {
      state.pageNo = 0;
    },
    setSelected: (state, action) => {
      state.selected = action?.payload;
    },
    clearAll: (state, { payload: { data, total } }) => {
      state.isFilterApplied = false;
      state.allData = [];
      state.allData = [...data];
      state.pageNo = 1;
      state.totalRecords = total;
    },
    resetDb: () => initialState,
    deleteRecords: (state, action) => {
      state.allData = state.allData.filter((obj) => obj.id !== action.payload);
      state.totalRecords -= 1;
    },
    setOpenAlert: (state, action) => {
      state.openAlert = action.payload;
    },
    fetchAllAfterEditReducer: (state, { payload: { data, total } }) => {
      state.allData = [...data];
      state.totalRecords = total;
    },
    setRetainedFilters: (state, action) => {
      state.isFilterApplied = action.payload;
    },
  },
});

export const {
  fetchAll,
  fetchFilteredData,
  loadMoreFiltersReducer,
  setPageNoReducer,
  fetchSearchData,
  deleteRecords,
  setSelected,
  clearAll,
  resetDb,
  setOpenAlert,
  fetchAllAfterEditReducer,
  setRetainedFilters,
} = languageDatabase.actions;

export default languageDatabase.reducer;
