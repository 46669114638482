import React from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { SideMenu } from '../languageDatabase/SideMenu';
import { BackButton } from './BackButton';
import { SearchBox } from '../languageDatabase/SearchBox';
import '../../assets/styles/SubHeader.sass';

export const SubHeader = ({ title, sidemenu, backBtn, searchBox }) => (
  <Box className="inner-container">
    <Box className="sidebar-backBtn">
      {sidemenu === true && <SideMenu />}
      {backBtn === true && <BackButton />}
    </Box>
    <Typography
      className={searchBox === true ? `heading-style` : `heading-style padding-right`}
      fontSize="25px"
      variant="h3"
      fontWeight="700"
    >
      {title}
    </Typography>
    {searchBox === true && <SearchBox title={title} />}
  </Box>
);

SubHeader.propTypes = {
  title: PropTypes.string.isRequired,
  sidemenu: PropTypes.bool,
  backBtn: PropTypes.bool,
  searchBox: PropTypes.bool,
};

SubHeader.defaultProps = {
  sidemenu: false,
  backBtn: false,
  searchBox: false,
};
