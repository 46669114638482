import { PropTypes } from 'prop-types';
import { Typography } from '@mui/material';
import { DropdownSingle } from './DropdownSingle';

export const DropdownWithLabel = ({ labelName, dropdownValues, levelNumber }) => (
  <>
    <Typography variant="body1">{labelName}</Typography>
    <DropdownSingle
      labelName={labelName}
      dropdownValues={dropdownValues}
      levelNumber={levelNumber}
    />
  </>
);

DropdownWithLabel.propTypes = {
  labelName: PropTypes.string.isRequired,
  dropdownValues: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  levelNumber: PropTypes.string,
};

DropdownWithLabel.defaultProps = {
  levelNumber: '',
};
