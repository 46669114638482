import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Backdrop, Box, CircularProgress } from '@mui/material';
import { Navbar } from '../Navbar';
import { LangHeader } from './LangHeader';
import { LangTable } from './LangTable';
import { ButtonCustom } from '../custom/ButtonCustom';
import { headCellsData } from '../../assets/constantData/constLangTable';
import '../../assets/styles/MainScreen.sass';
import { fetchAllRecords } from '../../services/actions/languageDatabase';
import { loadMoreFilters, passingSelectedFilters } from '../../services/actions/filterApply';
import { LOAD_MORE, NO_RESULT_FOUND } from '../../assets/constantData/gameConstant';
import { resetFilters } from '../../store/reducer/filterApply';

export const MainScreen = () => {
  const dispatch = useDispatch();
  const languageDbData = useSelector((states) => states.languageDatabase);
  const total = useSelector((state) => state.languageDatabase.totalRecords);
  const appliedFilters = useSelector((state) => state.filterApply.appliedFilters);
  const pageNo = useSelector((state) => state.languageDatabase.pageNo);
  const isFilterApplied = useSelector((state) => state.languageDatabase.isFilterApplied);
  const filteredDataCount = useSelector((state) => state.languageDatabase.filteredDataCount);
  const backdrop = useSelector((states) => states.uploadFile.backdrop);
  useEffect(() => {
    if (languageDbData?.pageNo === 0 && !languageDbData.isFilterApplied)
      dispatch(fetchAllRecords(languageDbData?.pageNo));
    else if (languageDbData.isFilterApplied && languageDbData?.pageNo === 0) {
      dispatch(passingSelectedFilters(appliedFilters, pageNo));
    }
  }, []);

  useEffect(() => {
    if (!isFilterApplied) dispatch(resetFilters());
  }, []);
  const handleButtonClick = () => {
    if (isFilterApplied) dispatch(loadMoreFilters(appliedFilters, languageDbData?.pageNo));
    else dispatch(fetchAllRecords(languageDbData?.pageNo));
  };
  const compare = function compare() {
    if (languageDbData.allData.length === 0 && filteredDataCount) {
      return <h1>{NO_RESULT_FOUND}</h1>;
    }
    if (languageDbData.allData.length < total)
      return (
        <ButtonCustom
          customAttribute={{
            'data-testid': 'loadMore',
          }}
          valueProp={LOAD_MORE}
          variantProp="outlined"
          classProp="loadMore"
          typoClassProp="typoLoadMore"
          onClick={handleButtonClick}
        />
      );
    return null;
  };
  return (
    <Box>
      <Navbar />
      <Box className="MainScreen">
        <Box className="langHeaderWrapper">
          <LangHeader headerTitle="Language Database" />
        </Box>
        <LangTable headCellsData={headCellsData} rowsData={languageDbData.allData} />
        <Box className="moreLoad" display="flex" justifyContent="center">
          {compare()}
        </Box>
      </Box>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backdrop}>
        <CircularProgress />
      </Backdrop>
    </Box>
  );
};
