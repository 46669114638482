import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  editRecordDetail: false,
  viewRecordData: {},
  updatedData: {},
};

export const editRecordStatus = createSlice({
  name: 'editRecord',
  initialState,
  reducers: {
    setEditRecordDetail: (state, action) => {
      state.editRecordDetail = action?.payload;
    },

    getViewRecordDetail: (state, action) => {
      state.viewRecordData = action?.payload;
    },

    setDataToBeUpdated: (state, action) => {
      state.updatedData = action?.payload;
    },
  },
});
export const { setEditRecordDetail, getViewRecordDetail, setDataToBeUpdated } =
  editRecordStatus.actions;
export const editRecordSelector = (state) => state.editRecord;
export default editRecordStatus.reducer;
