import { Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { APPLY_FILTERS, CLEAR_ALL } from '../../assets/constantData/gameConstant';
import { postFilteredData } from '../../services/actions/historyLogs';
import {
  resetHistoryDb,
  resetPageNo,
  setDataToSend,
  setIsFilterApplied,
} from '../../store/reducer/historyLogs';
import { ButtonCustom } from '../custom/ButtonCustom';
import DatePicker from './DatePicker';

export const DatePickerWIthLabel = () => {
  const [fromVocabId, setFromVocabId] = useState('');
  const [toVocabId, setToVocabId] = useState('');
  const defaultEndDate = new Date();
  defaultEndDate.setDate(defaultEndDate.getDate() + 7);
  const dispatch = useDispatch();
  const dataToSend = useSelector((state) => state.historyLogs.dataToSend);
  const isFilterApplied = useSelector((state) => state.historyLogs.isFilterApplied);
  const subject = useSelector((state) => state.historyLogs.dataToSend.subject);
  const course = useSelector((state) => state.historyLogs.dataToSend.course);
  const keyStage = useSelector((state) => state.historyLogs.dataToSend.keyStage);
  const module = useSelector((state) => state.historyLogs.dataToSend.module);
  const unit = useSelector((state) => state.historyLogs.dataToSend.unit);
  const fromDate = useSelector((state) => state.historyLogs.dataToSend.fromDate);
  const toDate = useSelector((state) => state.historyLogs.dataToSend.toDate);
  const fromVocabIdFilter = useSelector((state) => state.historyLogs.dataToSend.fromVocabId);
  const toVocabIdFilter = useSelector((state) => state.historyLogs.dataToSend.toVocabId);
  const searchStr = useSelector((state) => state.historyLogs.dataToSend.searchStr);
  const lengthOfFilters =
    subject.length ||
    course.length ||
    keyStage.length ||
    module.length ||
    unit.length ||
    fromDate.length ||
    toDate.length ||
    fromVocabIdFilter.length ||
    toVocabIdFilter.length;
  const applyFilters = () => {
    if (lengthOfFilters && (searchStr.length >= 3 || searchStr.length === 0)) {
      dispatch(resetPageNo());
      dispatch(setIsFilterApplied(true));
      dispatch(postFilteredData(dataToSend, 0, false));
    }
  };

  useEffect(() => {
    if (isFilterApplied === false) {
      setFromVocabId('');
      setToVocabId('');
    }
  }, [isFilterApplied]);
  const fromDateSelected = useSelector((state) => state?.historyLogs?.fromDateSelected);
  const toDateSelected = useSelector((state) => state?.historyLogs?.toDateSelected);
  return (
    <Grid
      container
      display="flex"
      flexWrap="wrap"
      marginTop={1}
      columnSpacing={2}
      rowSpacing={1}
      rowGap={1}
    >
      <>
        {/* eslint-disable-next-line */}
        <Grid display="flex" item xs={8} sm={4} md={2} flexWrap="wrap">
          <Typography variant="body2" fontWeight={600}>
            From Date
          </Typography>
          <DatePicker
            title="fromDate"
            maxDate={
              Object.keys(toDateSelected).length === 0 && toDateSelected.constructor === Object
                ? new Date()
                : toDateSelected
            }
          />
        </Grid>
        <Grid display="flex" item xs={8} sm={4} md={2} flexWrap="wrap">
          <Typography variant="body2" fontWeight={600}>
            To Date
          </Typography>
          <DatePicker title="toDate" minDate={fromDateSelected} maxDate={new Date()} />
        </Grid>
      </>
      <Grid item xs={8} sm={4} md={2}>
        <Typography variant="body2" fontWeight={600}>
          From Vocab ID
        </Typography>
        <TextField
          value={fromVocabId}
          onChange={(e) => {
            setFromVocabId(e.target.value);
            dispatch(setDataToSend({ ...dataToSend, fromVocabId: e.target.value }));
          }}
          size="small"
          fullWidth
          inputProps={{ maxLength: 10, 'data-testid': 'fromvocabid' }}
        />
      </Grid>
      <Grid item xs={8} sm={4} md={2}>
        <Typography variant="body2" fontWeight={600}>
          To Vocab ID
        </Typography>
        <TextField
          value={toVocabId}
          onChange={(e) => {
            setToVocabId(e.target.value);
            dispatch(setDataToSend({ ...dataToSend, toVocabId: e.target.value }));
          }}
          size="small"
          fullWidth
          inputProps={{ maxLength: 10, 'data-testid': 'tovocabid' }}
        />
      </Grid>
      <Grid
        item
        display="flex"
        alignItems="flex-end"
        justifyContent="right"
        width="33%"
        xs={12}
        sm={8}
        md={4}
      >
        <ButtonCustom
          variantProp="contained"
          valueProp={APPLY_FILTERS}
          onClick={applyFilters}
          customAttribute={{
            sx: {
              textTransform: 'capitalize',
              marginRight: '10px',
            },
            'data-testid': 'applyfilters',
          }}
        />
        <ButtonCustom
          variantProp="text"
          valueProp={CLEAR_ALL}
          customAttribute={{
            sx: {
              textTransform: 'capitalize',
              textDecoration: 'underline',
              '&:hover': {
                textDecoration: 'underline',
              },
            },
            'data-testid': 'clearAllHistory',
          }}
          onClick={() => {
            if (isFilterApplied) {
              dispatch(resetHistoryDb());
              dispatch(postFilteredData(dataToSend, 0, true));
            }
          }}
        />
      </Grid>
    </Grid>
  );
};
