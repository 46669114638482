import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconButton, TableSortLabel } from '@mui/material';
import '../../../assets/styles/LangTable.sass';
import { visuallyHidden } from '@mui/utils';
import { tableStyle } from '../../../assets/styles/material-ui/gameDashboard';

function descendingComparator(a, b, orderBy) {
  if (orderBy === '') {
    return 0;
  }
  if (orderBy === 'modifiedAt' || orderBy === 'date') {
    const dayA = Number(a[orderBy].substring(0, 2));
    const monthA = Number(a[orderBy].substring(3, 5));
    const yearA = Number(a[orderBy].substring(6, 10));
    const dayB = Number(b[orderBy].substring(0, 2));
    const monthB = Number(b[orderBy].substring(3, 5));
    const yearB = Number(b[orderBy].substring(6, 10));
    if (yearB < yearA) {
      return -1;
    }
    if (yearB > yearA) {
      return 1;
    }
    if (yearB === yearA) {
      if (monthB < monthA) return -1;
      if (monthB > monthA) return 1;
      if (monthB === monthA) {
        if (dayB < dayA) return -1;
        if (dayB > dayA) return 1;
      }
    }
    return 0;
  }
  if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
    return -1;
  }
  if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const handleFilterData = (key) => {
  switch (key) {
    case 'Status':
      return 'status';
    case 'Game Name':
      return 'gameName';
    case 'User Id':
      return 'userId';
    case 'User ID':
      return 'userId';
    case 'Modified At':
      return 'modifiedAt';
    case 'Action Taken':
      return 'actionTaken';
    case 'Fields Name':
      return 'fieldsName';
    case 'Vocab ID':
      return 'vocabId';
    case 'Item 1':
      return 'item1';
    case 'Date':
      return 'date';
    case 'Time':
      return 'time';
    default:
      return null;
  }
};
function EnhancedTableHead(props) {
  const { order, orderBy, headCells, isIcon, onRequestSort } = props;
  const classes = tableStyle();
  const createSortHandler = (property) => (event) => {
    const data = handleFilterData(property);
    onRequestSort(event, data);
  };
  return (
    <TableHead className={classes.headingStyle}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell}
            sortDirection={orderBy === handleFilterData(headCell) ? order : false}
            sx={{ overflowWrap: 'anywhere', width: '100px' }}
            align="left"
            className="headcell"
          >
            <TableSortLabel
              active={orderBy === headCell}
              direction={orderBy === handleFilterData(headCell) ? order : 'asc'}
              onClick={createSortHandler(headCell)}
            >
              {headCell}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {isIcon && (
          <TableCell
            sx={{ overflowWrap: 'anywhere', width: '100px' }}
            align="left"
            className="headcell"
          >
            Actions
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  headCells: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  isIcon: PropTypes.element.isRequired,
};

export const TableWrapper = ({ isHistoryLogs, rows, columns, isIcon, handleIconClick }) => {
  const [order, setOrder] = React.useState('');
  const [orderBy, setOrderBy] = React.useState('');
  const rowValues = Object.values(rows);
  let objToAddHistory = {};
  let objToAddGameDb = {};
  const finalArrHistory = [];
  const finalArrGameDb = [];
  if (isHistoryLogs) {
    rowValues.forEach((obj) => {
      objToAddHistory.actionTaken = obj?.actionTaken;
      objToAddHistory.fieldsName = obj?.fieldName;
      objToAddHistory.vocabId = obj?.vocabId;
      objToAddHistory.item1 = obj?.item1;
      objToAddHistory.id = obj?.id;
      objToAddHistory.userId = obj?.userId;
      objToAddHistory.date = obj.createdAt?.substring(0, 10).split('-').reverse().join('-');
      objToAddHistory.time = obj.createdAt?.substring(11, 16);
      finalArrHistory.push(objToAddHistory);
      objToAddHistory = {};
    });
  } else {
    rowValues.forEach((obj) => {
      objToAddGameDb.gameName = obj.name;
      objToAddGameDb.userId = obj.userId;
      objToAddGameDb.status = obj.gameStatus;
      objToAddGameDb.id = obj.id;
      objToAddGameDb.modifiedAt = obj.createdAt.substring(0, 10).split('-').reverse().join('-');
      objToAddGameDb.name = obj.name;
      objToAddGameDb.exportURL = obj.exportURL;
      objToAddGameDb.previewURL = obj.previewURL;
      finalArrGameDb.push(objToAddGameDb);
      objToAddGameDb = {};
    });
  }

  let finalArr = [];
  if (isHistoryLogs) finalArr = finalArrHistory;
  else finalArr = finalArrGameDb;
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} size="small">
            <EnhancedTableHead
              isIcon={isIcon}
              headCells={columns}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(finalArr, getComparator(order, orderBy)).map((row) => (
                <TableRow hover tabIndex={-1} key={row.id}>
                  {isHistoryLogs ? (
                    <>
                      <TableCell
                        sx={{
                          overflowWrap: 'anywhere',
                          width: '150px',
                          paddingTop: '11px',
                          paddingBottom: '11px',
                        }}
                        align="left"
                      >
                        {row.actionTaken}
                      </TableCell>
                      <TableCell
                        sx={{
                          overflowWrap: 'anywhere',
                          width: '150px',
                          paddingTop: '11px',
                          paddingBottom: '11px',
                        }}
                        align="left"
                      >
                        {row.fieldsName}
                      </TableCell>
                      <TableCell
                        sx={{
                          overflowWrap: 'anywhere',
                          width: '100px',
                          paddingTop: '11px',
                          paddingBottom: '11px',
                        }}
                        align="left"
                      >
                        {row.vocabId}
                      </TableCell>
                      <TableCell
                        sx={{
                          overflowWrap: 'anywhere',
                          width: '250px',
                          paddingTop: '11px',
                          paddingBottom: '11px',
                        }}
                        align="left"
                      >
                        {row.item1}
                      </TableCell>
                      <TableCell
                        sx={{
                          overflowWrap: 'anywhere',
                          width: '100px',
                          paddingTop: '11px',
                          paddingBottom: '11px',
                        }}
                        align="left"
                      >
                        {row.userId}
                      </TableCell>
                      <TableCell
                        sx={{
                          overflowWrap: 'anywhere',
                          width: '100px',
                          paddingTop: '11px',
                          paddingBottom: '11px',
                        }}
                        align="left"
                      >
                        {row.date}
                      </TableCell>
                      <TableCell
                        sx={{
                          overflowWrap: 'anywhere',
                          width: '100px',
                          paddingTop: '11px',
                          paddingBottom: '11px',
                        }}
                        align="left"
                      >
                        {row.time}
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell
                        sx={{ overflowWrap: 'anywhere', width: '250px', minHeight: '30px' }}
                        align="left"
                      >
                        {row.gameName}
                      </TableCell>
                      <TableCell
                        sx={{ overflowWrap: 'anywhere', width: '100px', minHeight: '30px' }}
                        align="left"
                      >
                        {row.userId}
                      </TableCell>
                      <TableCell
                        sx={{ overflowWrap: 'anywhere', width: '100px', minHeight: '30px' }}
                        align="left"
                      >
                        {row.status}
                      </TableCell>
                      <TableCell
                        sx={{ overflowWrap: 'anywhere', width: '100px', minHeight: '30px' }}
                        align="left"
                      >
                        {row.modifiedAt}
                      </TableCell>
                    </>
                  )}
                  {isIcon && (
                    <TableCell
                      sx={{ overflowWrap: 'anywhere', width: '100px', minHeight: '30px' }}
                      align="left"
                    >
                      <IconButton
                        data-testid="fetchGameOptions"
                        sx={{ padding: '3px' }}
                        onClick={(e) => {
                          handleIconClick(e, row.id, row.name, row.previewURL, row.exportURL);
                        }}
                      >
                        {isIcon}
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

TableWrapper.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  isHistoryLogs: PropTypes.bool.isRequired,
  columns: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  isIcon: PropTypes.element,
  handleIconClick: PropTypes.func,
};

TableWrapper.defaultProps = {
  isIcon: false,
  handleIconClick: () => {},
};
