import * as React from 'react';
import { OutlinedInput, MenuItem, FormControl, Select, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@mui/styles';
import { MenuProps } from '../../assets/constantData/utils';
import { fetchAllNewGameFilterValues } from '../../services/actions/gameDevelopment';
import {
  setIsFilterSelected,
  setGameNewFilterValues,
  setIsNewKeyStageDisabled,
  setIsNewCourseDisabled,
  setIsNewModuleDisabled,
  setIsNewUnitDisabled,
  resetNewGameSubjectCase,
  resetNewGameKeyStageCase,
  resetNewGameCourseCase,
  resetNewGameModuleCase,
} from '../../store/reducer/gameDb';

const CustomTooltip = withStyles({
  tooltip: {
    fontSize: '14px',
  },
})(Tooltip);

export const NewFilterSingle = ({ options, title }) => {
  const [data, setData] = React.useState([]);
  const dispatch = useDispatch();
  const isFilterApplied = useSelector((state) => state.gameDb.isFilterApplied);
  const newLiveFilters = useSelector((state) => state.gameDb.newLiveFilters);
  const isNewKeyStageDisabled = useSelector((state) => state.gameDb.isNewKeyStageDisabled);
  const isNewCourseDisabled = useSelector((state) => state.gameDb.isNewCourseDisabled);
  const isNewModuleDisabled = useSelector((state) => state.gameDb.isNewModuleDisabled);
  const isNewUnitDisabled = useSelector((state) => state.gameDb.isNewUnitDisabled);

  React.useEffect(() => {
    if (!isFilterApplied) setData([]);
  }, [isFilterApplied]);

  React.useEffect(() => {
    if (newLiveFilters.keyStage.length === 0 && title === 'keyStage') setData([]);
    if (newLiveFilters.course.length === 0 && title === 'course') setData([]);
    if (newLiveFilters.module.length === 0 && title === 'module') setData([]);
    if (newLiveFilters.unit.length === 0 && title === 'unit') setData([]);
  }, [newLiveFilters.keyStage, newLiveFilters.course, newLiveFilters.module, newLiveFilters.unit]);

  React.useEffect(() => {
    if (isNewKeyStageDisabled && title === 'keyStage')
      dispatch(setGameNewFilterValues(['', title]));
    if (isNewCourseDisabled && title === 'course') dispatch(setGameNewFilterValues(['', title]));
    if (isNewModuleDisabled && title === 'module') dispatch(setGameNewFilterValues(['', title]));
    if (isNewUnitDisabled && title === 'unit') dispatch(setGameNewFilterValues(['', title]));
  }, [isNewKeyStageDisabled, isNewCourseDisabled, isNewModuleDisabled, isNewUnitDisabled]);

  const handleChange = (event) => {
    dispatch(setIsFilterSelected(true));
    const {
      target: { value },
    } = event;

    switch (title) {
      case 'subject': {
        dispatch(
          fetchAllNewGameFilterValues({
            subject: [value],
            keyStage: [],
            course: [],
            module: [],
            unit: [],
          })
        );
        dispatch(setIsNewKeyStageDisabled(false));
        dispatch(setIsNewCourseDisabled(true));
        dispatch(setIsNewModuleDisabled(true));
        dispatch(setIsNewUnitDisabled(true));
        dispatch(resetNewGameSubjectCase());
        break;
      }
      case 'keyStage': {
        dispatch(
          fetchAllNewGameFilterValues({
            subject: [newLiveFilters.subject],
            keyStage: [value],
            course: [],
            module: [],
            unit: [],
          })
        );
        dispatch(setIsNewCourseDisabled(false));
        dispatch(setIsNewModuleDisabled(true));
        dispatch(setIsNewUnitDisabled(true));
        dispatch(resetNewGameKeyStageCase());
        break;
      }
      case 'course': {
        dispatch(
          fetchAllNewGameFilterValues({
            subject: [newLiveFilters.subject],
            keyStage: [newLiveFilters.keyStage],
            course: [value],
            module: [],
            unit: [],
          })
        );
        dispatch(setIsNewModuleDisabled(false));
        dispatch(setIsNewUnitDisabled(true));
        dispatch(resetNewGameCourseCase());
        break;
      }
      case 'module': {
        dispatch(
          fetchAllNewGameFilterValues({
            subject: [newLiveFilters.subject],
            keyStage: [newLiveFilters.keyStage],
            course: [newLiveFilters.course],
            module: [value],
            unit: [],
          })
        );
        dispatch(setIsNewUnitDisabled(false));
        dispatch(resetNewGameModuleCase());
        break;
      }
      default:
    }
    setData([value]);
    dispatch(setGameNewFilterValues([value, title]));
  };
  const iff = (condition, then, otherwise) => (condition ? then : otherwise);

  return (
    <div>
      <FormControl size="small" sx={{ width: '100%' }}>
        <Select
          data-testid="select-single"
          inputProps={{ 'data-testid': 'select-input' }}
          sx={{ maxHeight: '40px', maxWidth: '200px' }}
          value={data}
          onChange={handleChange}
          input={<OutlinedInput size="small" />}
          renderValue={(value) => (value?.length ? value : 'Show All')}
          size="small"
          margin="dense"
          disabled={iff(
            title === 'subject',
            false,
            iff(
              title === 'keyStage',
              isNewKeyStageDisabled,
              iff(
                title === 'course',
                isNewCourseDisabled,
                iff(title === 'module', isNewModuleDisabled, isNewUnitDisabled)
              )
            )
          )}
          placeholder="Select"
          MenuProps={MenuProps}
          displayEmpty
        >
          {options?.map((name) =>
            name.length > 50 ? (
              <CustomTooltip title={name} placement="left" arrow>
                <MenuItem key={name} value={name} sx={{ whiteSpace: 'normal' }}>
                  {name}
                </MenuItem>
              </CustomTooltip>
            ) : (
              <MenuItem key={name} value={name} sx={{ whiteSpace: 'normal' }}>
                {name}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>
    </div>
  );
};

NewFilterSingle.propTypes = {
  title: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
};

NewFilterSingle.defaultProps = {
  title: '',
};
