import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Backdrop, CircularProgress, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Navbar } from '../../Navbar';
import { BottomWrapper } from './BottomWrapper';
import { TopSection } from './TopSection';
import MidSection from './MidSectionWrapper';
import { ButtonCustom } from '../../custom/ButtonCustom';
import AlertDialog from '../../custom/AlertDialog';
import {
  resetData,
  setCloneAlert,
  setCloneFlag,
  setEditFlag,
  setEmptyAlert,
  setErrorFocusFlag,
} from '../../../store/reducer/gameDevelop';
import {
  fetchSingleGameDetails,
  saveCreateNewGame,
  saveEditGame,
} from '../../../services/actions/gameDevelop';
import { setOriginalVocabList, setSelectedVocabList } from '../../../store/reducer/gameDevelopment';
import {
  ALERT_CONTENT_CLONE,
  ALERT_CONTENT_VOCAB_CONFIRM,
} from '../../../assets/constantData/gameConstant';
import { resetGameDb } from '../../../store/reducer/gameDb';

const useStyles = makeStyles(() => ({
  bottomButtons: {
    minWidth: '120px',
  },
  typoBottomButtons: {
    textTransform: 'capitalize',
  },
  linkClass: {
    textDecoration: 'none',
  },
  okayAlertButtonClass: {
    '&.MuiButtonBase-root': {
      color: '#333333',
      borderColor: '#333333',
    },
    textTransform: 'capitalize',
  },
}));
export const GameDevelopment = ({ title }) => {
  const { id, clone } = useParams();
  const dispatch = useDispatch();
  const editFlag = useSelector((state) => state.gameDevelop.editFlag);
  const dropdowns = useSelector((state) => state.gameDevelop.dropdowns);
  const statusGame = useSelector((state) => state.gameDevelop.statusGame);
  const backdrop = useSelector((states) => states.uploadFile.backdrop);
  const liveFilters = useSelector((states) => states.gameDevelopment.liveFilter);
  const valArr = Object.entries(liveFilters);
  const finalArr = {};
  valArr.map((arr) => {
    if (arr[0] === 'subject') {
      if (arr[1].length === 1) {
        finalArr.selectedSubject = liveFilters.subject.join();
        arr[1] = arr[1].join();
      } else {
        arr[1] = '';
        finalArr.selectedSubject = '';
      }
    }
    if (arr[0] === 'keyStage') {
      if (arr[1].length === 1) {
        finalArr.selectedKeyStage = liveFilters.keyStage.join();
        arr[1] = arr[1].join();
      } else {
        arr[1] = '';
        finalArr.selectedKeyStage = '';
      }
    }
    if (arr[0] === 'course') {
      if (arr[1].length === 1) {
        finalArr.selectedCourse = liveFilters.course.join();
        arr[1] = arr[1].join();
      } else {
        arr[1] = '';
        finalArr.selectedCourse = '';
      }
    }
    if (arr[0] === 'module') {
      finalArr.selectedModule = liveFilters.module.join('$@$@');
    }
    if (arr[0] === 'unit') {
      finalArr.selectedUnit = liveFilters.unit.join('$@$@');
    }
    if (arr[0] === 'vocabType') {
      finalArr.selectedVocabType = liveFilters.vocabType.join('$@$@');
    }
    if (arr[0] === 'subGroup') {
      finalArr.selectedSubGroup = liveFilters.subGroup.join('$@$@');
    }

    return arr;
  });

  const navigate = useNavigate();
  useEffect(() => {
    dispatch(resetData());
    dispatch(setOriginalVocabList([]));
    dispatch(setSelectedVocabList([]));
    if (id || editFlag) {
      if (title === 'clone' && clone === 'clone') dispatch(setCloneFlag(true));
      dispatch(setEditFlag(true));
      dispatch(fetchSingleGameDetails(id));
    }
    dispatch(resetGameDb());
  }, []);
  const selectedVocabList = useSelector((state) => state.gameDevelop.dropdowns.selectedVocabList);
  const emptyAlert = useSelector((state) => state.gameDevelop.emptyAlert);
  const cloneAlert = useSelector((state) => state.gameDevelop.cloneAlert);
  const checkCloneName = useSelector((state) => state.gameDevelop.checkCloneName);
  const gameName = useSelector((state) => state?.gameDevelop?.dropdowns?.name);
  const trimmedGameName = gameName?.trim();
  const classes = useStyles();
  const handleCancel = () => {
    if (title === 'Create New') {
      navigate('/games/list');
      dispatch(resetData());
    } else if (title === 'edit') {
      navigate('/games/list');
      dispatch(setEditFlag(false));
      dispatch(resetData());
    } else {
      navigate('/games/list');
      dispatch(setCloneFlag(false));
      dispatch(setEditFlag(false));
      dispatch(resetData());
    }
  };
  const handlesubmit = async (e) => {
    e.preventDefault();
    if (!trimmedGameName) {
      window.scrollTo(0, 0);
      dispatch(setErrorFocusFlag(true));
      return;
    }
    if (selectedVocabList.length === 0) {
      dispatch(setEmptyAlert(true));
      return;
    }
    if (title === 'edit') {
      await dispatch(saveEditGame(dropdowns, 'Completed', id, finalArr));
      navigate('/games/list');
      dispatch(setEditFlag(false));
      dispatch(resetData());
    } else if (title === 'Create New') {
      await dispatch(saveCreateNewGame(dropdowns, 'Completed', finalArr));
      navigate('/games/list');
      dispatch(resetData());
    } else {
      if (checkCloneName === dropdowns?.name?.trim()) {
        dispatch(setCloneAlert(true));
        return;
      }
      await dispatch(saveCreateNewGame(dropdowns, 'Completed', finalArr));
      navigate('/games/list');
      dispatch(setEditFlag(false));
      dispatch(setCloneFlag(false));
      dispatch(resetData());
    }
  };
  const handleSaveAsDraft = async () => {
    if (!trimmedGameName) {
      window.scrollTo(0, 0);
      dispatch(setErrorFocusFlag(true));
      return;
    }
    if (title === 'edit') {
      await dispatch(saveEditGame(dropdowns, 'Draft', id, finalArr));
      navigate('/games/list');
      dispatch(setEditFlag(false));
      dispatch(resetData());
    } else if (title === 'Create New') {
      await dispatch(saveCreateNewGame(dropdowns, 'Draft', finalArr));
      navigate('/games/list');
      dispatch(resetData());
    } else {
      if (checkCloneName === dropdowns?.name?.trim()) {
        dispatch(setCloneAlert(true));
        return;
      }
      await dispatch(saveCreateNewGame(dropdowns, 'Draft', finalArr));
      navigate('/games/list');
      dispatch(setEditFlag(false));
      dispatch(setCloneFlag(false));
      dispatch(resetData());
    }
  };
  const emptyAlertClick = () => {
    dispatch(setEmptyAlert(false));
  };
  const cloneAlertClick = () => {
    dispatch(setCloneAlert(false));
  };
  return (
    <>
      <Navbar noNavigate />
      <form onSubmit={handlesubmit}>
        <TopSection title={title} />
        <MidSection />
        <AlertDialog
          okayTestId="confirmedPopup"
          open={emptyAlert}
          okBtn="OK"
          okClick={emptyAlertClick}
          okayVariant="outlined"
          okayButtonClass={classes.okayAlertButtonClass}
          contentText={ALERT_CONTENT_VOCAB_CONFIRM}
        />
        <AlertDialog
          okayTestId="cloneNamePopup"
          open={cloneAlert}
          okBtn="OK"
          okClick={cloneAlertClick}
          okayButtonClass={classes.okayAlertButtonClass}
          okayVariant="outlined"
          contentText={ALERT_CONTENT_CLONE}
        />
        <BottomWrapper />
        <Grid
          container
          columnGap={2}
          display="flex"
          justifyContent="flex-end"
          margin="0 0 0 auto"
          paddingTop={1}
          paddingBottom={1}
          paddingX={1}
          position="sticky"
          bottom="0"
          sx={{ background: '#FFFFFF' }}
        >
          <Grid item>
            <ButtonCustom
              valueProp="Cancel"
              variantProp="outlined"
              classProp={classes.bottomButtons}
              typoClassProp={classes.typoBottomButtons}
              onClick={handleCancel}
              customAttribute={{ 'data-testid': 'cancel' }}
            />
          </Grid>
          <Grid item>
            {((editFlag && statusGame === 'Draft' && title === 'edit') ||
              title === 'Create New' ||
              title === 'clone') && (
              <ButtonCustom
                data-testid="save"
                valueProp="Save As Draft"
                variantProp="outlined"
                classProp={classes.bottomButtons}
                typoClassProp={classes.typoBottomButtons}
                onClick={handleSaveAsDraft}
                customAttribute={{ 'data-testid': 'saveAsDraft' }}
              />
            )}
          </Grid>
          <Grid item>
            <ButtonCustom
              data-testid="save"
              valueProp="Save"
              variantProp="contained"
              classProp={classes.bottomButtons}
              typoClassProp={classes.typoBottomButtons}
              customAttribute={{ type: 'submit', 'data-testid': 'save' }}
            />
          </Grid>
        </Grid>
      </form>
      <Backdrop sx={{ color: '#fff', zIndex: 10000 }} open={backdrop}>
        <CircularProgress />
      </Backdrop>
    </>
  );
};

GameDevelopment.propTypes = {
  title: PropTypes.string.isRequired,
};
