import { AppBar, Stack, Tooltip } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import PearsonLogo from '../assets/icons/pearson-logo 2.png';
import '../assets/styles/Navbar.sass';
import Logo from './Logo';
import { logout } from '../utils/getEnvConfig';
import { resetDb } from '../store/reducer/languageDatabase';
import { resetFilters } from '../store/reducer/filterApply';
import { resetGameDb } from '../store/reducer/gameDb';
import { resetHistoryDb } from '../store/reducer/historyLogs';

export const Navbar = ({ noNavigate }) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(resetDb());
    dispatch(resetFilters());
    dispatch(resetGameDb());
    dispatch(resetHistoryDb());
  };
  const handleClose = () => {
    logout();
  };
  return (
    <AppBar position="sticky" className="appbar">
      <Stack className="flexbox" direction="row">
        {noNavigate ? (
          <Logo src={PearsonLogo} className="pearson-logo-img" />
        ) : (
          <Link to="/" className="link-style" data-testid="clicklogo" onClick={handleClick}>
            <Logo src={PearsonLogo} className="pearson-logo-img" />
          </Link>
        )}
        <Tooltip title="Logout">
          <LogoutIcon data-testid="logout" onClick={handleClose} sx={{ cursor: 'pointer' }} />
        </Tooltip>
        {/* <Avatar alt="Remy Sharp" src="" /> */}
      </Stack>
    </AppBar>
  );
};

Navbar.propTypes = {
  noNavigate: PropTypes.bool,
};

Navbar.defaultProps = {
  noNavigate: false,
};
