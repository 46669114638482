import React from 'react';
import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';
import '../../assets/styles/SelectCustom.sass';

export const SelectCustom = ({
  selectConstantData,
  incomingResponse,
  fetchUpdatedValue,
  rowKeysJson,
}) => {
  const [action, setAction] = React.useState(incomingResponse);
  const handleChange = (event) => {
    setAction(event.target.value);
    fetchUpdatedValue(event.target.value, rowKeysJson);
  };
  return (
    <FormControl size="small">
      <Select
        data-testid="select-custom"
        id="selectBox"
        value={action}
        inputProps={{ 'data-testid': 'content' }}
        onChange={handleChange}
        displayEmpty
        renderValue={
          action !== ''
            ? undefined
            : () => <Typography className="placeholderSelect">{incomingResponse}</Typography>
        }
      >
        {selectConstantData.map((value) => (
          <MenuItem key={value} value={value.value}>
            <Typography className="typoMenuItemOptions">{value.label}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

SelectCustom.propTypes = {
  selectConstantData: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  incomingResponse: PropTypes.string.isRequired,
  fetchUpdatedValue: PropTypes.func.isRequired,
  rowKeysJson: PropTypes.number.isRequired,
};
