import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filters: {
    subject: [],
    keyStage: [],
    course: [],
    module: [],
    unit: [],
    vocabType: [],
    subGroup: [],
  },
  liveFilter: {
    subject: [],
    keyStage: [],
    course: [],
    module: [],
    unit: [],
    subGroup: [],
    vocabType: [],
  },
  isKeyStageDisabled: true,
  isCourseDisabled: true,
  isModuleDisabled: true,
  isUnitDisabled: true,
  isVocabTypeDisabled: true,
  isSubGroupDisabled: true,
  originalVocabList: [],
  selectedVocabList: [],
};

const gameDevelopment = createSlice({
  name: 'gameDevelopment',
  initialState,
  reducers: {
    setGameFilterValues: (state, action) => {
      state.filters[action?.payload[0]] = action?.payload[1];
    },
    setGameFilterValuesAll: (state, action) => {
      state.liveFilter = action?.payload;
    },
    setOriginalVocabList: (state, action) => {
      state.originalVocabList = action?.payload;
    },
    setSelectedVocabList: (state, action) => {
      state.selectedVocabList = action?.payload;
    },
    setIsKeyStageDisabled: (state, action) => {
      state.isKeyStageDisabled = action?.payload;
    },
    setIsCourseDisabled: (state, action) => {
      state.isCourseDisabled = action?.payload;
    },
    setIsModuleDisabled: (state, action) => {
      state.isModuleDisabled = action?.payload;
    },
    setIsUnitDisabled: (state, action) => {
      state.isUnitDisabled = action?.payload;
    },
    setIsVocabTypeDisabled: (state, action) => {
      state.isVocabTypeDisabled = action?.payload;
    },
    setIsSubGroupDisabled: (state, action) => {
      state.isSubGroupDisabled = action?.payload;
    },
    setLiveFilter: (state, { payload: [data, title] }) => {
      state.liveFilter[title] = data;
    },
    setLiveFilterEdit: (state, action) => {
      state.liveFilter = action.payload;
    },
    resetGameSubjectCase: (state) => {
      state.liveFilter.keyStage = initialState.liveFilter.keyStage;
      state.liveFilter.course = initialState.liveFilter.course;
      state.liveFilter.module = initialState.liveFilter.module;
      state.liveFilter.unit = initialState.liveFilter.unit;
      state.liveFilter.vocabType = initialState.liveFilter.vocabType;
      state.liveFilter.subGroup = initialState.liveFilter.subGroup;
    },
    resetGameKeyStageCase: (state) => {
      state.liveFilter.course = initialState.liveFilter.course;
      state.liveFilter.module = initialState.liveFilter.module;
      state.liveFilter.unit = initialState.liveFilter.unit;
      state.liveFilter.vocabType = initialState.liveFilter.vocabType;
      state.liveFilter.subGroup = initialState.liveFilter.subGroup;
    },
    resetGameCourseCase: (state) => {
      state.liveFilter.module = initialState.liveFilter.module;
      state.liveFilter.unit = initialState.liveFilter.unit;
      state.liveFilter.vocabType = initialState.liveFilter.vocabType;
      state.liveFilter.subGroup = initialState.liveFilter.subGroup;
    },
    resetGameModuleCase: (state) => {
      state.liveFilter.unit = initialState.liveFilter.unit;
      state.liveFilter.vocabType = initialState.liveFilter.vocabType;
      state.liveFilter.subGroup = initialState.liveFilter.subGroup;
    },
    resetGameUnitCase: (state) => {
      state.liveFilter.vocabType = initialState.liveFilter.vocabType;
      state.liveFilter.subGroup = initialState.liveFilter.subGroup;
    },
    resetGameVocabTypeCase: (state) => {
      state.liveFilter.subGroup = initialState.liveFilter.subGroup;
    },
    resetGameFilters: (state) => {
      state.liveFilter = initialState.liveFilter;
      state.filters.keyStage = initialState.filters.keyStage;
      state.filters.course = initialState.filters.course;
      state.filters.module = initialState.filters.module;
      state.filters.unit = initialState.filters.unit;
      state.filters.vocabType = initialState.filters.vocabType;
      state.filters.subGroup = initialState.filters.subGroup;
      state.isKeyStageDisabled = initialState.isKeyStageDisabled;
      state.isCourseDisabled = initialState.isCourseDisabled;
      state.isModuleDisabled = initialState.isModuleDisabled;
      state.isUnitDisabled = initialState.isUnitDisabled;
      state.isVocabTypeDisabled = initialState.isVocabTypeDisabled;
      state.isSubGroupDisabled = initialState.isSubGroupDisabled;
    },
  },
});

export const {
  setGameFilterValues,
  setOriginalVocabList,
  setSelectedVocabList,
  setIsKeyStageDisabled,
  setIsCourseDisabled,
  setIsModuleDisabled,
  setIsUnitDisabled,
  setIsVocabTypeDisabled,
  setIsSubGroupDisabled,
  resetGameSubjectCase,
  resetGameKeyStageCase,
  resetGameCourseCase,
  resetGameModuleCase,
  resetGameUnitCase,
  resetGameVocabTypeCase,
  setLiveFilter,
  resetGameFilters,
  setGameFilterValuesAll,
  setLiveFilterEdit,
} = gameDevelopment.actions;
export default gameDevelopment.reducer;
