import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import '../../assets/styles/SideMenu.sass';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { sideMenuConstant } from '../../assets/constantData/sideMenuConstant';
import { resetFilters } from '../../store/reducer/filterApply';
import { resetDb } from '../../store/reducer/languageDatabase';
import { resetHistoryDb } from '../../store/reducer/historyLogs';
import { resetGameDb } from '../../store/reducer/gameDb';

export const SideMenu = () => {
  const [state, setState] = React.useState({
    left: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const dispatch = useDispatch();

  const handleClick = (value) => {
    switch (value) {
      case 'Language Database':
        dispatch(resetFilters());
        dispatch(resetDb());
        dispatch(resetHistoryDb());
        dispatch(resetGameDb());
        break;
      case 'Game Management':
        dispatch(resetFilters());
        dispatch(resetDb());
        dispatch(resetHistoryDb());
        break;
      case 'History Logs':
        dispatch(resetFilters());
        dispatch(resetDb());
        dispatch(resetGameDb());
        break;
      default:
        break;
    }
  };

  const list = (anchor) => (
    <Box
      data-testid="list-box"
      className="listBox"
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List className="listMenu" key="ListUnique">
        <ListItem className="closeIcon" key="closeButton">
          <IconButton>
            <CloseIcon />
          </IconButton>
        </ListItem>
        {sideMenuConstant.map((text) => (
          <Box key={text.id}>
            <ListItem disablePadding>
              <ListItemButton
                data-testid={text.value}
                className="listItem"
                component={Link}
                to={text.link}
                onClick={() => handleClick(text.value)}
              >
                <ListItemText primary={text.value} className="listText" />
              </ListItemButton>
            </ListItem>
            <Divider />
          </Box>
        ))}
      </List>
    </Box>
  );

  return (
    <Box className="MainWrapper">
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <MenuIcon
            data-testid="menu-icon"
            className="MenuIcon"
            onClick={toggleDrawer(anchor, true)}
          />
          <Drawer
            className="leftDrawer"
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </Box>
  );
};
