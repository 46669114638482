import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  response: {},
  backdrop: false,
  alertPopup: false,
  infoPopup: false,
  uploadedFile: null,
};

const uploadFile = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    setFetchFile: (state, action) => {
      state.response = action?.payload;
    },
    setBackdrop: (state, action) => {
      state.backdrop = action?.payload;
    },
    setAlertPopup: (state, action) => {
      state.alertPopup = action?.payload;
    },
    setInfoPopup: (state, action) => {
      state.infoPopup = action?.payload;
    },
    setUploadedFile: (state, action) => {
      state.uploadedFile = action?.payload;
    },
  },
});

export const { setFetchFile, setBackdrop, setAlertPopup, setInfoPopup, setUploadedFile } =
  uploadFile.actions;
export default uploadFile.reducer;
