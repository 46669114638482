import { makeStyles } from '@mui/styles';

export const useStyle = makeStyles({
  buttonStyle: {
    width: '100%',
  },
  createButtonStyle: {
    float: 'right',
  },
});

export const tableStyle = makeStyles({
  headingStyle: {
    backgroundColor: '#E8ECF1',
  },
});
