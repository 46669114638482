import { post, remove } from '../../utils/request-axios';
import mflConfig from '../../utils/config';
import { clearAll, fetchAll, fetchAllAfterEditReducer } from '../../store/reducer/languageDatabase';
import { setSearchString } from '../../store/reducer/filterApply';
import store from '../../store/store';
import { setBackdrop } from '../../store/reducer/uploadFile';

export const fetchAllRecords =
  (pageNo, isClearAll = false) =>
  async (dispatch) => {
    const limit = 50;
    const skip = limit * pageNo;
    store.dispatch(setBackdrop(true));
    const response = await post(`${mflConfig.API_URL}language/?$limit=${limit}&$skip=${skip}`, {
      subject: [],
      course: [],
      keyStage: [],
      module: [],
      unit: [],
      assets: [],
      searchStr: '',
    });
    store.dispatch(setBackdrop(false));
    if (isClearAll) {
      dispatch(clearAll(response?.data));
    } else {
      dispatch(fetchAll(response?.data));
    }
  };

export const fetchRecordsWithIDandItem1 = (userInp) => async (dispatch) => {
  dispatch(setSearchString(userInp));
};

export const deleteRecordsWithIds = async (idsArr) => {
  await remove(`${mflConfig.API_URL}language/`, {
    languageId: idsArr,
  });
};

export const fetchAllRecordsAfterEdit = (pageNo) => async (dispatch) => {
  const limit = 50 * pageNo;
  const skip = 0;
  const response = await post(`${mflConfig.API_URL}language/?$limit=${limit}&$skip=${skip}`, {
    subject: [],
    course: [],
    keyStage: [],
    module: [],
    unit: [],
    assets: [],
    searchStr: '',
  });
  dispatch(fetchAllAfterEditReducer(response?.data));
};
