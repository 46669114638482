import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';

export const CustomMenu = ({ open, handleClose, anchorEl, menuItems }) => (
  <Menu
    data-testid="menu"
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    MenuListProps={{
      'aria-labelledby': 'basic-button',
      'data-testid': 'menu',
    }}
  >
    {menuItems.map((item) => (
      <MenuItem data-testid="gameOptions" key={item} onClick={() => handleClose(item)}>
        {item}
      </MenuItem>
    ))}
  </Menu>
);

CustomMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  anchorEl: PropTypes.instanceOf.isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
};

CustomMenu.defaultProps = {
  handleClose: () => {},
};
