import { PropTypes } from 'prop-types';
import { Grid } from '@mui/material';
import { DropdownWithLabel } from '../../custom/DropdownWithLabel';
import { TextfieldWithLabel } from '../../custom/TextfieldWithLabel';

export const DifficultyLevels = ({
  labelTextfield,
  levelNumber,
  promptValues,
  upperDropdownValues,
}) => (
  <Grid container>
    <TextfieldWithLabel labelValue={labelTextfield} />
    <Grid container columnSpacing={2} paddingTop={2}>
      <Grid item xs={3}>
        <DropdownWithLabel
          labelName="Number of Distractors"
          levelNumber={levelNumber}
          dropdownValues={upperDropdownValues[0]}
        />
      </Grid>
      <Grid item xs={3}>
        <DropdownWithLabel
          labelName="Source of Distractors"
          levelNumber={levelNumber}
          dropdownValues={upperDropdownValues[1]}
        />
      </Grid>
      <Grid item xs={3}>
        <DropdownWithLabel
          labelName="Number of Gaps"
          levelNumber={levelNumber}
          dropdownValues={upperDropdownValues[2]}
        />
      </Grid>
    </Grid>
    <Grid container columnSpacing={2} paddingTop={2}>
      <Grid item xs={3}>
        <DropdownWithLabel
          labelName="Image Prompt"
          levelNumber={levelNumber}
          dropdownValues={promptValues}
        />
      </Grid>
      <Grid item xs={3}>
        <DropdownWithLabel
          labelName="Audio Prompt"
          levelNumber={levelNumber}
          dropdownValues={promptValues}
        />
      </Grid>
      <Grid item xs={3}>
        <DropdownWithLabel
          labelName="Text Prompt"
          levelNumber={levelNumber}
          dropdownValues={promptValues}
        />
      </Grid>
    </Grid>
  </Grid>
);

DifficultyLevels.propTypes = {
  labelTextfield: PropTypes.string,
  levelNumber: PropTypes.string.isRequired,
  promptValues: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  upperDropdownValues: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
};

DifficultyLevels.defaultProps = {
  labelTextfield: '',
};
