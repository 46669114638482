import { Backdrop, CircularProgress, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navbar } from '../Navbar';
import { SubHeader } from '../custom/SubHeader';
import { fetchAllHistoryFilterValues, postFilteredData } from '../../services/actions/historyLogs';
import { DatePickerWIthLabel } from './DatePickerWIthLabel';
import { TableWrapper } from '../game/list/TableWrapper';
import { ButtonCustom } from '../custom/ButtonCustom';
import { HistoryFilterContainer } from './HistoryFilterContainer';
import { updatePageNo } from '../../store/reducer/historyLogs';
import { LOAD_MORE, NO_RESULT_FOUND } from '../../assets/constantData/gameConstant';

const HistoryLogs = () => {
  const defaultEndDate = new Date();
  defaultEndDate.setDate(defaultEndDate.getDate() + 7);

  const dispatch = useDispatch();
  const logData = useSelector((states) => states.historyLogs);
  const backdrop = useSelector((states) => states.uploadFile.backdrop);
  useEffect(() => {
    dispatch(fetchAllHistoryFilterValues({ subject: [] }));
    dispatch(postFilteredData(logData.dataToSend, 0, false));
  }, []);

  const handleLoadMore = () => {
    dispatch(updatePageNo());
    dispatch(postFilteredData(logData.dataToSend, logData.pageNo + 1, false));
  };

  const compare = () => {
    if (logData.data.length === 0 && logData.list.length === 0) {
      return <h1>{NO_RESULT_FOUND}</h1>;
    }
    if (logData.data.length === 50)
      return (
        <ButtonCustom
          valueProp={LOAD_MORE}
          variantProp="outlined"
          classProp="loadMore"
          typoClassProp="typoLoadMore"
          onClick={() => {
            handleLoadMore();
          }}
          customAttribute={{ 'data-testid': 'loadMore' }}
        />
      );
    return null;
  };

  return (
    <>
      <Navbar />
      <Grid paddingX={4}>
        <SubHeader sidemenu title="History Logs" searchBox />
        <HistoryFilterContainer filters={logData.filters} />
        <DatePickerWIthLabel />
        <br />
        <TableWrapper isHistoryLogs rows={logData.list} columns={logData.columns} />
        <Grid display="flex" justifyContent="center" paddingY={2}>
          {compare()}
        </Grid>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdrop}
        >
          <CircularProgress />
        </Backdrop>
      </Grid>
    </>
  );
};

export default HistoryLogs;
