import * as React from 'react';
import {
  ListItemIcon,
  OutlinedInput,
  MenuItem,
  FormControl,
  Select,
  Checkbox,
  Tooltip,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@mui/styles';
import { MenuProps, useStyles } from '../../assets/constantData/utils';
import { fetchAllGameFilterValues } from '../../services/actions/gameDevelopment';
import {
  resetGameModuleCase,
  resetGameUnitCase,
  resetGameVocabTypeCase,
  setGameFilterValues,
  setIsModuleDisabled,
  setIsSubGroupDisabled,
  setIsUnitDisabled,
  setIsVocabTypeDisabled,
  setLiveFilter,
} from '../../store/reducer/gameDevelopment';

const CustomTooltip = withStyles({
  tooltip: {
    fontSize: '14px',
  },
})(Tooltip);

export const DropdownCheck = ({ options, title }) => {
  const [data, setData] = React.useState([]);
  const dispatch = useDispatch();
  const liveFilters = useSelector((state) => state.gameDevelopment.liveFilter);
  const isModuleDisabled = useSelector((state) => state.gameDevelopment.isModuleDisabled);
  const isUnitDisabled = useSelector((state) => state.gameDevelopment.isUnitDisabled);
  const isVocabTypeDisabled = useSelector((state) => state.gameDevelopment.isVocabTypeDisabled);
  const isSubGroupDisabled = useSelector((state) => state.gameDevelopment.isSubGroupDisabled);
  const editFlag = useSelector((state) => state?.gameDevelop?.editFlag);
  const cloneFlag = useSelector((state) => state?.gameDevelop?.cloneFlag);
  React.useEffect(() => {
    if (editFlag || cloneFlag) {
      setData(liveFilters[title]);
      if (liveFilters.module.length !== 0 || liveFilters.course.length !== 0)
        dispatch(setIsModuleDisabled(false));
      if (liveFilters.unit.length !== 0 || liveFilters.module.length !== 0)
        dispatch(setIsUnitDisabled(false));
      if (liveFilters.vocabType.length !== 0 || liveFilters.unit.length !== 0)
        dispatch(setIsVocabTypeDisabled(false));
      if (liveFilters.subGroup.length !== 0 || liveFilters.vocabType.length !== 0)
        dispatch(setIsSubGroupDisabled(false));
    }
  }, [editFlag, liveFilters]);

  React.useEffect(() => {
    if (liveFilters.module.length === 0 && title === 'module') setData([]);
    if (liveFilters.unit.length === 0 && title === 'unit') setData([]);
    if (liveFilters.vocabType.length === 0 && title === 'vocabType') setData([]);
    if (liveFilters.subGroup.length === 0 && title === 'subGroup') setData([]);
  }, [liveFilters.module, liveFilters.unit, liveFilters.vocabType, liveFilters.subGroup]);

  React.useEffect(() => {
    if (isModuleDisabled && title === 'module') dispatch(setGameFilterValues([title, []]));
    if (isUnitDisabled && title === 'unit') dispatch(setGameFilterValues([title, []]));
    if (isVocabTypeDisabled && title === 'vocabType') dispatch(setGameFilterValues([title, []]));
    if (isSubGroupDisabled && title === 'subGroup') dispatch(setGameFilterValues([title, []]));
  }, [isModuleDisabled, isUnitDisabled, isVocabTypeDisabled, isSubGroupDisabled]);

  const isAllSelected = options.length > 0 && data?.length === options.length;
  const classes = useStyles();
  const handleChange = (event) => {
    let {
      target: { value },
    } = event;
    if (value[value.length - 1] === 'all') {
      value = data.length === options.length ? [] : options;
    }
    switch (title) {
      case 'module': {
        if (value.length === 0) {
          setData(value);
          dispatch(setLiveFilter([value, title]));
          dispatch(setIsUnitDisabled(true));
          dispatch(setIsVocabTypeDisabled(true));
          dispatch(setIsSubGroupDisabled(true));
          dispatch(resetGameModuleCase());
          return;
        }
        dispatch(
          fetchAllGameFilterValues({
            subject: liveFilters.subject,
            keyStage: liveFilters.keyStage,
            course: liveFilters.course,
            module: value,
            unit: [],
          })
        );
        dispatch(setIsUnitDisabled(false));
        dispatch(setIsVocabTypeDisabled(true));
        dispatch(setIsSubGroupDisabled(true));
        dispatch(resetGameModuleCase());
        break;
      }
      case 'unit': {
        if (value.length === 0) {
          setData(value);
          dispatch(setLiveFilter([value, title]));
          dispatch(setIsVocabTypeDisabled(true));
          dispatch(setIsSubGroupDisabled(true));
          dispatch(resetGameUnitCase());
          return;
        }
        dispatch(
          fetchAllGameFilterValues({
            subject: liveFilters.subject,
            keyStage: liveFilters.keyStage,
            course: liveFilters.course,
            module: liveFilters.module,
            unit: value,
            vocabType: [],
            subGroup: [],
          })
        );
        dispatch(setIsVocabTypeDisabled(false));
        dispatch(setIsSubGroupDisabled(true));
        dispatch(resetGameUnitCase());
        break;
      }
      case 'vocabType': {
        if (value.length === 0) {
          setData(value);
          dispatch(setLiveFilter([value, title]));
          dispatch(setIsSubGroupDisabled(true));
          dispatch(resetGameVocabTypeCase());
          return;
        }
        dispatch(
          fetchAllGameFilterValues({
            subject: liveFilters.subject,
            keyStage: liveFilters.keyStage,
            course: liveFilters.course,
            module: liveFilters.module,
            unit: liveFilters.unit,
            vocabType: value,
            subGroup: [],
          })
        );
        dispatch(setIsSubGroupDisabled(false));
        dispatch(resetGameVocabTypeCase());
        break;
      }
      default:
    }
    setData(value);
    dispatch(setLiveFilter([value, title]));
  };

  const iff = (condition, then, otherwise) => (condition ? then : otherwise);
  const checkDisable = () => {
    switch (title) {
      case 'module':
        return isModuleDisabled;
      case 'unit':
        return isUnitDisabled;
      case 'vocabType':
        return isVocabTypeDisabled;
      case 'subGroup':
        return isSubGroupDisabled;
      default:
        return false;
    }
  };
  return (
    <div>
      <FormControl size="small" sx={{ width: '100%' }}>
        <Select
          data-testid="check-select"
          sx={{ maxHeight: '40px', maxWidth: '200px' }}
          multiple
          value={data}
          inputProps={{ 'data-testid': 'select-input' }}
          onChange={handleChange}
          input={<OutlinedInput size="small" />}
          renderValue={(value) =>
            iff(value?.length, iff(Array.isArray(value), value.join(', '), value), 'Show All')
          }
          size="small"
          margin="dense"
          disabled={checkDisable()}
          placeholder="Select"
          MenuProps={MenuProps}
          displayEmpty
        >
          <MenuItem
            value="all"
            classes={{
              root: isAllSelected ? classes.selectedAll : '',
            }}
          >
            <ListItemIcon>
              <Checkbox
                sx={{ padding: 0 }}
                classes={{ indeterminate: classes.indeterminateColor }}
                checked={isAllSelected}
                indeterminate={data?.length > 0 && data.length < options.length}
              />
            </ListItemIcon>
            Select All
          </MenuItem>
          {options?.map((name) =>
            name.length > 50 ? (
              <CustomTooltip title={name} placement="left" arrow>
                <MenuItem key={name} value={name} sx={{ whiteSpace: 'normal' }}>
                  <ListItemIcon>
                    <Checkbox sx={{ padding: 0 }} checked={data.indexOf(name) > -1} />
                  </ListItemIcon>
                  {name}
                </MenuItem>
              </CustomTooltip>
            ) : (
              <MenuItem key={name} value={name} sx={{ whiteSpace: 'normal' }}>
                <ListItemIcon>
                  <Checkbox sx={{ padding: 0 }} checked={data.indexOf(name) > -1} />
                </ListItemIcon>
                {name}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>
    </div>
  );
};

DropdownCheck.propTypes = {
  title: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
};

DropdownCheck.defaultProps = {
  title: '',
};
