import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useStyle } from '../../../assets/styles/material-ui/gameDashboard';
import { postGameFilters } from '../../../services/actions/gameDb';
import { resetGameDb, resetPageNoGameDb, setIsFilterApplied } from '../../../store/reducer/gameDb';
import { ButtonCustom } from '../../custom/ButtonCustom';
import { APPLY_FILTERS, CLEAR_ALL } from '../../../assets/constantData/gameConstant';
import { fetchAllNewGameFilterValues } from '../../../services/actions/gameDevelopment';

export const ActionContainer = () => {
  const dispatch = useDispatch();
  const updatedFilters = useSelector((state) => state.gameDb.dataToSend);
  const newLiveFilters = useSelector((state) => state.gameDb.newLiveFilters);
  const totalRecords = useSelector((state) => state.gameDb.totalRecords);
  const currDataCount = useSelector((state) => state.gameDb.list.length);
  const isFilterApplied = useSelector((state) => state.gameDb.isFilterApplied);
  const lengthOfFilters =
    updatedFilters.taskAction.length ||
    updatedFilters.gameMechanics.length ||
    updatedFilters.theme.length ||
    updatedFilters.gameStatus.length ||
    newLiveFilters.subject.length ||
    newLiveFilters.keyStage.length ||
    newLiveFilters.course.length ||
    newLiveFilters.module.length ||
    newLiveFilters.unit.length;
  const postFilters = () => {
    if (
      lengthOfFilters &&
      (updatedFilters.searchStr.length >= 3 || updatedFilters.searchStr.length === 0)
    ) {
      dispatch(resetPageNoGameDb());
      dispatch(setIsFilterApplied(true));
      dispatch(postGameFilters({ ...updatedFilters, ...newLiveFilters }, 0, false));
    }
  };
  const clearFilters = () => {
    if (isFilterApplied) {
      dispatch(resetGameDb());
      dispatch(fetchAllNewGameFilterValues({ subject: [] }));
      dispatch(postGameFilters({ ...updatedFilters, ...newLiveFilters }, 0, true));
    }
  };
  const classes = useStyle();
  return (
    <Grid container columnSpacing={2} paddingY={1} rowGap={1}>
      <Grid item xs={6} sm={3} md={2}>
        <ButtonCustom
          valueProp={APPLY_FILTERS}
          variantProp="contained"
          classProp={classes.buttonStyle}
          onClick={postFilters}
          customAttribute={{
            style: { textTransform: 'none', width: '100%' },
            'data-testid': 'applyFilterActionContainer',
          }}
        />
      </Grid>
      <Grid item xs={6} sm={2} md={1.5}>
        <ButtonCustom
          valueProp={CLEAR_ALL}
          variantProp="text"
          classProp={classes.buttonStyle}
          customAttribute={{
            style: { textTransform: 'none', width: '100%', textDecoration: 'underline' },
            'data-testid': 'clearAllActionContainer',
          }}
          onClick={clearFilters}
        />
      </Grid>
      <Grid item xs={6} sm={4} md={4} alignSelf="center">
        <Typography>{`No. of records showing: ${currDataCount}/${totalRecords}`}</Typography>
      </Grid>
      <Grid item xs={6} sm={3} md={4.5} justifySelf="end">
        <Link to="/gameDevelopment">
          <ButtonCustom
            valueProp="Create New"
            variantProp="contained"
            classProp={classes.createButtonStyle}
            customAttribute={{
              style: { textTransform: 'none', float: 'right' },
            }}
          />
        </Link>
      </Grid>
    </Grid>
  );
};

ActionContainer.propTypes = {};
