import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import '../../assets/styles/AlertDialog.sass';
import PropTypes from 'prop-types';

const AlertDialog = ({
  divider,
  open,
  title,
  contentText,
  contentText2,
  okBtn,
  cancelBtn,
  okClick,
  cancelClick,
  closeButtonClick,
  closeBtn,
  cancelButtonClass,
  okayButtonClass,
  cancelVariant,
  okayVariant,
  okayTestId,
  cancelTestId,
  closeTestId,
}) => {
  const renderButton = () => (
    <>
      {cancelBtn !== '' && cancelBtn !== undefined && (
        <Button
          data-testid={cancelTestId}
          color="primary"
          variant={cancelVariant}
          size="medium"
          onClick={cancelClick}
          className={cancelButtonClass}
        >
          {cancelBtn}
        </Button>
      )}
      <Button
        data-testid={okayTestId}
        variant={okayVariant}
        size="medium"
        onClick={okClick}
        className={okayButtonClass}
      >
        {okBtn}
      </Button>
    </>
  );

  const popUpTitle = () => (
    <>
      {title}
      {closeBtn === true && (
        <CloseIcon data-testid={closeTestId} className="iconClose" onClick={closeButtonClick} />
      )}
    </>
  );

  return (
    <div>
      <Dialog open={open} classes={{ paper: 'wrapper' }}>
        <DialogTitle id="alert-dialog-title">{popUpTitle()}</DialogTitle>
        {divider === true && <Divider light />}
        <DialogContent>
          <DialogContentText className="contentStyle">{contentText}</DialogContentText>
          <DialogContentText style={{ width: '70%', margin: '0 auto', textAlign: 'left' }}>
            {contentText2}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="buttonStyle">
          {okBtn !== '' && okBtn !== undefined && renderButton()}
        </DialogActions>
      </Dialog>
    </div>
  );
};

AlertDialog.propTypes = {
  okayTestId: PropTypes.string,
  cancelTestId: PropTypes.string,
  closeTestId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  contentText: PropTypes.string.isRequired,
  contentText2: PropTypes.string.isRequired,
  okBtn: PropTypes.string,
  cancelBtn: PropTypes.string,
  okClick: PropTypes.func,
  cancelClick: PropTypes.func,
  closeButtonClick: PropTypes.func,
  divider: PropTypes.bool,
  closeBtn: PropTypes.bool,
  cancelButtonClass: PropTypes.string,
  okayButtonClass: PropTypes.string,
  cancelVariant: PropTypes.string,
  okayVariant: PropTypes.string,
};

AlertDialog.defaultProps = {
  okayTestId: '',
  cancelTestId: '',
  closeTestId: '',
  okBtn: '',
  title: '',
  cancelBtn: '',
  okClick: () => {},
  cancelClick: () => {},
  closeButtonClick: () => {},
  divider: false,
  closeBtn: false,
  cancelButtonClass: '',
  okayButtonClass: '',
  cancelVariant: '',
  okayVariant: '',
};

export default AlertDialog;
