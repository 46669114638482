import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonCustom } from '../custom/ButtonCustom';
import '../../assets/styles/LanguageDatabaseDashboard.sass';
import { fetchFileDownloadUrl } from '../../services/actions/errorLogs';

const ErrorTable = () => {
  let sNo = 0;
  const getSno = () => {
    sNo += 1;
    return sNo;
  };
  const tableData = useSelector((state) => state.errorLogs.errorData);
  const useStyle = makeStyles(() => ({
    typoClass: {
      textTransform: 'capitalize',
      fontWeight: 'bold',
    },
  }));
  const classes = useStyle();
  const dispatch = useDispatch();
  const downloadErrorFile = async (fileName) => {
    const downloadUrl = await dispatch(fetchFileDownloadUrl(fileName));
    const anchorLink = document.createElement('a');
    anchorLink.href = downloadUrl;
    anchorLink.download = 'Download Template';
    document.body.appendChild(anchorLink);
    anchorLink.click();
    anchorLink.parentNode.removeChild(anchorLink);
  };
  return (
    <Box sx={{ width: '100%', marginTop: '20px' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead sx={{ backgroundColor: '#E8ECF1' }}>
              <TableRow>
                <TableCell align="left" className="headcell">
                  Serial No.
                </TableCell>
                <TableCell align="left" className="headcell">
                  File Name
                </TableCell>
                <TableCell align="left" className="headcell">
                  Date
                </TableCell>
                <TableCell align="left" className="headcell">
                  Time
                </TableCell>
                <TableCell align="center" className="headcell">
                  Report
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row) => (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="left">{getSno()}</TableCell>
                  <TableCell align="left">{row.fileName}</TableCell>
                  <TableCell align="left">
                    {row.createdAt.substring(0, 10).split('-').reverse().join('-')}
                  </TableCell>
                  <TableCell align="left">{row.createdAt.substring(11, 16)}</TableCell>
                  <TableCell align="center">
                    <ButtonCustom
                      customAttribute={{ 'data-testid': 'downloaderror' }}
                      valueProp="Download"
                      variantProp="outlined"
                      typoClassProp={classes.typoClass}
                      onClick={() => downloadErrorFile(row.fileName)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default ErrorTable;
