export const LANGUAGE_FIELDS = {
  vocabId: 'Vocab ID',
  subject: 'Subject',
  keyStage: 'Key Stage',
  course: 'Course',
  module: 'Module',
  unit: 'Unit',
  subGroup: 'Sub Group',
  vocabType: 'Vocab Type',
  item1: 'Item 1',
  alternateItem1: 'Alternate Item 1',
  caseSensitiveItem1: 'Case Sensitive Item 1',
  imageItem1: 'Image Item 1',
  audioItem1: 'Audio Item 1',
  item2: 'Item 2',
  alternateItem2: 'Alternate Item 2',
  caseSensitiveItem2: 'Case Sensitive Item 2',
  imageItem2: 'Image Item 2',
  audioItem2: 'Audio Item 2',
};

export const SEARCH_TOOLTIP_TITLE_LANGUAGE = 'Search with Vocab ID or Item1';
export const SEARCH_TOOLTIP_TITLE_GAMEDB = 'Search with Game Name';
