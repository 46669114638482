import { Box, InputLabel, Typography, Snackbar, Alert, Container } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { makeStyles } from '@mui/styles';
import Cards from '../Cards';
import '../../assets/styles/LanguageDatabaseDashboard.sass';
import '../../assets/styles/LangHeader.sass';
import { secondPageData } from '../../assets/constantData/cardsData';
import { Navbar } from '../Navbar';
import DropDown from '../DropDown';
import { ButtonCustom } from '../custom/ButtonCustom';
import { SubHeader } from '../custom/SubHeader';
import AlertDialog from '../custom/AlertDialog';
import { setAlertPopup, setInfoPopup } from '../../store/reducer/uploadFile';
import { snackPopup, setSnackState } from '../../store/reducer/snackbarReducer';
import { uploadFile } from '../../services/actions/uploadFile';
import { fetchAllFiltersNew } from '../../services/actions/filterSelection';
import { setRetainedFilters } from '../../store/reducer/languageDatabase';
import {
  ALERT_CONTENT_TEXT,
  LANGUAGE_DASHBOARD_TITLE,
  VIEW_RECORDS_FOR,
} from '../../assets/constantData/dashboard';
import { resetErrorFilters } from '../../store/reducer/errorLogs';
import SingleDropdown from '../custom/SingleDropdown';

const LanguageDatabaseDashboard = () => {
  const dispatch = useDispatch();
  const response = useSelector((state) => state?.uploadFile?.response);
  const backdrop = useSelector((states) => states?.uploadFile?.backdrop);
  const alertPopup = useSelector((states) => states?.uploadFile?.alertPopup);
  const infoPopup = useSelector((states) => states?.uploadFile?.infoPopup);
  const uploadedFile = useSelector((states) => states?.uploadFile?.uploadedFile);
  const { openSnackbar, message, type } = useSelector(snackPopup);
  const selectedFilters = useSelector((state) => state?.filterApply?.filterValues);
  const entries = Object.entries(selectedFilters);
  const names = secondPageData;

  const useStyle = makeStyles(() => ({
    okayAlertButtonClass: {
      '&.MuiButtonBase-root': {
        backgroundColor: '#003057',
      },
      textTransform: 'capitalize',
    },
    cancelAlertButtonClass: {
      '&.MuiButtonBase-root': {
        color: '#333333',
        borderColor: '#333333',
      },
      textTransform: 'capitalize',
    },
    cancelInfoPopupClass: {
      '&.MuiButtonBase-root': {
        color: '#333333',
        borderColor: '#333333',
      },
    },
    typoClass: {
      textTransform: 'capitalize',
    },
  }));
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchAllFiltersNew({ subject: [] }));
    dispatch(resetErrorFilters());
  }, []);

  const responseRender = () => (
    <ul className="wrapperList">
      {response?.data?.success && <li className="listPopup">{response.data?.success}</li>}
      {response?.data?.updated && <li className="listPopup">{response.data?.updated}</li>}
      {response?.data?.error && <li className="listPopup">{response.data?.error}</li>}
    </ul>
  );
  const cancelInfoPopupClick = () => {
    dispatch(setInfoPopup(false));
  };
  const cancelClick = () => {
    dispatch(setAlertPopup(false));
    uploadFile(uploadedFile, 'add');
  };
  const closeButtonClick = () => {
    dispatch(setAlertPopup(false));
  };
  const okClick = () => {
    dispatch(setAlertPopup(false));
    uploadFile(uploadedFile, 'update');
  };

  const viewSummaryClick = () => {
    navigate('/errorLogs');
    dispatch(setInfoPopup(false));
  };

  const handleSnackClose = () => {
    dispatch(
      setSnackState({
        openSnackbar: false,
        message: '',
        type: '',
      })
    );
  };

  const handleFields = (key, value) => {
    const createData = (isKey, isValue) => ({ isKey, isValue });
    switch (key) {
      case 'subject':
      case 'keyStage':
      case 'course':
      case 'module':
      case 'unit':
        return createData(key, value);
      default:
        return null;
    }
  };
  const appliedFilters = useSelector((state) => state.filterApply.appliedFilters);
  const sendFilters = () => {
    if (
      appliedFilters?.subject?.length ||
      appliedFilters?.keyStage?.length ||
      appliedFilters?.module?.length ||
      appliedFilters?.unit?.length ||
      appliedFilters?.course?.length
    )
      dispatch(setRetainedFilters(true));
  };

  const classes = useStyle();
  return (
    <>
      <Box className="container">
        <Navbar />
        <Container maxWidth="large">
          <SubHeader sidemenu backBtn={false} title={LANGUAGE_DASHBOARD_TITLE} />
        </Container>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={() => handleSnackClose()}
        >
          <Alert severity={type} sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>
        <Cards names={names} />
        <Box />
        <AlertDialog
          data-testid="updatePopup"
          okayTestId="okayAlert"
          cancelTestId="cancelAlert"
          closeBtn
          closeButtonClick={closeButtonClick}
          open={alertPopup}
          okBtn="Replace"
          cancelBtn="Add as New"
          closeTestId="closeTest"
          okClick={okClick}
          cancelClick={cancelClick}
          cancelButtonClass={classes.cancelAlertButtonClass}
          okayButtonClass={classes.okayAlertButtonClass}
          cancelVariant="outlined"
          okayVariant="contained"
          contentText={ALERT_CONTENT_TEXT}
        />
        <AlertDialog
          data-testid="infoPopup"
          open={infoPopup}
          okBtn="OK"
          okayTestId="oktestid"
          cancelTestId="viewSummaryClick"
          cancelBtn="View Summary"
          okClick={cancelInfoPopupClick}
          cancelClick={viewSummaryClick}
          okayButtonClass={classes.cancelInfoPopupClass}
          cancelButtonClass={classes.cancelAlertButtonClass}
          okayVariant="outlined"
          cancelVariant="outlined"
          contentText={responseRender()}
        />
        <Box className="head-filters-cont">
          <Typography className="heading-style-2" fontSize="20px" variant="h3" fontWeight="700">
            {VIEW_RECORDS_FOR}
          </Typography>
          <Box className="boxFiltersList">
            {entries.map(([key, value]) => {
              const data = handleFields(key, value);
              return (
                <>
                  {/* eslint-disable-next-line */}
                  {data && (
                    <Box key={data.isKey} className="innerSelectBox">
                      <InputLabel id="mutiple-select-only-label">
                        {key === 'keyStage' ? 'Key Stage' : data.isKey}
                      </InputLabel>
                      {key === 'subject' ||
                      key === 'keyStage' ||
                      key === 'course' ||
                      key === 'assets' ? (
                        <SingleDropdown key={key} filterOptions={value} keys={key} />
                      ) : (
                        <DropDown key={key} filterOptions={value} keys={key} />
                      )}
                    </Box>
                  )}
                </>
              );
            })}
          </Box>
          <Box className="button-wrapper">
            <Link className="link-style" to="/viewrecords">
              <ButtonCustom
                customAttribute={{ 'data-testid': 'sendLanguageFilters' }}
                valueProp="View Records"
                variantProp="contained"
                classProp="button-style"
                typoClassProp={classes.typoClass}
                onClick={sendFilters}
              />
            </Link>
          </Box>
        </Box>
      </Box>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backdrop}>
        <CircularProgress />
      </Backdrop>
    </>
  );
};

export default LanguageDatabaseDashboard;
