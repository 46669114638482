import axios from 'axios';

export const fetch = (url, ...param) => axios.get(url, { params: { ...param } });

export const post = (url, data) =>
  axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const edit = (url, data, configs) =>
  axios.put(url, data, {
    headers: {
      'Content-Type': 'application/json',
    },
    ...configs,
  });

export const update = (url, data, configs) =>
  axios.patch(url, data, {
    headers: {
      'Content-Type': 'application/json',
    },
    ...configs,
  });
export const remove = (url, data) =>
  axios.delete(url, {
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  });
