import * as React from 'react';
import {
  ListItemIcon,
  OutlinedInput,
  MenuItem,
  FormControl,
  Select,
  Checkbox,
  Tooltip,
} from '@mui/material';
import '../../assets/styles/DropDown.sass';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@mui/styles';
import { MenuProps, useStyles } from '../../assets/constantData/utils';
import {
  resetHistoryModuleCase,
  setHistoryFilterValues,
  setHistoryLiveFilter,
  setIsUnitDisabledHistory,
} from '../../store/reducer/historyLogs';
import { fetchAllHistoryFilterValues } from '../../services/actions/historyLogs';

const CustomTooltip = withStyles({
  tooltip: {
    fontSize: '14px',
  },
})(Tooltip);
export const HistoryDropdownCheck = ({ options, title }) => {
  const [data, setData] = React.useState([]);
  const isAllSelected = options.length > 0 && data?.length === options.length;
  const classes = useStyles();
  const dispatch = useDispatch();
  const dataToSend = useSelector((state) => state.historyLogs.dataToSend);
  const isFilterApplied = useSelector((state) => state.historyLogs.isFilterApplied);

  const isModuleDisabledHistory = useSelector((state) => state.historyLogs.isModuleDisabledHistory);
  const isUnitDisabledHistory = useSelector((state) => state.historyLogs.isUnitDisabledHistory);
  React.useEffect(() => {
    if (!isFilterApplied) setData([]);
  }, [isFilterApplied]);

  React.useEffect(() => {
    if (dataToSend.module.length === 0 && title === 'module') setData([]);
    if (dataToSend.unit.length === 0 && title === 'unit') setData([]);
  }, [dataToSend.module, dataToSend.unit]);

  React.useEffect(() => {
    if (isModuleDisabledHistory && title === 'module')
      dispatch(setHistoryFilterValues([title, []]));
    if (isUnitDisabledHistory && title === 'unit') dispatch(setHistoryFilterValues([title, []]));
  }, [isModuleDisabledHistory, isUnitDisabledHistory]);
  const handleChange = (event) => {
    let {
      target: { value },
    } = event;
    if (value[value.length - 1] === 'all') {
      value = data.length === options.length ? [] : options;
    }
    switch (title) {
      case 'module': {
        if (value.length === 0) {
          dispatch(setIsUnitDisabledHistory(true));
          dispatch(resetHistoryModuleCase());
          setData(value);
          dispatch(setHistoryLiveFilter([title, value]));
          return;
        }
        dispatch(
          fetchAllHistoryFilterValues({
            subject: dataToSend.subject,
            keyStage: dataToSend.keyStage,
            course: dataToSend.course,
            module: value,
            unit: [],
          })
        );
        dispatch(setIsUnitDisabledHistory(false));
        dispatch(resetHistoryModuleCase());
        break;
      }
      default:
    }
    setData(value);
    dispatch(setHistoryLiveFilter([title, value]));
  };
  const iff = (condition, then, otherwise) => (condition ? then : otherwise);
  const checkDisable = () => {
    switch (title) {
      case 'module':
        return isModuleDisabledHistory;
      case 'unit':
        return isUnitDisabledHistory;
      default:
        return false;
    }
  };
  return (
    <FormControl sx={{ width: '100%' }} size="small" id="multiple-select-label">
      <Select
        data-testid="select-history"
        sx={{ maxHeight: '40px', maxWidth: '200px' }}
        multiple
        value={data}
        onChange={handleChange}
        input={<OutlinedInput size="small" />}
        renderValue={(value) =>
          iff(value?.length, iff(Array.isArray(value), value.join(', '), value), 'Show All')
        }
        inputProps={{ 'data-testid': 'selecthandleChange' }}
        size="small"
        margin="dense"
        placeholder="Select"
        MenuProps={MenuProps}
        disabled={checkDisable()}
        displayEmpty
      >
        <MenuItem
          value="all"
          classes={{
            root: isAllSelected ? classes.selectedAll : '',
          }}
        >
          <ListItemIcon>
            <Checkbox
              sx={{ padding: 0 }}
              classes={{ indeterminate: classes.indeterminateColor }}
              checked={isAllSelected}
              indeterminate={data?.length > 0 && data.length < options.length}
            />
          </ListItemIcon>
          Select All
        </MenuItem>
        {options.map((name) =>
          name.length > 50 ? (
            <CustomTooltip title={name} placement="left" arrow>
              <MenuItem key={name} value={name} sx={{ whiteSpace: 'normal' }}>
                <ListItemIcon>
                  <Checkbox sx={{ padding: 0 }} checked={data.indexOf(name) > -1} />
                </ListItemIcon>
                {name}
              </MenuItem>
            </CustomTooltip>
          ) : (
            <MenuItem key={name} value={name} sx={{ whiteSpace: 'normal' }}>
              <ListItemIcon>
                <Checkbox sx={{ padding: 0 }} checked={data.indexOf(name) > -1} />
              </ListItemIcon>
              {name}
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
};

HistoryDropdownCheck.propTypes = {
  options: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  title: PropTypes.string.isRequired,
};
