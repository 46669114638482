/* eslint-disable prefer-destructuring */
import { setGameNewFilterOptions } from '../../store/reducer/gameDb';
import {
  setGameFilterValues,
  setLiveFilter,
  setOriginalVocabList,
} from '../../store/reducer/gameDevelopment';
import { setBackdrop } from '../../store/reducer/uploadFile';
import mflConfig from '../../utils/config';
import { post } from '../../utils/request-axios';

export const fetchAllGameFilterValues = (appliedFilters) => async (dispatch) => {
  dispatch(setBackdrop(true));
  const response = await post(`${mflConfig.API_URL}filter`, appliedFilters);
  let label;
  let options;
  if (typeof response?.data?.subject?.data === 'object') {
    label = Object.keys(response?.data)[0]; // subject
    options = Object.values(response?.data?.subject)[0]; // []
  } else {
    label = Object.keys(response?.data)[0]; // subject
    options = Object.values(response?.data)[0]; // ["french", "german"]
  }
  dispatch(setGameFilterValues([label, options]));
  dispatch(setBackdrop(false));
};

export const fetchAllNewGameFilterValues = (appliedNewFilters) => async (dispatch) => {
  dispatch(setBackdrop(true));
  const response = await post(`${mflConfig.API_URL}filter`, appliedNewFilters);
  let label;
  let options;
  if (typeof response?.data?.subject?.data === 'object') {
    label = Object.keys(response?.data)[0]; // subject
    options = Object.values(response?.data?.subject)[0]; // []
  } else {
    label = Object.keys(response?.data)[0]; // subject
    options = Object.values(response?.data)[0]; // ["french", "german"]
  }
  dispatch(setGameNewFilterOptions([label, options]));
  dispatch(setBackdrop(false));
};

export const pushLiveFilterAction =
  ([data, title]) =>
  async (dispatch) => {
    switch (title) {
      case 'taskAction':
        break;
      case 'gameMechanism':
        break;
      case 'theme':
        break;
      case 'status':
        break;
      default:
        dispatch(setLiveFilter([data, title]));
        break;
    }
  };

export const fetchfilteredVocabList = (liveFilter, selectedVocabList) => async (dispatch) => {
  dispatch(setBackdrop(true));
  const response = await post(`${mflConfig.API_URL}language/?$paginate=false`, liveFilter);
  const filteredArray = response?.data?.data.filter(
    (arrayel) =>
      selectedVocabList.filter((anotherOneel) => anotherOneel.id === arrayel.id).length === 0
  );
  dispatch(setBackdrop(false));
  dispatch(setOriginalVocabList(filteredArray));
};
