import {
  Checkbox,
  FormControl,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import '../assets/styles/DropDown.sass';
import { withStyles } from '@mui/styles';
import { MenuProps, useStyles } from '../assets/constantData/utils';
import { pushSelected } from '../services/actions/filterApply';
import { fetchAllFiltersNew } from '../services/actions/filterSelection';
import { resetModuleCase, setFilterValues, setIsUnitSelected } from '../store/reducer/filterApply';

const CustomTooltip = withStyles({
  tooltip: {
    fontSize: '14px',
  },
})(Tooltip);
function DropDown({ filterOptions, keys }) {
  const [selected, setSelected] = useState([]);
  const isFilterApplied = useSelector((state) => state.languageDatabase.isFilterApplied);
  const selectedStore = useSelector((states) => states.filterApply.appliedFilters[keys]);
  const appliedFilters = useSelector((states) => states.filterApply.appliedFilters);
  const isModuleSelected = useSelector((states) => states.filterApply.isModuleSelected);
  const isUnitSelected = useSelector((states) => states.filterApply.isUnitSelected);

  useEffect(() => {
    if (!isFilterApplied) setSelected([]);
    else {
      setSelected(selectedStore?.length > 0 ? selectedStore : selected);
    }
  }, [isFilterApplied]);

  React.useEffect(() => {
    if (appliedFilters.module.length === 0 && keys === 'module') setSelected([]);
    if (appliedFilters.unit.length === 0 && keys === 'unit') setSelected([]);
  }, [appliedFilters.module, appliedFilters.unit]);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isModuleSelected && keys === 'module') dispatch(setFilterValues([keys, []]));
    if (isUnitSelected && keys === 'unit') dispatch(setFilterValues([keys, []]));
  }, [isModuleSelected, isUnitSelected]);

  const classes = useStyles();
  const isAllSelected = filterOptions.length > 0 && selected?.length === filterOptions.length;
  const handleChange = (event) => {
    let {
      target: { value },
    } = event;
    if (value[value.length - 1] === 'all') {
      value = selected.length === filterOptions.length ? [] : filterOptions;
    }
    switch (keys) {
      case 'module': {
        if (value.length === 0) {
          setSelected(value);
          dispatch(pushSelected(value, keys));
          dispatch(setIsUnitSelected(true));
          dispatch(resetModuleCase());
          return;
        }
        dispatch(
          fetchAllFiltersNew({
            subject: appliedFilters.subject,
            keyStage: appliedFilters.keyStage,
            course: appliedFilters.course,
            module: value,
            unit: [],
          })
        );
        dispatch(setIsUnitSelected(false));
        dispatch(resetModuleCase());
        break;
      }
      default:
    }
    setSelected(value);
    dispatch(pushSelected(value, keys));
  };
  const iff = (condition, then, otherwise) => (condition ? then : otherwise);
  return (
    <FormControl className={classes.formControl} id="mutiple-select-label">
      <Select
        data-testid="trigger-change"
        sx={{
          height: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '14px',
          width: '195px',
        }}
        labelId="mutiple-select-label"
        multiple
        variant="outlined"
        size="small"
        margin="dense"
        displayEmpty
        disabled={keys === 'module' ? isModuleSelected : isUnitSelected}
        inputProps={{ 'data-testid': 'content-input' }}
        value={selected}
        onChange={handleChange}
        renderValue={(value) =>
          iff(value?.length, iff(Array.isArray(value), value?.join(', '), value), 'Show All')
        }
        MenuProps={MenuProps}
        input={<OutlinedInput />}
        placeholder="Select"
      >
        <MenuItem
          value="all"
          classes={{
            root: isAllSelected ? classes.selectedAll : '',
          }}
        >
          <ListItemIcon>
            <Checkbox
              sx={{ padding: 0 }}
              classes={{ indeterminate: classes.indeterminateColor }}
              checked={isAllSelected}
              indeterminate={selected?.length > 0 && selected.length < filterOptions.length}
            />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.selectAllText }} primary="Select All" />
        </MenuItem>
        {filterOptions.map((option) =>
          option.length > 50 ? (
            <CustomTooltip title={option} placement="left" arrow>
              <MenuItem key={option} value={option} sx={{ whiteSpace: 'normal' }}>
                <ListItemIcon>
                  <Checkbox checked={selected?.includes(option)} sx={{ padding: 0 }} />
                </ListItemIcon>
                <ListItemText primary={option.title}>{option}</ListItemText>
              </MenuItem>
            </CustomTooltip>
          ) : (
            <MenuItem
              key={option}
              value={option}
              sx={{
                whiteSpace: 'normal',
              }}
            >
              <ListItemIcon>
                <Checkbox checked={selected?.includes(option)} sx={{ padding: 0 }} />
              </ListItemIcon>
              <ListItemText primary={option.title}>{option}</ListItemText>
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
}

DropDown.propTypes = {
  filterOptions: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  keys: PropTypes.string.isRequired,
};

export default DropDown;
