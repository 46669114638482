import axios from 'axios';
import { setSuccess } from '../store/reducer/authenticate';
import store from '../store/store';
import mflConfig from './config';

export const logout = async () => {
  const currentUrl = window.location.href;
  const logoutUrl = mflConfig.LOGOUT_URL + encodeURIComponent(currentUrl);
  window.location.href = logoutUrl;
};

export const getCookie = (name) => {
  let value = ` ${document.cookie}`;
  let cStart = value.indexOf(` ${name}=`);
  if (cStart === -1) {
    value = '';
  } else {
    cStart = value.indexOf('=', cStart) + 1;
    let cEnd = value.indexOf(';', cStart);
    if (cEnd === -1) {
      cEnd = value.length;
    }
    value = unescape(value.substring(cStart, cEnd));
  }
  return value;
};
export const redirect = () => {
  const currentUrl = window.location.href;
  if (
    !(
      window.location.origin.includes('dev') ||
      window.location.origin.includes('staging') ||
      window.location.origin.includes('uat') ||
      window.location.origin.includes('qa') ||
      window.location.origin.includes('test') ||
      window.location.origin.includes('perf') ||
      window.location.origin.includes('stg')
    )
  ) {
    mflConfig.MYCLOUD_URL = 'https://mycloud.pearson.com/redirect?url=';
  }
  const redirectUrl = mflConfig.MYCLOUD_URL + encodeURIComponent(currentUrl);
  window.location.replace(redirectUrl);
};
export const setConfigKeys = (newObj) => {
  /* jshint expr: true */
  if (newObj) {
    Object.keys(newObj).forEach((key) => {
      mflConfig[key] = newObj[key];
      console.log(key, 'key');
    });
  }
};

/* eslint no-return-await: "error" */
export const setEnvConfig = async (callbackFn) => {
  console.log(process.env);
  const enviroment = window.location.hostname;
  // eslint-disable-next-line consistent-return
  switch (enviroment) {
    case 'qa-vocabgamesauthor.pearson.com':
      mflConfig.API_URL = 'https://backend-qa.vocabgamesauthor.pearsondev.tech/';
      break;
    case 'uat-vocabgamesauthor.pearson.com':
      mflConfig.API_URL = 'https://backend-uat.vocabgamesauthor.pearsondev.tech/';
      break;
    case 'vocabgamesauthor.pearson.com':
      mflConfig.API_URL = 'https://backend.vocabgamesauthor.pearsonprd.tech/';
      break;
    default:
      break;
  }
  await axios
    .get(`${mflConfig.API_URL}get-config/`, {
      headers: {
        'Content-Type': 'application/json',
        mycloudproxysession: mflConfig.SSO_TOKEN,
      },
    })
    .then((response) => {
      if (response && response.status === 200) {
        store.dispatch(setSuccess(true));
        callbackFn(response.data);
      } else {
        console.log('response status issue');
      }
    })
    .catch((error) => {
      console.log('Error in fetching origin:', error);
    });
};
export const GetEnvConfig = async () => {
  const cookieSSO = getCookie('myCloudProxySession');
  if (!window.location.origin.includes('localhost')) {
    if (!cookieSSO) {
      redirect();
      return;
    }
  }
  mflConfig.SSO_TOKEN = getCookie('myCloudProxySession') || mflConfig.SSO_TOKEN;
  // eslint-disable-next-line consistent-return
  await setEnvConfig(setConfigKeys);
  // eslint-disable-next-line consistent-return
  return true;
};
