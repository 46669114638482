import { Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetLevelFields } from '../../../services/actions/gameDevelop';
import { DifficultyLevels } from './DifficultyLevels';
import { DropdownContainer } from './DropdownContainer';
import { DropdownWithLabel } from '../../custom/DropdownWithLabel';
import { CustomTextField } from '../../custom/CustomTextField';
import {
  NUMBER_OF_ROUND,
  QUESTION_PER_ROUND,
  ATTEMPT_PER_QUESTION,
  NUMERICAL_ARRAY,
  LEVEL_DROPDOWN_VALUES,
  LEVEL_PROMPT_VALUES,
  TASK_ACTION_KEYS,
  TASK_ACTION_VALUES,
  GAME_MECHANICS_KEYS,
  GAME_MECHANICS_VALUES,
  GAME_THEME_KEYS,
  GAME_THEME_VALUES,
  GAME_FUNCTIONALITY_KEYS,
  GAME_FUNCTIONALITY_VALUES,
  DIFFICULTY_LEVELS_KEYS,
  DIFFICULTY_LEVELS_VALUES,
} from '../../../assets/constantData/gameConstant';
import { setSingleGameDetails } from '../../../store/reducer/gameDevelop';

export const BottomWrapper = () => {
  const dispatch = useDispatch();
  const noOfDifficultyLevels = useSelector(
    (state) => state?.gameDevelop?.dropdowns?.dificultyLevel
  );
  const { dropdowns } = useSelector((state) => state?.gameDevelop);
  useEffect(() => {
    dispatch(resetLevelFields(noOfDifficultyLevels));
  }, [noOfDifficultyLevels]);

  const handleQuestionOfRounds = (e) => {
    let value = parseInt(e.target.value, 10);
    if (value > 20) value = 20;
    if (value < 1) value = 1;
    const shallowDropdown = JSON.parse(JSON.stringify(dropdowns));
    shallowDropdown.questionPerRound = value;
    dispatch(setSingleGameDetails(shallowDropdown));
  };
  const handleNumberOfRounds = (e) => {
    let value = parseInt(e.target.value, 10);
    if (value > 30) value = 30;
    if (value < 1) value = 1;
    const shallowDropdown = JSON.parse(JSON.stringify(dropdowns));
    shallowDropdown.numberOfRound = value;
    dispatch(setSingleGameDetails(shallowDropdown));
  };

  return (
    <Grid container paddingX={4}>
      <Grid display="flex" justifyContent="flex-start" paddingBottom={1}>
        <Grid item>
          <Typography variant="h5">Selecting Task Action</Typography>
        </Grid>
      </Grid>
      <DropdownContainer
        type="triple"
        dropdownLabelValue={TASK_ACTION_KEYS}
        dropdownItemValues={TASK_ACTION_VALUES}
      />
      <Grid display="flex" justifyContent="flex-start" paddingBottom={1}>
        <Grid item>
          <Typography variant="h5">Selecting Game Mechanics</Typography>
        </Grid>
      </Grid>
      <DropdownContainer
        type="single"
        dropdownLabelValue={GAME_MECHANICS_KEYS}
        dropdownItemValues={GAME_MECHANICS_VALUES}
      />
      <Grid display="flex" justifyContent="flex-start" paddingBottom={1}>
        <Grid item>
          <Typography variant="h5">Selecting Game Theme</Typography>
        </Grid>
      </Grid>
      <DropdownContainer
        type="double"
        dropdownLabelValue={GAME_THEME_KEYS}
        dropdownItemValues={GAME_THEME_VALUES}
      />
      <Grid display="flex" justifyContent="flex-start" paddingBottom={1}>
        <Grid item>
          <Typography variant="h5">Selecting Game Functionality</Typography>
        </Grid>
      </Grid>
      <Grid container display="flex" justifyContent="flex-start" paddingBottom={1}>
        <Grid item xs={3}>
          <CustomTextField labelName={NUMBER_OF_ROUND} onChange={(e) => handleNumberOfRounds(e)} />
        </Grid>
        <Grid item xs={3}>
          <CustomTextField
            labelName={QUESTION_PER_ROUND}
            typeOfField
            onChange={(e) => handleQuestionOfRounds(e)}
          />
        </Grid>
        <Grid item xs={3}>
          <DropdownWithLabel labelName={ATTEMPT_PER_QUESTION} dropdownValues={NUMERICAL_ARRAY} />
        </Grid>
      </Grid>
      <Grid container>
        <DropdownContainer
          type="double"
          dropdownLabelValue={GAME_FUNCTIONALITY_KEYS}
          dropdownItemValues={GAME_FUNCTIONALITY_VALUES}
        />
      </Grid>
      <Grid display="flex" justifyContent="flex-start" paddingBottom={1}>
        <Grid item>
          <Typography variant="h5">Selecting Difficulty Level</Typography>
        </Grid>
      </Grid>
      <DropdownContainer
        type="single"
        dropdownLabelValue={DIFFICULTY_LEVELS_KEYS}
        dropdownItemValues={DIFFICULTY_LEVELS_VALUES}
      />
      {(noOfDifficultyLevels === '1' ||
        noOfDifficultyLevels === '2' ||
        noOfDifficultyLevels === '3') && (
        <DifficultyLevels
          labelTextfield="Level 1 Name"
          levelNumber="level1"
          upperDropdownValues={LEVEL_DROPDOWN_VALUES}
          promptValues={LEVEL_PROMPT_VALUES}
        />
      )}

      {(noOfDifficultyLevels === '2' || noOfDifficultyLevels === '3') && (
        <DifficultyLevels
          labelTextfield="Level 2 Name"
          levelNumber="level2"
          upperDropdownValues={LEVEL_DROPDOWN_VALUES}
          promptValues={LEVEL_PROMPT_VALUES}
        />
      )}

      {noOfDifficultyLevels === '3' && (
        <DifficultyLevels
          labelTextfield="Level 3 Name"
          levelNumber="level3"
          upperDropdownValues={LEVEL_DROPDOWN_VALUES}
          promptValues={LEVEL_PROMPT_VALUES}
        />
      )}
    </Grid>
  );
};
