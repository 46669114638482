import { PropTypes } from 'prop-types';
import { Grid } from '@mui/material';
import { DropdownWithLabel } from '../../custom/DropdownWithLabel';
import { DIFFICULTY_LEVELS_KEYS } from '../../../assets/constantData/gameConstant';

export const DropdownContainer = ({ type, dropdownLabelValue, dropdownItemValues }) => (
  <Grid
    container
    columnSpacing={2}
    paddingBottom={dropdownLabelValue[0] === DIFFICULTY_LEVELS_KEYS[0] ? 2 : 4}
  >
    {type === 'single' && (
      <Grid item xs={3}>
        <DropdownWithLabel
          labelName={dropdownLabelValue[0]}
          dropdownValues={dropdownItemValues[0]}
        />
      </Grid>
    )}

    {type === 'double' && (
      <>
        <Grid item xs={3}>
          <DropdownWithLabel
            labelName={dropdownLabelValue[0]}
            dropdownValues={dropdownItemValues[0]}
          />
        </Grid>
        <Grid item xs={3}>
          <DropdownWithLabel
            labelName={dropdownLabelValue[1]}
            dropdownValues={dropdownItemValues[1]}
          />
        </Grid>
      </>
    )}
    {type === 'triple' && (
      <>
        <Grid item xs={3}>
          <DropdownWithLabel
            labelName={dropdownLabelValue[0]}
            dropdownValues={dropdownItemValues[0]}
          />
        </Grid>
        <Grid item xs={3}>
          <DropdownWithLabel
            labelName={dropdownLabelValue[1]}
            dropdownValues={dropdownItemValues[1]}
          />
        </Grid>
        <Grid item xs={3}>
          <DropdownWithLabel
            labelName={dropdownLabelValue[2]}
            dropdownValues={dropdownItemValues[2]}
          />
        </Grid>
      </>
    )}
  </Grid>
);

DropdownContainer.propTypes = {
  type: PropTypes.string.isRequired,
  dropdownLabelValue: PropTypes.arrayOf(PropTypes.any.isRequired),
  dropdownItemValues: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
};

DropdownContainer.defaultProps = {
  dropdownLabelValue: [],
};
