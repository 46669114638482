export const headCellsData = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Vocab ID',
  },
  {
    id: 'calories',
    numeric: true,
    disablePadding: false,
    label: 'Sub Group',
  },
  {
    id: 'fat',
    numeric: true,
    disablePadding: false,
    label: 'Vocab Type',
  },
  {
    id: 'carbs',
    numeric: true,
    disablePadding: false,
    label: 'Item 1',
  },
  {
    id: 'protein',
    numeric: true,
    disablePadding: false,
    label: 'Item 2',
  },
  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    label: 'Actions',
  },
];

export const rowsData = [
  {
    name: '1',
    id: 1834,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '2',
    id: 2,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '3',
    id: 3,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '4',
    id: 4,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '5',
    id: 5,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '6',
    id: 6,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '7',
    id: 7,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '8',
    id: 8,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '9',
    id: 9,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '10',
    id: 10,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '11',
    id: 11,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '12',
    id: 12,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '13',
    id: 13,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '14',
    id: 14,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '15',
    id: 15,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '16',
    id: 16,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '17',
    id: 17,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '18',
    id: 18,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '19',
    id: 19,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '20',
    id: 20,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '21',
    id: 21,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '22',
    id: 22,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '23',
    id: 23,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '24',
    id: 24,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '25',
    id: 25,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '26',
    id: 26,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '27',
    id: 27,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '28',
    id: 28,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '29',
    id: 29,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '30',
    id: 30,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '30',
    id: 30,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '31',
    id: 31,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '32',
    id: 32,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '33',
    id: 33,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '34',
    id: 34,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '35',
    id: 35,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '36',
    id: 36,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '37',
    id: 37,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '38',
    id: 38,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '39',
    id: 39,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '40',
    id: 40,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '40',
    id: 40,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '41',
    id: 41,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '42',
    id: 42,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '43',
    id: 43,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '44',
    id: 44,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '45',
    id: 45,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '46',
    id: 46,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '47',
    id: 47,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '48',
    id: 48,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '49',
    id: 49,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
  {
    name: '50',
    id: 50,
    calories: 'French',
    fat: 3,
    carbs: 'Dynamo 1',
    protein: 'Module 2 - En Classe',
  },
];
