import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './assets/styles/App.sass';
import { Backdrop, CircularProgress, ThemeProvider } from '@mui/material';
import { useSelector } from 'react-redux';
import { MainScreen } from './components/languageDatabase/MainScreen';
import Dashboard from './components/dashboard/Dashboard';
import LanguageDatabaseDashboard from './components/dashboard/LanguageDatabaseDashboard';
import ViewRecord from './components/ViewRecord';
import { GameDashboard } from './components/game/list/GameDashboard';
import { theme } from './assets/styles/material-ui/themeProvider';
import { GetEnvConfig } from './utils/getEnvConfig';
import { GameDevelopment } from './components/game/list/GameDevelopment';
import HistoryLogs from './components/historyLogs/HistoryLogs';
import { ErrorLogs } from './components/errorLogs/ErrorLogs';

function App() {
  const [env, setEnv] = useState(false);
  useEffect(() => {
    const getEnv = async () => {
      await GetEnvConfig();
      setEnv(true);
    };
    getEnv();
  }, []);
  console.log('Build version 2.0.79 ');
  const auth = useSelector((state) => state?.authenticate?.auth);
  const success = useSelector((state) => state?.authenticate?.success);
  const backdrop = useSelector((states) => states?.uploadFile?.backdrop);
  if (!env) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: 10000 }} open={backdrop}>
        <CircularProgress />
      </Backdrop>
    );
  }
  if (auth) {
    return (
      <div
        style={{
          background: 'gray',
          display: 'flex',
          justifyContent: 'center',
          width: '610px',
          margin: '0 auto',
          marginTop: '20px',
          padding: '10px 15px',
          borderRadius: '4px',
          border: '2px solid #b00020',
          backgroundColor: '#fffce0',
          color: '#d71212',
          fontSize: '13px',
          letterSpacing: '.5px',
        }}
      >
        You are an unauthorized user. Please contact the administrator
      </div>
    );
  }
  return (
    success && (
      <ThemeProvider theme={theme}>
        <div>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/gamemanagement" element={<h1>Game Management</h1>} />
              <Route path="/languagedatabase" element={<LanguageDatabaseDashboard />} />
              <Route path="/historylogs" element={<HistoryLogs />} />
              <Route path="/viewrecords" element={<MainScreen />} />
              <Route path="/view-record-detail/:id" element={<ViewRecord />} />
              <Route path="/games/list/" element={<GameDashboard />} />
              <Route path="/gameDevelopment" element={<GameDevelopment title="Create New" />} />
              <Route path="/gameDevelopment/:id" element={<GameDevelopment title="edit" />} />
              <Route
                path="/gameDevelopment/:id/:clone"
                element={<GameDevelopment title="clone" />}
              />
              <Route path="/errorLogs" element={<ErrorLogs />} />
            </Routes>
          </BrowserRouter>
        </div>
      </ThemeProvider>
    )
  );
}

export default App;
