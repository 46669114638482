import { Button, Typography } from '@mui/material';
import '../../assets/styles/ButtonCustom.sass';
import PropTypes from 'prop-types';

export const ButtonCustom = ({
  valueProp,
  variantProp,
  classProp,
  typoClassProp,
  onClick,
  customAttribute,
}) => (
  <Button
    className={classProp}
    variant={variantProp}
    onClick={onClick}
    size="small"
    {...customAttribute}
  >
    <Typography className={typoClassProp}>{valueProp}</Typography>
  </Button>
);

ButtonCustom.propTypes = {
  valueProp: PropTypes.string.isRequired,
  variantProp: PropTypes.string.isRequired,
  classProp: PropTypes.string,
  typoClassProp: PropTypes.string,
  onClick: PropTypes.func,
  customAttribute: PropTypes.objectOf(PropTypes.any.isRequired),
};

ButtonCustom.defaultProps = {
  classProp: '',
  typoClassProp: '',
  onClick: () => {},
  customAttribute: {},
};
