import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dropdowns: {
    name: '',
    vocabOrder: '',
    selectedVocabList: '',
    taskAction: '',
    questionLangulage: '',
    answerLangulage: '',
    gameMechanics: '',
    theme: '',
    subTheme: '',
    numberOfRound: 1,
    questionPerRound: 10,
    attemptPerQuestion: null,
    caseSensitivity: '',
    typeOfKeyboard: '',
    dificultyLevel: '',
    levelName1: '',
    numberOfDistractor: null,
    sourceDistractor: '',
    numberOfMissingWord: '',
    imagePrompt: '',
    audioPrompt: '',
    textPrompt: '',
    levelName2: '',
    numberOfDistractor2: null,
    sourceDistractor2: '',
    numberOfMissingWord2: '',
    imagePrompt2: '',
    audioPrompt2: '',
    textPrompt2: '',
    levelName3: '',
    numberOfDistractor3: null,
    sourceDistractor3: '',
    numberOfMissingWord3: '',
    imagePrompt3: '',
    audioPrompt3: '',
    textPrompt3: '',
  },
  emptyAlert: false,
  editFlag: false,
  cloneFlag: false,
  singleGameDetails: {},
  selectedVocabFromResponse: [],
  statusGame: '',
  itemInVocablist: 0,
  checkCloneName: '',
  cloneAlert: '',
  responseFlag: false,
  responseFlagAttempt: false,
  showVocab: false,
  errorFocusFlag: false,
};

const gameDevelop = createSlice({
  name: 'gameDevelop',
  initialState,
  reducers: {
    setConfirmedVocabList: (state, action) => {
      state.dropdowns.selectedVocabList = action?.payload;
    },
    setValue: (state, { payload: [labelChanged, selectedValue] }) => {
      state.dropdowns[labelChanged] = selectedValue;
    },
    setLevelName: (state, { payload: [levelNumber, levelName] }) => {
      state.dropdowns[levelNumber] = levelName;
    },
    setLevelDropdown: (state, { payload: [labelChanged, selectedValue] }) => {
      state.dropdowns[labelChanged] = selectedValue;
    },
    resetLevelField2: (state) => {
      state.dropdowns.levelName2 = initialState.dropdowns.levelName2;
      state.dropdowns.numberOfDistractor2 = initialState.dropdowns.numberOfDistractor2;
      state.dropdowns.sourceDistractor2 = initialState.dropdowns.sourceDistractor2;
      state.dropdowns.numberOfMissingWord2 = initialState.dropdowns.numberOfMissingWord2;
      state.dropdowns.imagePrompt2 = initialState.dropdowns.imagePrompt2;
      state.dropdowns.audioPrompt2 = initialState.dropdowns.audioPrompt2;
      state.dropdowns.textPrompt2 = initialState.dropdowns.textPrompt2;
    },
    resetLevelField3: (state) => {
      state.dropdowns.levelName3 = initialState.dropdowns.levelName3;
      state.dropdowns.numberOfDistractor3 = initialState.dropdowns.numberOfDistractor3;
      state.dropdowns.sourceDistractor3 = initialState.dropdowns.sourceDistractor3;
      state.dropdowns.numberOfMissingWord3 = initialState.dropdowns.numberOfMissingWord3;
      state.dropdowns.imagePrompt3 = initialState.dropdowns.imagePrompt3;
      state.dropdowns.audioPrompt3 = initialState.dropdowns.audioPrompt3;
      state.dropdowns.textPrompt3 = initialState.dropdowns.textPrompt3;
    },
    setGameName: (state, { payload: value }) => {
      state.dropdowns.name = value;
    },
    setEmptyAlert: (state, action) => {
      state.emptyAlert = action?.payload;
    },
    resetData: (state) => {
      state.dropdowns = initialState.dropdowns;
      state.itemInVocablist = initialState.itemInVocablist;
    },
    setEditFlag: (state, action) => {
      state.editFlag = action.payload;
    },
    setSingleGameDetails: (state, { payload: response }) => {
      state.dropdowns = response;
    },
    setEditGameDetails: (state, { payload: response }) => {
      state.dropdowns = response;
      state.dropdowns.selectedVocabList = initialState?.dropdowns?.selectedVocabList;
    },
    setSelectedVocabsResponse: (state, { payload: selectedVocabs }) => {
      state.selectedVocabFromResponse = selectedVocabs;
    },
    setStatusGame: (state, { payload: gameStatus }) => {
      state.statusGame = gameStatus;
    },
    setItemInVocabList: (state, action) => {
      state.itemInVocablist = action.payload;
    },
    setCloneFlag: (state, action) => {
      state.cloneFlag = action.payload;
    },
    setCheckCloneName: (state, action) => {
      state.checkCloneName = action.payload;
    },
    setCloneAlert: (state, action) => {
      state.cloneAlert = action.payload;
    },
    setResponseFlag: (state, action) => {
      state.responseFlag = action?.payload;
    },
    setAttemptResponseFlag: (state, action) => {
      state.responseFlagAttempt = action?.payload;
    },
    setShowVocab: (state, action) => {
      state.showVocab = action?.payload;
    },
    setErrorFocusFlag: (state, action) => {
      state.errorFocusFlag = action?.payload;
    },
    resetAll: () => initialState,
  },
});
export const {
  setConfirmedVocabList,
  setValue,
  setLevelName,
  setLevelDropdown,
  resetLevelField2,
  resetLevelField3,
  setGameName,
  setEmptyAlert,
  resetData,
  setEditFlag,
  setSingleGameDetails,
  setEditGameDetails,
  setSelectedVocabsResponse,
  setStatusGame,
  setItemInVocabList,
  setCloneFlag,
  setCheckCloneName,
  setCloneAlert,
  setResponseFlag,
  setAttemptResponseFlag,
  setShowVocab,
  setErrorFocusFlag,
  resetAll,
} = gameDevelop.actions;
export default gameDevelop.reducer;
