import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import '../../assets/styles/LangTable.sass';
import { useNavigate } from 'react-router-dom';
import { Box, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { ButtonCustom } from '../custom/ButtonCustom';
import { setSelected } from '../../store/reducer/languageDatabase';

function descendingComparator(a, b, orderBy) {
  if (orderBy === '') {
    return 0;
  }
  if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
    return -1;
  }
  if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const handleFilterData = (key) => {
  switch (key) {
    case 'Vocab ID':
      return 'vocabId';
    case 'Sub Group':
      return 'subGroup';
    case 'Vocab Type':
      return 'vocabType';
    case 'Item 1':
      return 'item1';
    case 'Item 2':
      return 'item2';
    default:
      return null;
  }
};
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, onSelectAllClick, numSelected, rowCount, headCellsData } =
    props;
  const createSortHandler = (property) => (event) => {
    const data = handleFilterData(property);
    onRequestSort(event, data);
  };
  return (
    <TableHead>
      <TableRow className="table">
        <TableCell
          padding="checkbox"
          sx={{ overflowWrap: 'anywhere', width: '100px' }}
          data-testid="checkboxParent"
        >
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
              'data-testid': 'checkbox',
            }}
          />
        </TableCell>
        {headCellsData.map((headCell, idx) =>
          headCell.label !== 'Actions' ? (
            <TableCell
              sx={{ width: '100px' }}
              padding={idx === 0 ? 'none' : 'normal'}
              key={headCell.id}
              align="left"
              className="headcell"
            >
              <TableSortLabel
                inputProps={{ 'data-testid': 'sortLabel' }}
                active={orderBy === headCell}
                direction={orderBy === handleFilterData(headCell.label) ? order : 'desc'}
                onClick={createSortHandler(headCell.label)}
              >
                {headCell.label}

                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell
              sx={{ width: '100px' }}
              padding={idx === 0 ? 'none' : 'normal'}
              key={headCell.id}
              align="left"
              className="headcell"
            >
              Actions
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCellsData: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  onRequestSort: PropTypes.func.isRequired,
};

export const LangTable = ({ headCellsData, rowsData }) => {
  const dispatch = useDispatch();
  const { selected } = useSelector((states) => states.languageDatabase);
  const navigate = useNavigate();
  const [order, setOrder] = React.useState('');
  const [orderBy, setOrderBy] = React.useState('');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rowsData.map((n) => n.id);
      dispatch(setSelected(newSelected));
      return;
    }
    dispatch(setSelected([]));
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    switch (true) {
      case selectedIndex === -1:
        newSelected = newSelected.concat(selected, id);
        break;
      case selectedIndex === 0:
        newSelected = newSelected.concat(selected.slice(1));
        break;
      case selectedIndex === selected.length - 1:
        newSelected = newSelected.concat(selected.slice(0, -1));
        break;
      case selectedIndex > 0:
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
        break;
      default:
        break;
    }
    dispatch(setSelected(newSelected));
  };

  const toViewPage = (rowId) => {
    navigate(`/view-record-detail/${rowId}`);
  };
  const isSelected = (id) => selected.indexOf(id) !== -1;
  return (
    <Paper sx={{ width: '100%', mb: 2 }}>
      <TableContainer>
        <Table aria-labelledby="tableTitle" size="small" sx={{ minWidth: 750 }}>
          <EnhancedTableHead
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={rowsData.length}
            headCellsData={headCellsData}
            onRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy}
          />
          <TableBody>
            {stableSort(rowsData, getComparator(order, orderBy)).map((row) => {
              const isItemSelected = isSelected(row.id);
              const labelId = `enhanced-table-checkbox-${row.id}`;
              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={labelId}
                  selected={isItemSelected}
                >
                  <TableCell padding="checkbox" sx={{ maxWidth: '100px' }}>
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        'aria-labelledby': labelId,
                        'data-testid': row.name,
                      }}
                      onClick={(event) => handleClick(event, row.id)}
                    />
                  </TableCell>
                  <TableCell
                    component="th"
                    align="left"
                    id={labelId}
                    scope="row"
                    padding="none"
                    sx={{ minWidth: '100px' }}
                  >
                    {row.vocabId}
                  </TableCell>
                  <TableCell align="left" sx={{ minWidth: '100px' }}>
                    {row.subGroup}
                  </TableCell>
                  <TableCell align="left" sx={{ minWdth: '100px' }}>
                    {row.vocabType}
                  </TableCell>
                  <TableCell align="left" sx={{ minWidth: '250px' }}>
                    {row.item1}
                  </TableCell>
                  <TableCell align="left" sx={{ minWidth: '150px' }}>
                    {row.item2}
                  </TableCell>
                  <TableCell align="left">
                    <ButtonCustom
                      customAttribute={{ 'data-testid': row.calories + row.name }}
                      valueProp="View"
                      variantProp="outlined"
                      classProp="viewButton"
                      typoClassProp="buttonView"
                      onClick={() => toViewPage(row.id)}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

LangTable.propTypes = {
  headCellsData: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  rowsData: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
};
