import React, { useEffect } from 'react';
import { TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  setDataToSend,
  setFromDateSelected,
  setToDateSelected,
} from '../../store/reducer/historyLogs';

function DatePicker({ maxDate, title, minDate }) {
  const dispatch = useDispatch();
  const dataToSend = useSelector((state) => state.historyLogs.dataToSend);
  const isFilterApplied = useSelector((state) => state.historyLogs.isFilterApplied);
  const addZeroIfRequired = (val) => {
    if (val < 10) return `0${val}`;
    return val;
  };
  const [value, setValue] = React.useState(null);
  useEffect(() => {
    if (isFilterApplied === false) {
      setValue(null);
    }
  }, [isFilterApplied]);
  const handleChange = (newValue) => {
    setValue(new Date(newValue));
    if (title === 'fromDate') dispatch(setFromDateSelected(newValue));
    if (title === 'toDate') dispatch(setToDateSelected(newValue));
    dispatch(
      setDataToSend({
        ...dataToSend,
        [title]: `${newValue.$y}-${addZeroIfRequired(newValue.$M + 1)}-${addZeroIfRequired(
          newValue.$D
        )}`,
      })
    );
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        inputProps={{ 'data-testid': 'date' }}
        inputFormat="DD/MM/YYYY"
        value={value || null}
        onChange={handleChange}
        maxDate={maxDate}
        minDate={minDate}
        renderInput={(params) => <TextField {...params} size="small" />}
      />
    </LocalizationProvider>
  );
}

DatePicker.propTypes = {
  maxDate: PropTypes.instanceOf(Date).isRequired,
  minDate: PropTypes.instanceOf(Date).isRequired,
  title: PropTypes.string.isRequired,
};
export default DatePicker;
