import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ src, className }) => (
  <img
    src={src}
    alt="logo"
    style={className === 'pearson-logo-img' ? { height: '30px' } : { height: '100px' }}
  />
);

Logo.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Logo.defaultProps = {
  className: null,
};

export default Logo;
