import { React, useEffect } from 'react';
import { Box, InputLabel, Stack, Tooltip } from '@mui/material';
import '../../assets/styles/LangHeader.sass';
import '../../assets/styles/Dashboard.sass';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import exportFromJSON from 'export-from-json';
import { makeStyles } from '@mui/styles';
import DropDown from '../DropDown';
import { ButtonCustom } from '../custom/ButtonCustom';
import { SubHeader } from '../custom/SubHeader';
import { passingSelectedFilters, setPageNo } from '../../services/actions/filterApply';
import { deleteRecordsWithIds, fetchAllRecords } from '../../services/actions/languageDatabase';
import { fetchAllFiltersNew } from '../../services/actions/filterSelection';
import AlertDialog from '../custom/AlertDialog';
import { deleteRecords, setOpenAlert, setSelected } from '../../store/reducer/languageDatabase';
import { setBackdrop } from '../../store/reducer/uploadFile';
import { APPLY_FILTERS, CLEAR_ALL } from '../../assets/constantData/gameConstant';
import { LANGUAGE_FIELDS } from '../../assets/constantData/languageDatabase';
import { clearSearchString, resetFilters } from '../../store/reducer/filterApply';
import SingleDropdown from '../custom/SingleDropdown';

export const LangHeader = ({ headerTitle }) => {
  const useStyle = makeStyles(() => ({
    okayAlertButtonClass: {
      '&.MuiButtonBase-root': {
        backgroundColor: '#003057',
      },
      textTransform: 'capitalize',
    },
    cancelAlertButtonClass: {
      '&.MuiButtonBase-root': {
        color: '#333333',
        borderColor: '#333333',
      },
      textTransform: 'capitalize',
    },
  }));
  const classes = useStyle();
  const appliedFilters = useSelector((state) => state.filterApply.appliedFilters);
  const selectedFilters = useSelector((state) => state.filterApply.filterValues);
  const { selected, isFilterApplied, allData, openAlert } = useSelector(
    (state) => state.languageDatabase
  );
  const dispatch = useDispatch();
  const searchStrLength = useSelector(
    (states) => states?.filterApply?.appliedFilters?.searchStr.length
  );
  const exportCsv = () => {
    if (selected.length > 0) {
      const time = new Date();
      const fileName = `MFLExport_${time.toLocaleDateString('en-GB').replace(/\//g, '')}_${time
        .toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
        .replace(/(|\s+)/g, '')}`;
      const jsonData = selected.map((id) => allData.find((o) => o.id === id));
      const data = [];
      jsonData.forEach((element) => {
        const json = {};
        Object.keys(LANGUAGE_FIELDS).forEach((key) => {
          json[key] = element[key];
        });
        data.push(json);
      });
      const exportType = 'csv';
      exportFromJSON({
        data,
        fileName,
        exportType,
        fields: LANGUAGE_FIELDS,
        withBOM: true,
      });
      dispatch(setSelected([]));
    }
  };
  useEffect(() => {
    dispatch(fetchAllFiltersNew({ subject: [] }));
  }, []);

  const entries = Object.entries(selectedFilters);
  const lengthOfFilters =
    appliedFilters.subject.length ||
    appliedFilters.keyStage.length ||
    appliedFilters.course.length ||
    appliedFilters.module.length ||
    appliedFilters.unit.length ||
    appliedFilters.assets.length;
  const sendFilters = () => {
    if (lengthOfFilters && (searchStrLength >= 3 || searchStrLength === 0)) {
      dispatch(setPageNo());
      dispatch(passingSelectedFilters(appliedFilters, 0));
    }
  };

  const handleClearAll = () => {
    if (isFilterApplied) {
      dispatch(fetchAllRecords(0, true));
      dispatch(clearSearchString());
      dispatch(setSelected([]));
      dispatch(resetFilters());
    }
  };

  const okClick = async () => {
    dispatch(setOpenAlert(false));
    const selectedDup = selected;
    selected.map((id) => {
      dispatch(deleteRecords(id));
      return null;
    });
    dispatch(setBackdrop(true));
    await deleteRecordsWithIds(selectedDup);
    dispatch(setBackdrop(false));
    const count = selectedDup.length;
    if (count === allData.length) {
      dispatch(fetchAllRecords(0, true));
    }

    dispatch(setSelected([]));
  };

  const cancelClick = () => {
    dispatch(setOpenAlert(false));
  };
  return (
    <Box id="MainWrapper">
      <SubHeader sidemenu backBtn={false} title={headerTitle} searchBox />
      <Box className="boxFiltersList">
        {entries?.map(([key, value]) => (
          <Box key={key} className="innerSelectBox">
            <InputLabel id="mutiple-select-only-label">
              {key === 'keyStage' ? 'Key Stage' : key}
            </InputLabel>
            {key === 'subject' || key === 'keyStage' || key === 'course' || key === 'assets' ? (
              <SingleDropdown key={key} filterOptions={value} keys={key} />
            ) : (
              <DropDown key={key} filterOptions={value} keys={key} />
            )}
          </Box>
        ))}
      </Box>
      <Stack
        className="filterStack-2"
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Box>
          <ButtonCustom
            customAttribute={{ 'data-testid': 'sendFilters' }}
            typoClassProp="typoApplyButton"
            variantProp="contained"
            classProp="applyFilterButton"
            valueProp={APPLY_FILTERS}
            onClick={sendFilters}
          />

          <ButtonCustom
            typoClassProp="typoClearButton"
            classProp="clear-button"
            valueProp={CLEAR_ALL}
            onClick={handleClearAll}
            customAttribute={{
              sx: {
                marginLeft: '10px',
                textTransform: 'capitalize',
                textDecoration: 'underline',
              },
              'data-testid': 'clearAllLangHeader',
            }}
          />
        </Box>
        <Box>
          <Tooltip title="Export the csv file">
            <SystemUpdateAltIcon
              data-testid="exportcsv"
              className={selected.length > 0 ? 'enableExport' : 'disableExport'}
              onClick={exportCsv}
            />
          </Tooltip>
          <Tooltip title="Delete the record">
            <DeleteIcon
              data-testid="deleteIcon"
              className={selected.length > 0 ? 'enableExport' : 'disableExport'}
              onClick={() => {
                if (selected.length !== 0) dispatch(setOpenAlert(true));
              }}
            />
          </Tooltip>
          <AlertDialog
            okayTestId="okClick"
            cancelTestId="cancelClick"
            open={openAlert}
            contentText={`Are you sure you want to delete ${selected.length} record(s)?`}
            okBtn="confirm"
            cancelBtn="cancel"
            okClick={okClick}
            cancelClick={cancelClick}
            cancelButtonClass={classes.cancelAlertButtonClass}
            okayButtonClass={classes.okayAlertButtonClass}
            okayVariant="contained"
          />
        </Box>
      </Stack>
    </Box>
  );
};

LangHeader.propTypes = {
  headerTitle: PropTypes.string.isRequired,
};
