import { Paper, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import '../assets/styles/CardItem.sass';
import { useDispatch } from 'react-redux';
import { setSnackState } from '../store/reducer/snackbarReducer';
import { setAlertPopup, setUploadedFile } from '../store/reducer/uploadFile';

const CardItem = ({ title, Icon, onClick }) => {
  const dispatch = useDispatch();
  const handleUpload = (event) => {
    const file = event.target.files[0];
    if (file.name.endsWith('.csv')) {
      dispatch(setUploadedFile(file));
      dispatch(setAlertPopup(true));
    } else {
      dispatch(
        setSnackState({
          openSnackbar: true,
          message: 'Please select only csv file format',
          type: 'error',
        })
      );
    }
  };
  return (
    <div
      className="cardItem-container"
      data-testid="cardItemCont"
      onClick={onClick}
      aria-hidden="true"
    >
      {title === 'Upload File' && (
        <input
          type="file"
          id="upload-file"
          data-testid="uploadFile"
          accept="text/csv"
          hidden
          onChange={handleUpload}
          onClick={(event) => {
            event.target.value = null;
          }}
        />
      )}
      <Paper className="paper-style" elevation={10}>
        {Icon}
        <Typography className="card-heading" fontWeight={600}>
          {title}
        </Typography>
      </Paper>
    </div>
  );
};
CardItem.propTypes = {
  title: PropTypes.string.isRequired,
  Icon: PropTypes.element.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CardItem;
