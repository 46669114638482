import { Grid } from '@mui/material';
import React from 'react';
import { PropTypes } from 'prop-types';
import { FilterBox } from './FilterBox';

export const FilterContainer = ({ filters }) => (
  <Grid container columnSpacing={2} rowSpacing={1} marginTop={0.5}>
    {Object.entries(filters).map(([key, value]) => (
      <Grid key={key} item xs={8} sm={4} md={2}>
        <FilterBox title={key} options={value} />
      </Grid>
    ))}
  </Grid>
);

FilterContainer.propTypes = {
  filters: PropTypes.objectOf(PropTypes.arrayOf).isRequired,
};
