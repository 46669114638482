import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filters: {
    subject: [],
    keyStage: [],
    course: [],
    module: [],
    unit: [],
  },
  isFilterApplied: false,
  fromDateSelected: {},
  toDateSelected: {},
  list: [],
  columns: ['Action Taken', 'Fields Name', 'Vocab ID', 'Item 1', 'User ID', 'Date', 'Time'],
  dataToSend: {
    subject: [],
    course: [],
    keyStage: [],
    module: [],
    unit: [],
    fromDate: '',
    toDate: '',
    fromVocabId: '',
    toVocabId: '',
    searchStr: '',
  },
  pageNo: 0,
  dataCount: 0,
  data: [],
  isfilterSelected: false,
  isClearAll: false,
  isKeyStageDisabledHistory: true,
  isCourseDisabledHistory: true,
  isModuleDisabledHistory: true,
  isUnitDisabledHistory: true,
};

const historyLogs = createSlice({
  name: 'historyLogs',
  initialState,
  reducers: {
    setHistoryLiveFilter: (state, action) => {
      state.dataToSend[action?.payload[0]] = action?.payload[1];
    },
    setFromDateSelected: (state, action) => {
      state.fromDateSelected = action?.payload;
    },
    setToDateSelected: (state, action) => {
      state.toDateSelected = action?.payload;
    },
    setHistoryFilterValues: (state, action) => {
      state.filters[action?.payload[0]] = action?.payload[1];
    },
    setDataToSend: (state, action) => {
      state.dataToSend = action?.payload;
    },
    updateList: (state, action) => {
      state.list = [...state.list, ...action.payload];
    },
    updateListWithoutAppend: (state, action) => {
      state.list = action.payload;
    },

    updatePageNo: (state) => {
      state.pageNo += 1;
    },
    decPageNo: (state) => {
      state.pageNo -= 1;
    },
    resetHistoryDb: (state) => {
      state.fromDateSelected = initialState.fromDateSelected;
      state.toDateSelected = initialState.toDateSelected;
      state.dataToSend = initialState.dataToSend;
      state.filters.keyStage = initialState.filters.keyStage;
      state.filters.course = initialState.filters.course;
      state.filters.module = initialState.filters.module;
      state.filters.unit = initialState.filters.unit;
      state.isFilterApplied = initialState.isFilterApplied;
      state.list = initialState.list;
      state.columns = initialState.columns;
      state.pageNo = initialState.pageNo;
      state.dataCount = initialState.dataCount;
      state.data = initialState.data;
      state.isfilterSelected = initialState.isfilterSelected;
      state.isClearAll = initialState.isClearAll;
      state.isKeyStageDisabledHistory = initialState.isKeyStageDisabledHistory;
      state.isCourseDisabledHistory = initialState.isCourseDisabledHistory;
      state.isModuleDisabledHistory = initialState.isModuleDisabledHistory;
      state.isUnitDisabledHistory = initialState.isUnitDisabledHistory;
    },
    setIsFilterApplied: (state, action) => {
      state.list = [];
      state.isFilterApplied = action.payload;
    },
    setIsFilterSelected: (state, action) => {
      state.isfilterSelected = action.payload;
    },
    setOnlyIsFilterApplied: (state, action) => {
      state.isFilterApplied = action.payload;
    },
    setDataCount: (state, action) => {
      state.dataCount = action.payload;
    },
    setList: (state, action) => {
      state.list = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setClearAll: (state, action) => {
      state.isClearAll = action?.payload;
    },
    resetPageNo: (state) => {
      state.pageNo = initialState.pageNo;
    },
    setIsKeyStageDisabledHistory: (state, action) => {
      state.isKeyStageDisabledHistory = action?.payload;
    },
    setIsCourseDisabledHistory: (state, action) => {
      state.isCourseDisabledHistory = action?.payload;
    },
    setIsModuleDisabledHistory: (state, action) => {
      state.isModuleDisabledHistory = action?.payload;
    },
    setIsUnitDisabledHistory: (state, action) => {
      state.isUnitDisabledHistory = action?.payload;
    },
    resetHistorySubjectCase: (state) => {
      state.dataToSend.keyStage = initialState.dataToSend.keyStage;
      state.dataToSend.course = initialState.dataToSend.course;
      state.dataToSend.module = initialState.dataToSend.module;
      state.dataToSend.unit = initialState.dataToSend.unit;
    },
    resetHistoryKeyStageCase: (state) => {
      state.dataToSend.course = initialState.dataToSend.course;
      state.dataToSend.module = initialState.dataToSend.module;
      state.dataToSend.unit = initialState.dataToSend.unit;
    },
    resetHistoryCourseCase: (state) => {
      state.dataToSend.module = initialState.dataToSend.module;
      state.dataToSend.unit = initialState.dataToSend.unit;
    },
    resetHistoryModuleCase: (state) => {
      state.dataToSend.unit = initialState.dataToSend.unit;
    },
  },
});

export const {
  fetchFiltersForHistoryLogs,
  setDataToSend,
  updateList,
  setFromDateSelected,
  setToDateSelected,
  updatePageNo,
  resetHistoryDb,
  setIsFilterApplied,
  setOnlyIsFilterApplied,
  setDataCount,
  setList,
  setData,
  updateListWithoutAppend,
  decPageNo,
  setIsFilterSelected,
  setClearAll,
  resetPageNo,
  setHistoryFilterValues,
  setIsKeyStageDisabledHistory,
  setIsCourseDisabledHistory,
  setIsModuleDisabledHistory,
  setIsUnitDisabledHistory,
  resetHistorySubjectCase,
  resetHistoryKeyStageCase,
  resetHistoryCourseCase,
  setHistoryLiveFilter,
  resetHistoryModuleCase,
} = historyLogs.actions;
export default historyLogs.reducer;
