import axios from 'axios';
import { setAuth } from '../store/reducer/authenticate';
import { setSnackState } from '../store/reducer/snackbarReducer';
import { setBackdrop } from '../store/reducer/uploadFile';
import store from '../store/store';
import mflConfig from './config';
import { logout } from './getEnvConfig';

export const interceptor = () => {
  axios.interceptors.request.use((req) => {
    // adding the headers information here
    req.headers.mycloudproxysession = mflConfig.SSO_TOKEN;
    return req;
  });
  axios.interceptors.response.use(
    (res) => res,
    (err) => {
      switch (err.response.status) {
        case 400:
          setTimeout(() => {
            store.dispatch(setBackdrop(false));
            store.dispatch(
              setSnackState({
                openSnackbar: true,
                message: err?.response?.data?.message,
                type: 'error',
              })
            );
          }, 1000);
          break;
        case 401:
          logout();
          break;
        case 403:
          store.dispatch(setAuth(true));
          break;
        default:
          setTimeout(() => {
            store.dispatch(setBackdrop(false));
            store.dispatch(
              setSnackState({
                openSnackbar: true,
                message: err?.response?.data?.message,
                type: 'error',
              })
            );
          }, 1000);
          break;
      }
    }
  );
};
