import {
  Backdrop,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  GAME_DEVELOPMENT,
  GAME_NAME,
  SELECTING_COURSE,
  SELECTING_VOCABLIST,
} from '../../../assets/constantData/gameConstant';
import {
  fetchAllGameFilterValues,
  fetchfilteredVocabList,
} from '../../../services/actions/gameDevelopment';
import { setErrorFocusFlag, setGameName, setShowVocab } from '../../../store/reducer/gameDevelop';
import { ButtonCustom } from '../../custom/ButtonCustom';
import { FilterBox } from './FilterBox';

const useStyle = makeStyles(() => ({
  textField: {
    width: '74%',
  },
}));
export const TopSection = ({ title }) => {
  const dispatch = useDispatch();
  const backdrop = useSelector((states) => states.uploadFile.backdrop);
  const { liveFilter, selectedVocabList } = useSelector((state) => state.gameDevelopment);

  useEffect(() => {
    if (title === 'Create New') {
      dispatch(fetchAllGameFilterValues({ subject: [] }));
    }
  }, []);

  useEffect(() => {
    if (title !== 'Create New') {
      dispatch(
        fetchAllGameFilterValues({
          subject: [],
        })
      );
      if (liveFilter?.subject?.length !== 0)
        dispatch(
          fetchAllGameFilterValues({
            subject: liveFilter?.subject,
            keyStage: [],
            course: [],
            module: [],
            unit: [],
          })
        );
      if (liveFilter?.keyStage?.length !== 0)
        dispatch(
          fetchAllGameFilterValues({
            subject: liveFilter?.subject,
            keyStage: liveFilter?.keyStage,
            course: [],
            module: [],
            unit: [],
          })
        );
      if (liveFilter?.course?.length !== 0)
        dispatch(
          fetchAllGameFilterValues({
            subject: liveFilter?.subject,
            keyStage: liveFilter?.keyStage,
            course: liveFilter?.course,
            module: [],
            unit: [],
          })
        );
      if (liveFilter?.module?.length !== 0)
        dispatch(
          fetchAllGameFilterValues({
            subject: liveFilter?.subject,
            keyStage: liveFilter?.keyStage,
            course: liveFilter?.course,
            module: liveFilter?.module,
            unit: [],
          })
        );
      if (liveFilter?.unit?.length !== 0)
        dispatch(
          fetchAllGameFilterValues({
            subject: liveFilter?.subject,
            keyStage: liveFilter?.keyStage,
            course: liveFilter?.course,
            module: liveFilter?.module,
            unit: liveFilter?.unit,
            vocabType: [],
            subGroup: [],
          })
        );
      if (liveFilter?.vocabType?.length !== 0)
        dispatch(
          fetchAllGameFilterValues({
            subject: liveFilter?.subject,
            keyStage: liveFilter?.keyStage,
            course: liveFilter?.course,
            module: liveFilter?.module,
            unit: liveFilter?.unit,
            vocabType: liveFilter?.vocabType,
            subGroup: [],
          })
        );
    }
  }, [liveFilter]);

  const handleShowVocab = () => {
    dispatch(fetchfilteredVocabList(liveFilter, selectedVocabList));
    dispatch(setShowVocab(true));
  };
  const filters = useSelector((state) => state.gameDevelopment.filters);
  const incomingFieldName = useSelector((state) => state.gameDevelop.dropdowns.name);
  const errorFocusFlag = useSelector((state) => state.gameDevelop.errorFocusFlag);
  const arrTopFilters = Object.entries(filters);
  arrTopFilters.splice(arrTopFilters.length - 3, 3);
  const arrBottomFilters = Object.entries(filters);
  arrBottomFilters.splice(0, 4);
  const checkRegEx = /^[\w\s]*$/g;
  const classes = useStyle();
  const handleTextField = (value) => {
    dispatch(setErrorFocusFlag(false));
    if (checkRegEx.test(value)) {
      dispatch(setGameName(value));
    }
  };
  return (
    <Grid paddingX={4}>
      <Grid display="flex" paddingY={2} justifyContent="center">
        <Typography variant="h4">{GAME_DEVELOPMENT}</Typography>
      </Grid>
      <Grid display="flex" justifyContent="center" flexDirection="column">
        <Grid item display="flex" flexDirection="row" justifyContent="start" alignItems="center">
          <Typography variant="h5">{GAME_NAME}</Typography>
          <Tooltip
            title="This field can have alphanumeric data and underscore values"
            placement="right"
          >
            <IconButton
              disableRipple
              sx={{
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'start',
              }}
            >
              <InfoOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <TextField
            error={errorFocusFlag}
            inputRef={(input) => errorFocusFlag && input?.focus()}
            inputProps={{ 'data-testid': 'gamename', maxLength: 255 }}
            multiline
            rows={2}
            className={classes.textField}
            required
            value={incomingFieldName}
            onChange={(e) => {
              handleTextField(e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <Grid paddingBottom={1} paddingTop={2} item>
        <Typography variant="h5">{SELECTING_COURSE}</Typography>
      </Grid>
      <Grid display="flex" flexWrap="wrap" justifyContent="flex-start">
        {arrTopFilters.map(([key, value]) => (
          <Grid key={key + value} paddingBottom={2} marginRight={12} item xs={2} width="200px">
            <FilterBox key={key} isBold={false} title={key} options={value} />
          </Grid>
        ))}
      </Grid>
      <Grid paddingBottom={1} paddingTop={2} item>
        <Typography variant="h5">{SELECTING_VOCABLIST}</Typography>
      </Grid>
      <Grid display="flex" flexWrap="wrap" justifyContent="flex-start">
        {arrBottomFilters.map(([key, value]) => (
          <Grid paddingBottom={2} key={key} marginRight={12} item xs={2} width="200px">
            <FilterBox isBold={false} title={key} options={value} />
          </Grid>
        ))}
      </Grid>
      <Grid display="flex" justifyContent="center" flexDirection="column">
        <Grid item paddingY={3}>
          <ButtonCustom
            customAttribute={{ sx: { textTransform: 'capitalize' }, 'data-testid': 'showvocab' }}
            valueProp="Show Vocab"
            variantProp="contained"
            onClick={handleShowVocab}
          />
        </Grid>
      </Grid>
      <Backdrop sx={{ color: '#fff', zIndex: 10000 }} open={backdrop}>
        <CircularProgress />
      </Backdrop>
    </Grid>
  );
};

TopSection.propTypes = {
  title: PropTypes.string.isRequired,
};
